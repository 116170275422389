import React from "react";
import { Itabledata } from "./type";
import TransactionReference from "../../../module/applications/components/Table/TransactionRefrence";
import DateTime from "./DateTime";
import TxnStatus from "../../../module/utility/components/Table/Status";
import AmountData from "./Amount";
import ExchangeRate from "./ExchangeRate";

function SlateTableData({ row, col, layout_type, className }: Itabledata) {
  let template;

  if (!row) {
    return <td className="border-b py-6 px-1.5 text-right ">{template}</td>;
  }

  switch (col.dataIndex) {
    case "ref_item":
      template = (
        <TransactionReference value={row?.txn_reference} dontTruncateValue />
      );
      break;
    case "created_at":
      template = <DateTime timestring={row?.created_at} />;
      break;
    case "txn_status":
      template = <TxnStatus status={row?.status} />;
      break;
    case "dollar_amount":
      template = (
        <AmountData amount={row?.usdt_amount} currency_type="dollars" />
      );
      break;
    case "acc_balance":
      template = <AmountData amount={row?.balance} currency_type="dollars" />;
      break;
    case "txn_fee":
      template = <AmountData amount={row?.txn_fee} local={row?.currency} />;
      break;
    case "exchange_rate":
      template = <ExchangeRate amount={row?.exchange_rate} currency={row?.currency} />;
      break;
    case "currency":
      template = <span className="uppercase" >{row?.currency}</span> ;
      break;

    default:
      template = (
        <span className="inline-block w-full">
          {row[col?.key] ? row[col?.key] : "N/A"}
        </span>
      );
      break;
  }

  return <td className="slate-table border-b py-6 px-1.5 text-right">{template}</td>;
}

export default SlateTableData;
