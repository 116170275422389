import { NavLink, useLocation } from "react-router-dom";
import { appNavLinks } from "../../../utils/helpers/navHelpers";
import { mergeClassName } from "../../../utils/helpers";
import { BiChevronRight } from "react-icons/bi";

function ApplicationsMenu() {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div
      data-tut="instextour__nav-navigation"
      className="row-start-3 row-end-4 pl-6"
    >
      <ul className="flex flex-col gap-y-7">
        {appNavLinks.map(({ icon, link, title, name, child }) => {
          return (
            <li
              key={name}
              className={mergeClassName(
                "relative",
                pathname.includes(name)
                  ? "relative rounded-2xl text-[#0B112F] [&_svg]:text-blue-130"
                  : "text-[#797D8C]"
              )}
            >
              <NavLink
                key={name}
                to={link}
                className={({ isActive }) =>
                  mergeClassName(
                    "flex items-center justify-start gap-x-1 text-sm leading-4",
                    pathname.includes(name) ? "font-bold" : "font-normal"
                  )
                }
              >
                <span className="flex flex-1 items-center gap-x-5">
                  {icon}
                  <span className="hidden md:inline-block">{title}</span>
                </span>

                <span className="justify-self-end">
                  <BiChevronRight />
                </span>
              </NavLink>
              {pathname.includes(name) && child && (
                <div className="relative flex flex-col pl-10 pt-4 before:absolute before:left-10 before:h-max before:w-0.5 before:bg-[#EFF1F7]">
                  {child.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.link}
                      className={({ isActive }) =>
                        mergeClassName(
                          "relative flex items-center justify-start gap-x-5 py-3 pl-4 text-sm leading-4 before:absolute before:left-0 before:h-full before:w-0.5 before:bg-[#EFF1F7]",
                          pathname.includes(item.name) || isActive
                            ? "font-bold text-[#0B112F] before:bg-blue-130"
                            : "text-gray-10"
                        )
                      }
                    >
                      <span className="hidden md:inline-block">
                        {item.title}
                      </span>
                    </NavLink>
                  ))}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ApplicationsMenu;
