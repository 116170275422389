import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import { StatBoardQuery } from "../types";

export const getDashboardInfo = createAsyncThunk(
  "dashboard/getinfo",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getDashboardInfo()
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const getDashboardChart = createAsyncThunk(
  "dashboard/getChart",
  async (query: StatBoardQuery, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getDashboardChart(query)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
