import React from "react";
import { IconType } from "./types";

export function EditIcon({ scale = 1 }: IconType) {
  return (
    <svg
      width={scale * 16}
      height={scale * 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33337 1.33325H6.00004C2.66671 1.33325 1.33337 2.66659 1.33337 5.99992V9.99992C1.33337 13.3333 2.66671 14.6666 6.00004 14.6666H10C13.3334 14.6666 14.6667 13.3333 14.6667 9.99992V8.66658"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6934 2.01326L5.44004 7.26659C5.24004 7.46659 5.04004 7.85992 5.00004 8.14659L4.71337 10.1533C4.60671 10.8799 5.12004 11.3866 5.84671 11.2866L7.85337 10.9999C8.13337 10.9599 8.52671 10.7599 8.73337 10.5599L13.9867 5.30659C14.8934 4.39992 15.32 3.34659 13.9867 2.01326C12.6534 0.679924 11.6 1.10659 10.6934 2.01326Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.94006 2.7666C10.3867 4.35993 11.6334 5.6066 13.2334 6.05993"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
