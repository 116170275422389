import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../../common/components/CustomButton";
import CustomCheckBox from "../../../../../common/components/CustomCheckBox";
import CustomInput from "../../../../../common/components/CustomInput";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import routes from "../../../../../common/routes";

export function ApplicationCheckout() {
  const navigate = useNavigate();
  const location = useLocation();
  const backDropHandler = () => {
    navigate(routes.protected.applications.index, { replace: true });
  };
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate(routes.protected.applications.create_new_app.paymentsuccess, {
      replace: true,
      state: { background: location.state.background },
    });
  };
  return (
    <SideModal
      onclickBackDrop={backDropHandler}
      className=" relative pl-14 pr-40 pb-24"
    >
      <header className="sticky top-0 z-10 flex flex-col gap-y-3.5 bg-white pt-16">
        <h3 className="text-4xl font-bold">Checkout</h3>
        <p>Enter your payment details to proceed</p>
      </header>
      <form
        onSubmit={submitHandler}
        className="z-[9] flex flex-col gap-y-9 pt-11"
      >
        <div className="mb-4.5 font-semibold">
          <p className="pb-2 text-base leading-7">Order Summary</p>
          <div className="flex items-center justify-between border-t border-gray-390 pt-5 text-lg leading-[1.375rem]">
            <p className="text-gray-390">Instance application</p>
            <p className="text-base-30">$49.80</p>
          </div>
        </div>
        <div className="flex flex-col gap-y-6">
          <CustomInput label="Card Number" name="card" />
          <div className="flex gap-x-4.5 ">
            <CustomInput label="Expiration Date" name="date" />
            <CustomInput label="CVV" name="cvv" />
          </div>
          <CustomCheckBox
            checked={false}
            label="Save card details"
            classNames={{
              label: "gap-x-4 font-normal text-gray-390",
              field: "w-4 h-4 border border-gray-390 p-2 bg-white rounded-sm",
            }}
          />
        </div>
        <div className="flex flex-col gap-y-6">
          <CustomButtom className="py-5">Pay USD59.28</CustomButtom>

          <p className="text-gray-390">
            Your personal data will be used to process your order, support your
            experience throughout this website, and for other purposes described
            in our privacy policy.
          </p>
        </div>
      </form>
    </SideModal>
  );
}
