import { useLocation, useNavigate } from "react-router-dom";

export default function useNavigateModal() {
  const navigate = useNavigate();
  const location = useLocation();
  const prevBackground = location?.state?.background;
  const data = location?.state?.data;
  const navigateHandler = (
    path?: string,
    others?: { canUsePrevBg?: boolean; data?: any }
  ) => {
    const navPath = path || prevBackground?.pathname || "";

    navigate(navPath, {
      state: !path
        ? null
        : {
            background: others?.canUsePrevBg ? prevBackground : location,
            data: { ...data, ...others?.data },
          },
      replace: true,
    });
  };
  return navigateHandler;
}
