import { IconType } from "./types";

export function CloseIcon({ scale = 1 }: IconType) {
  return (
    <svg
      width={scale * 20}
      height={scale * 20}
      viewBox="0 0 20 20"
        fill="inherit"
        stroke="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.88045" cy="9.83455" r="9.83455" fill="inherit" />
      <path
        d="M6.75098 6.20874L13.208 13.6592"
        stroke="inherit"
        strokeWidth="1.49008"
        strokeLinecap="round"
      />
      <line
        x1="13.4221"
        y1="6.51672"
        x2="6.56204"
        y2="12.948"
        stroke="inherit"
        strokeWidth="1.49008"
        strokeLinecap="round"
      />
    </svg>
  );
}
