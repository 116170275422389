// @ts-nocheck
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../common/hooks";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import CustomInput from "../../../../common/components/CustomInput";
import CustomButton from "../../../../common/components/CustomButton";
import PermissionCheck from "../../components/PermissionCheck";
import { validationRules } from "../../../../common/utils/helpers";
import { inviteUser, updateAccountType } from "../../store/thunk";
import { iBtnState, iHandleChange, iHandleSubmit } from "../../types";
import Language from "../../../../common/utils/language/en";

const { invite_user: PageDictionary } = Language.protected.UserModal;

type pri = "WORKSPACE_ADMIN" | "WORKSPACE_MEMBER";

type Ishape = {
  name: pri;
  label: string;
  tip: string;
}[];

export const shape: Ishape = [
  {
    name: "WORKSPACE_ADMIN",
    label: PageDictionary.form.admin_label,
    tip: PageDictionary.form.admin_tooltip,
  },
  {
    name: "WORKSPACE_MEMBER",
    label: PageDictionary.form.basic_label,
    tip: PageDictionary.form.basic_tooltip,
  },
];

function CreateUser() {
  const { uid } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState({ check: false, email: false });
  const [inData, setInData] = useState({ email: "", user_type: "" });
  const [btnState, setBtnState] = useState<iBtnState>(
    PageDictionary.form.submit
  );
  const {
    members: { data },
    not_verified_loading: loading,
  } = useAppSelector((state) => state.user);
  const userData = data.find((itm) => itm._id === uid);
  const dispatch = useAppDispatch();

  const btn_disable_submit =
    btnState === PageDictionary.form.submit &&
    (Object.values(hasError).includes(true) ||
      Object.values(inData).includes(""));
  const btn_disable_update =
    btnState === PageDictionary.form.update &&
    userData?.user_types.includes(inData.user_type);

  useEffectOnce(() => {
    if (!userData) return;
    setBtnState(PageDictionary.form.update);
    setInData((prev) => ({
      ...prev,
      email: userData.email,
      user_type: userData.user_types.includes("WORKSPACE_ADMIN")
        ? "WORKSPACE_ADMIN"
        : "WORKSPACE_MEMBER",
    }));
  });

  const backDropHandler = () => {
    if (loading) return;
    navigate(state?.background?.pathname, { replace: true });
  };

  const checkHandler: iHandleChange = (e) => {
    const { name } = e.target;
    setInData((prev) => ({ ...prev, user_type: name }));
  };

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    setInData((prev) => ({ ...prev, [name]: value }));
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setHasError((prev) => ({ ...prev, [name]: false }));
    } else {
      setHasError((prev) => ({ ...prev, [name]: true }));
    }
  }, []);

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();

    // if (Object.values(inData).includes("")) return;

    if (btnState === PageDictionary.form.submit) {
      dispatch(inviteUser(inData)).unwrap().finally(backDropHandler);
      return;
    }
    if (btnState === PageDictionary.form.update && uid !== undefined) {
      dispatch(
        updateAccountType({
          member_id: uid,
          data: { user_type: inData.user_type },
        })
      )
        .unwrap()
        .finally(backDropHandler);
      return;
    }
  };

  return (
    <SideModal
      onclickBackDrop={backDropHandler}
      className="relative p-8 md:pl-14 md:pr-28 md:pb-24"
    >
      <header className="sticky top-0 z-10 border-b bg-white pt-16 pb-1.5 text-gray-40">
        <h3 className="text-4xl font-bold">
          {btnState === PageDictionary.form.submit
            ? PageDictionary.title.submit
            : PageDictionary.title.update}
        </h3>
      </header>
      <form
        onSubmit={submitHandler}
        className="z-[9] flex flex-col gap-y-7 pt-11"
      >
        <CustomInput
          label={PageDictionary.form.member_email}
          name="email"
          className="h-12"
          labelClassName="font-normal text-base-20"
          value={inData.email}
          onChange={changeHandler}
          rules={validationRules.email}
          haserror={hasError.email}
          disabled={loading}
          readOnly={!!uid}
        />

        <div className="mt-7">
          <p className="text-base text-base-20">
            {PageDictionary.form.role_label}
          </p>
          <div className="mt-7 flex flex-col gap-y-4">
            {shape.map((itm) => (
              <PermissionCheck
                key={itm.name}
                checkHandler={checkHandler}
                name={itm.name}
                is_check={itm.name === inData.user_type}
                label={itm.label}
                tip={itm.tip}
                disable={loading}
              />
            ))}
          </div>
        </div>

        <CustomButton
          disabled={btn_disable_submit || btn_disable_update}
          isloading={loading}
          className="mt-2.5 self-start px-[3.25rem] py-3.5 text-base leading-7"
        >
          {btnState}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default CreateUser;
