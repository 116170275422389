import React from "react";
import { IconType } from "./types";

function DataIcon({ color = "#4001C5", scale = 1 }: IconType) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      scale={scale}
    >
      <path
        d="M19.0503 3.76121C19.9794 4.64873 20.7163 5.70338 21.2186 6.86457C21.7209 8.02576 21.9787 9.2706 21.9773 10.5276C21.9773 13.169 20.8585 15.5616 19.0503 17.2939L17.6418 15.9445C18.3865 15.2348 18.9766 14.3906 19.3779 13.4607C19.7791 12.5308 19.9836 11.5338 19.9794 10.5276C19.9794 8.41247 19.0903 6.4888 17.6418 5.11065L19.0503 3.76121ZM16.2233 6.46966C16.7809 7.00174 17.2233 7.63415 17.525 8.33053C17.8267 9.02691 17.9818 9.77355 17.9814 10.5276C17.9814 12.1067 17.3121 13.5423 16.2233 14.5854L14.8147 13.236C15.1861 12.8805 15.4805 12.4583 15.6811 11.9935C15.8817 11.5287 15.9845 11.0305 15.9835 10.5276C15.9835 9.46523 15.534 8.50818 14.8147 7.8191L16.2233 6.46966ZM11.9876 8.61345C12.5175 8.61345 13.0257 8.81511 13.4004 9.17408C13.7751 9.53304 13.9856 10.0199 13.9856 10.5276C13.9856 11.0352 13.7751 11.5221 13.4004 11.881C13.0257 12.24 12.5175 12.4417 11.9876 12.4417C11.4577 12.4417 10.9496 12.24 10.5749 11.881C10.2002 11.5221 9.98969 11.0352 9.98969 10.5276C9.98969 10.0199 10.2002 9.53304 10.5749 9.17408C10.9496 8.81511 11.4577 8.61345 11.9876 8.61345ZM10.4892 0C11.1515 0 11.7868 0.252079 12.2551 0.700784C12.7235 1.14949 12.9866 1.75806 12.9866 2.39263V6.69935H10.9887V2.87115H1.99794V17.2269H10.9887V14.3558H12.9866V18.6625C12.9866 19.297 12.7235 19.9056 12.2551 20.3543C11.7868 20.803 11.1515 21.0551 10.4892 21.0551H2.49742C1.83506 21.0551 1.19984 20.803 0.731478 20.3543C0.26312 19.9056 0 19.297 0 18.6625V2.39263C0 1.75806 0.26312 1.14949 0.731478 0.700784C1.19984 0.252079 1.83506 0 2.49742 0H10.4892Z"
        fill={color}
      />
    </svg>
  );
}

export default DataIcon;
