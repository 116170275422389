import { Suspense, lazy } from "react";
import AmountData from "./Amount";
import DateTime from "./DateTime";
import { tableDataStyle } from "./helper";
import { Itabledata } from "./type";
import { ActiveWhiteLabelActionButton } from "../../../module/applications/components/ActiveWhiteLabelActionButton";
import { AuditReportingSchedule } from "../../../module/applications/components/Table/AuditReportingSchedule";
import { AuditReportHistoryBtn } from "../../../module/applications/components/Table/AuditReportHistoryBtn";
import { mergeClassName } from "../../utils/helpers";

const loadComp = (module: string, comp: string) =>
  lazy(() => import(`../../../module/${module}/components/Table/${comp}`));

const CategoryPolicyName = loadComp("applications", "CategoryPolicyName");
const ManagePolicy = loadComp("applications", "ManagePolicy");
const ViewShareholderData = loadComp("compliance", "ShareholderId");
const UtilityValue = loadComp("utility", "UtilityValue");

function TableData({ row, col, layout_type, className }: Itabledata) {
  let template;

  switch (col.dataIndex) {
    // case "app_config_keys":
    //   template = <ApplicationTableDownload id={row._id} />;
    //   break;
    case "app_name":
      const ApplicationName = loadComp("applications", "ApplicationName");
      template = (
        <ApplicationName
          name={row?.name}
          mode={row?.mode}
          is_approved={row?.is_admin_approved}
        />
      );
      break;
    case "edit_app":
      const ApplicationEdit = loadComp("applications", "ApplicationEdit");
      template = <ApplicationEdit appid={row._id} />;
      break;
    case "fullname":
      const FullName = loadComp("applications", "FullName");
      template = (
        <FullName firstname={row.first_name} lastname={row.last_name} />
      );
      break;
    case "tx_type":
      const TransactionType = loadComp("wallet", "TransactionType");
      template = (
        <TransactionType
          type={row.type}
          appid={row.merchant_app}
          tx_id={row.txn_reference}
          txn_status={row?.meta_data?.blockchain_txn_status}
        />
      );
      break;
    case "kyc_user":
    case "policy_noti":
      const ViewButton = loadComp("applications", "ViewButton");
      template = (
        <ViewButton
          datakey={col.key}
          appid={row?.merchant_app}
          pid={row?._id}
          count={row?.risk_record_count}
          name={row?.name}
        />
      );
      break;
    case "user_action":
    case "policy_action":
      const ActionButton = loadComp("applications", "ActionButton");
      template = <ActionButton data={row} colIndex={col.dataIndex} />;
      break;
    case "policy_rule":
      const MangeRules = loadComp("applications", "MangeRules");
      template = <MangeRules data={row} />;
      break;
    case "policy_cat_name_in":
      template = (
        <CategoryPolicyName
          policy_name={row.credit_policy?.name}
          policy_id={row.credit_policy?._id}
        />
      );
      break;
    case "policy_cat_name_out":
      template = (
        <CategoryPolicyName
          policy_name={row.debit_policy?.name}
          policy_id={row.debit_policy?._id}
        />
      );
      break;
    case "policy_manage":
      template = (
        <ManagePolicy app_id={row.merchant_app} category_id={row._id} />
      );
      break;
    case "manage_policy_risk":
      template = (
        <ManagePolicy
          app_id={row?.policy?.merchant_app}
          category_id={row?.policy?._id}
        />
      );
      break;
    case "risk_rules":
      const FlaggedRules = loadComp("applications", "FlaggedRules");
      template = <FlaggedRules rules_applied={row?.rules_applied} />;
      break;
    case "policy_name":
      const PolicyName = loadComp("applications", "PolicyName");
      template = <PolicyName type={row.type} name={row.name} />;
      break;
    case "business_action":
      const BusinessAction = loadComp("compliance", "BusinessAction");
      template = (
        <BusinessAction
          status={row?.data?.status}
          doc_title={row?.name}
          doc_link={row?.data?.file}
          doc_type={row?.data?.type}
        />
      );
      break;
    case "business_status":
      const BusinessStatus = loadComp("compliance", "BusinessStatus");
      template = <BusinessStatus status={row.data?.status} />;
      break;
    case "business_document_created_at":
      template = <DateTime timestring={row?.data?.created_at} />;
      break;
    case "shareholder_id_proof":
      template = <ViewShareholderData image={row.means_of_identity} />;
      break;
    case "shareholder_address_proof":
      template = <ViewShareholderData image={row.proof_of_address} />;
      break;
    case "shareholder_action":
      const ShareholderAction = loadComp("compliance", "ShareholderAction");
      template = <ShareholderAction shareholderid={row._id} />;
      break;
    case "created_at":
      template = <DateTime timestring={row.created_at} />;
      break;
    case "deactivate_btn":
      const DeactivateBtn = loadComp("applications", "DeactivateBtn");
      template = <DeactivateBtn bankid={row._id} is_active={row?.is_active} />;
      break;
    case "dollar_amount":
      template = (
        <AmountData amount={row?.usdt_amount} currency_type="dollars" />
      );
      break;
    case "acc_balance":
      template = <AmountData amount={row?.balance} currency_type="dollars" />;
      break;
    case "description":
      const Description = loadComp("applications", "Description");
      template = <Description text={row?.description} />;
      break;
    case "local_amount":
      template = <AmountData amount={row.amount} local={row?.currency} />;
      break;
    case "txn_fee":
      template = <AmountData amount={row?.txn_fee} local={row?.currency} />;
      break;
    case "amount":
      template = <AmountData amount={row.amount} currency_type="dollars" />;
      break;
    case "include_amount":
      template = (
        <p
          className={`font-semibold ${
            row.amount < 0 ? "text-[#E41D1D]" : "text-green-700"
          }`}
        >
          {row.amount < 0 ? "-" : "+"}
          <AmountData
            amount={Math.abs(row.amount).toString()}
            currency_type="dollars"
          />
        </p>
      );
      break;
    case "notification_config_rule":
      const NotificationRuleName = loadComp(
        "applications",
        "NotificationRuleName"
      );
      template = <NotificationRuleName name={row?.type} />;
      break;
    case "notification_config_schedule":
    case "notification_config_method":
      const NotificationMethod = loadComp(
        "applications",
        "NotificationTableSelect"
      );
      template = (
        <NotificationMethod
          type={col.dataIndex}
          method={row?.notification_method}
          schedule={row?.schedule}
          is_disabled={row?.is_disabled}
          is_loading={row?.is_loading}
          noti_id={row?._id}
          policy_id={row?.policy}
          notify_to={row?.notify_to}
        />
      );
      break;
    case "notification_config_notify_to":
      const NotificationNotifyTo = loadComp(
        "applications",
        "NotificationNotifyTo"
      );
      template = (
        <NotificationNotifyTo
          value={row?.notify_to}
          is_disabled={row?.is_disabled}
          is_loading={row?.is_loading}
          noti_id={row?._id}
          policy_id={row?.policy}
          is_all_rule_applied={row?.is_all_rules_applied}
          rule_applied_to_all={row?.apply_this_rule}
          method={row?.notification_method}
          schedule={row?.schedule}
          is_error={row?.is_error}
        />
      );
      break;
    case "notification_config_manage":
      const NotificationManage = loadComp("applications", "NotificationManage");
      template = (
        <NotificationManage
          is_disabled={row?.is_disabled}
          is_loading={row?.is_loading}
          noti_id={row?._id}
          policy_id={row?.policy}
          is_all_rule_applied={row?.is_all_rules_applied}
          rule_applied_to_all={row?.apply_this_rule}
          is_error={row?.is_error}
        />
      );
      break;
    case "user_access":
      const UserAccess = loadComp("user", "Access");
      template = <UserAccess access={row?.user_types} />;
      break;
    case "user_access_action":
      const UserAccActions = loadComp("user", "Actions");
      template = (
        <UserAccActions
          user_id={row?._id}
          is_active={row?.is_active}
          is_verified={row?.is_verified}
          access={row?.user_types}
          email={row?.email}
        />
      );
      break;
    case "txn_status":
      const TxnStatus = loadComp("wallet", "Status");
      template = <TxnStatus status={row?.status} />;
      break;

    case "app_audit_schedule":
      // const AuditReportingSchedule = loadComp(
      //   "applications",
      //   "AuditReportingSchedule"
      // );
      template = <AuditReportingSchedule />;
      break;

    case "audit_report_history":
      // const AuditReportHistoryBtn = loadComp(
      //   "applications",
      //   "AuditReportHistoryBtn"
      // );
      template = (
        <AuditReportHistoryBtn
          audit_report_history={row?.audit_report_history || 0}
        />
      );
      break;

    case "activeWhiteLabelAction":
      template = <ActiveWhiteLabelActionButton />;
      break;
    case "ref_item":
      const TransactionReference = loadComp(
        "applications",
        "TransactionRefrence"
      );
      template = <TransactionReference value={row?.txn_reference} />;
      break;
    case "report_item":
      const ReportTransaction = loadComp("applications", "ReportTransaction");
      template = <ReportTransaction id={row?._id} />;
      break;
    case "utility_txn_type":
      template = (
        <UtilityValue color value={row?.utilty_context?.product_type} />
      );
      break;
    case "utility_provider":
      template = <UtilityValue value={row?.utilty_context?.operator?.name} />;
      break;
    case "utility_actions":
      const UtilityActions = loadComp("utility", "UtilityActions");
      template = <UtilityActions id={row?._id} />;
      break;

    default:
      template = (
        <span className="inline-block w-full pt-4 pb-5">{row[col?.key]}</span>
      );
      break;
  }

  return (
    <td
      className={mergeClassName(
        tableDataStyle(layout_type, row?.data?.status, row?.is_active),
        className
      )}
    >
      <Suspense fallback={<span className="" />}>{template}</Suspense>
    </td>
  );
}

export default TableData;
