import { ReactNode } from "react";
import { toast, ToastOptions } from "react-toastify";

const config: ToastOptions = {
  position: "top-right",
  draggable: false,
  bodyClassName: "toast__body",
  className: "toast",
  autoClose: 2000,
  progressClassName: "toast__progress",
};
const customToast = {
  error: (res: any[] | string) => {
    const data = typeof res === "object" ? res[0] : res;
    return toast(data, {
      ...config,
      type: "error",
    });
  },
  success: (res: any[] | string) => {
    const data = typeof res === "object" ? res[0] : res;
    return toast(data, {
      ...config,
      type: "success",
    });
  },
  warning: (res: ReactNode | string, moreConfig: ToastOptions) => {
    const data = typeof res === "string" ? res : res;
    return toast(data, {
      ...config,
      ...moreConfig,
      type: "warning",
    });
  },
};

export { toast };

export default customToast;
