import React from "react";
import {
  mergeClassName,
  truncatedText,
} from "../../../../../common/utils/helpers";
import { useCopyToClipboard } from "../../../../../common/hooks";
import { BsCheckAll } from "react-icons/bs";
import { CopyIcon } from "../../../../../static/images/icons";

type Props = {
  value: string;
  dontTruncateValue?: boolean;
};

function TransactionReference({ value, dontTruncateValue }: Props) {
  const { copyToClipboard, isCopied } = useCopyToClipboard();
  const truncated_value = truncatedText(value, 10);

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    copyToClipboard(value);
  };

  return (
    <button
      onClick={clickHandler}
      className={mergeClassName(
        "flex w-full items-center pt-4 pb-5",
        dontTruncateValue ? "justify-end gap-x-2" : ""
      )}
    >
      <span>{dontTruncateValue ? value : truncated_value}</span>
      {dontTruncateValue && !isCopied ? (
        <span className="rounded-3xl border border-gray-20 bg-gray-60 px-2.5 py-1.5 text-white">
          Copy
        </span>
      ) : null}
      {dontTruncateValue && isCopied ? (
        <span className="rounded-3xl border border-gray-20 bg-green-10 px-2.5 py-1.5 text-white">
          Copied
        </span>
      ) : null}
      {!dontTruncateValue && isCopied ? (
        <BsCheckAll className="animate-pulse text-sm text-green-10" />
      ) : null}
      {!dontTruncateValue && !isCopied ? (
        <span className="fill-inherit text-xl">
          <CopyIcon scale={0.4} />
        </span>
      ) : null}
      {/* {isCopied ? (
        <BsCheckAll className="animate-pulse text-sm text-green-10" />
      ) : (
        <span className="fill-inherit text-xl">
          <CopyIcon scale={0.4} />
        </span>
      )} */}
    </button>
  );
}

export default TransactionReference;
