import { Outlet } from "react-router-dom";
import ApplicationLayout from "../../module/applications/components/Layout";
import IncludeLayout from "../../module/applications/components/Layout/IncludeLayout";
import WithdrawalSettingsLayout from "../../module/wallet/components/SettingsLayout";
import ComplianceLayout from "../../module/compliance/components/Layout";
import SettingsLayout from "../../module/settings/components/Layout";
import PolicyLayout from "../../module/applications/components/PolicyLayout";
import UtilityLayout from "../../module/utility/components/Layout";
import OnboardLayout from "../../module/onboarding/components/Layout";
import { GenLayoutProps } from "../types";
import ApiSettingsLayout from "../../module/applications/pages/ApiSettings/Layout";
import VirtualCardLayout from "../../module/virtualcards/components/Layout";

export const GenerateLayout = ({ layout }: GenLayoutProps): JSX.Element => {
  let template: any = null;
  switch (layout) {
    case "application":
      template = <ApplicationLayout />;
      break;
    case "include":
      // template = <IncludeLayout />;
      template = (
        <ApplicationLayout>
          <IncludeLayout />
        </ApplicationLayout>
      );
      break;
    case "compliance":
      template = <ComplianceLayout />;
      break;
    case "setting":
      template = <SettingsLayout />;
      break;
    case "utility":
      template = (
        <ApplicationLayout>
          <UtilityLayout />
        </ApplicationLayout>
      );
      break;
    case "withdrawal-setting":
      template = <WithdrawalSettingsLayout />;
      break;
    case "policy":
      template = <PolicyLayout />;
      break;
    case "onboarding":
      template = <OnboardLayout />;
      break;
    case "api-setting":
      template = (
        <ApplicationLayout>
          <ApiSettingsLayout />
        </ApplicationLayout>
      );
      break;
    case "virtual-card":
      template = (
        <ApplicationLayout>
          <VirtualCardLayout />
        </ApplicationLayout>
      );
      break;

    default:
      template = <Outlet />;
      break;
  }
  return <>{template}</>;
};
