import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { UserSlice } from "../types/module";
import * as tk from "./thunk";
import { logUserOut } from "../../auth/store/thunk";

const initialState: UserSlice = {
  loading: false,
  not_verified_loading: false,
  members: {
    data: [],
    loading: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetData: (state) => {
      state.members = { ...initialState.members };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logUserOut.fulfilled, (state) => {
        state.members.data = { ...initialState.members.data };
      })
      .addCase(tk.listUsers.fulfilled, (state, { payload }) => {
        state.members.data = payload.data;
      })
      .addCase(tk.updateAccountStatus.fulfilled, (state, { payload, meta }) => {
        state.members.data = state.members.data.map(
          (itm) =>
            /* itm._id === meta.arg
            ? { ...itm, is_suspended: !itm.is_suspended }
            :  */ itm
        );
      })
      .addMatcher(
        isAnyOf(
          tk.resendInvitation.pending,
          tk.inviteUser.pending,
          tk.rescindInvitation.pending,
          tk.updateAccountType.pending
        ),
        (state) => {
          state.not_verified_loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.resendInvitation.fulfilled,
          tk.resendInvitation.rejected,
          tk.inviteUser.fulfilled,
          tk.inviteUser.rejected,
          tk.rescindInvitation.fulfilled,
          tk.rescindInvitation.rejected,
          tk.updateAccountType.fulfilled,
          tk.updateAccountType.rejected
        ),
        (state) => {
          state.not_verified_loading = false;
        }
      )
      .addMatcher(
        isAnyOf(tk.listUsers.pending, tk.updateAccountStatus.pending),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.listUsers.fulfilled,
          tk.listUsers.rejected,
          tk.updateAccountStatus.fulfilled,
          tk.updateAccountStatus.rejected
        ),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
