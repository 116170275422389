import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { useCancelOnUnmount, useEffectOnce } from "../../../../common/hooks";
import CustomButton from "../../../../common/components/CustomButton";
import { truncatedText } from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";

type Props = {
  itmref: React.LegacyRef<SignatureCanvas>;
  containerRef: React.LegacyRef<HTMLDivElement>;
  clearHandler: VoidFunction;
  signatureValue: string;
  generateSignatureHandler: VoidFunction;
  isGeneratingId: boolean;
};

const { authorize: PageDictionary } = Language.protected.onboardingModals;

function CustomSignatureCanvas({
  clearHandler,
  itmref,
  signatureValue,
  generateSignatureHandler,
  containerRef,
  isGeneratingId,
}: Props) {
  const value = truncatedText(signatureValue, 24);
  const cancelPendingRequests = useCancelOnUnmount();
  useEffectOnce(() => {
    return () => {
      cancelPendingRequests();
    };
  });
  return (
    <>
      <div className="relative h-full w-full rounded-xl border p-2 ">
        <div ref={containerRef}>
          <SignatureCanvas
            ref={itmref}
            penColor="black"
            canvasProps={{
              className: "sigCanvas w-full h-64",
            }}
          />
        </div>
        <CustomButton
          name="canvas"
          isloading={isGeneratingId}
          onClick={generateSignatureHandler}
          type="button"
          className="absolute right-2 bottom-2 px-2"
        >
          {PageDictionary.formLabels.generate_signature}
        </CustomButton>
      </div>
      <div className=" flex items-center justify-between text-sm font-bold">
        <button type="button" onClick={clearHandler}>
          {PageDictionary.formLabels.clear_field}
        </button>
        {signatureValue && (
          <p>
            {PageDictionary.formLabels.signature + " "}
            <span className="font-normal">{value}</span>
          </p>
        )}
      </div>
    </>
  );
}

export default CustomSignatureCanvas;
