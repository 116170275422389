import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getDashboardChart, getDashboardInfo } from "./thunk";
import { Idashboard, StatBoardCategory } from "../types";
import { PageDictionary } from "../components/Chart/config";
import { logUserOut } from "../../auth/store/thunk";

const initialState: Idashboard = {
  chartData: {
    data: {
      label: [],
      datasets: [
        {
          id: "incoming",
          label: PageDictionary.datasetLabel_one,
          data: [],
          borderColor: "rgba(68, 207, 149, 1)",
          backgroundColor: "rgba(238, 242, 255, 0.5)",
          fill: true,
          showLine: true,
          borderWidth: 2,
          pointRadius: 0,
          lineTension: 0.3,
          radius: 6,
        },
        {
          id: "outgoing",
          label: PageDictionary.datasetLabel_two,
          data: [],
          borderColor: "rgba(0, 117, 226, 1)",
          backgroundColor: "rgba(238, 242, 255, 0.5)",
          fill: true,
          showLine: true,
          borderWidth: 2,
          pointRadius: 0,
          lineTension: 0.3,
          radius: 6,
        },
      ],
    },
    incoming_loading: false,
    outgoing_loading: false,
  },
  statBoard: {
    loading: false,
    data: {
      success_transaction_count: 0,
      users_count: 0,
      success_transaction_volume: {
        amount: 0,
        currency: "USD",
      },
      total_settlement: {
        amount: 0,
        currency: "USD",
      },
    },
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    toggleCategory: (state, { payload }: PayloadAction<StatBoardCategory>) => {
      state.chartData.data.datasets = state.chartData.data.datasets.map((itm) =>
        itm.id === payload
          ? { ...itm, fill: !itm.fill, showLine: !itm.showLine }
          : itm
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logUserOut.fulfilled, (state) => {
        state.chartData = { ...initialState.chartData };
        state.statBoard = { ...initialState.statBoard };
      })
      .addCase(getDashboardInfo.fulfilled, (state, { payload }) => {
        state.statBoard.data = payload;
      })
      .addCase(getDashboardChart.fulfilled, (state, { meta, payload }) => {
        state.chartData.data.label = payload.map((itm) => itm[meta.arg.freq]);
        // state.chartData.data.datasets[meta.arg.cat] = payload.map(
        //   (itm) => itm.usdt_amount
        // );
        const temp = payload.map((itm) => itm.usdt_amount);
        state.chartData.data.datasets = state.chartData.data.datasets.map(
          (itm) => (itm.id === meta.arg.cat ? { ...itm, data: temp } : itm)
        );
      })

      .addMatcher(isAnyOf(getDashboardInfo.pending), (state) => {
        state.statBoard.loading = true;
      })
      .addMatcher(
        isAnyOf(getDashboardInfo.rejected, getDashboardInfo.fulfilled),
        (state) => {
          state.statBoard.loading = false;
        }
      )
      .addMatcher(isAnyOf(getDashboardChart.pending), (state, { meta }) => {
        if (meta.arg.cat === "incoming") {
          state.chartData.incoming_loading = true;
        }
        if (meta.arg.cat === "outgoing") {
          state.chartData.outgoing_loading = true;
        }
      })
      .addMatcher(
        isAnyOf(getDashboardChart.rejected, getDashboardChart.fulfilled),
        (state, { meta }) => {
          if (meta.arg.cat === "incoming") {
            state.chartData.incoming_loading = false;
          }
          if (meta.arg.cat === "outgoing") {
            state.chartData.outgoing_loading = false;
          }
        }
      );
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
