import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../../common/components/CustomButton";
import CenterModal from "../../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch } from "../../../../../common/hooks";
import { updateAppUserStatus } from "../../../store/thunk";
import { iHandleSubmit } from "../../../types";
import Language from "../../../../../common/utils/language/en";

const {
  ApplicationPolicyModal: { block_policy },
  ApplicationUserModal: { block_user },
} = Language.protected;

const PageDictionary = { block_user, block_policy };

export function BlockUser() {
  const { userid, appid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const active_status = location.state?.data?.is_active;
  const col_type = location.state?.colIndex;

  const cancelHandler = () => {
    navigate(location.state.background.pathname, {
      replace: true,
      state: null,
    });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!appid || !userid) return;
    if (!location.state?.colIndex || !location.state?.data) return;
    let req: any = {
      appid,
      userid,
    };
    if (col_type === "user_action") {
      req.data = { is_active: active_status };
      dispatch(updateAppUserStatus(req)).unwrap().then(cancelHandler);
    }
    // navigate(location.state.background.pathname, {
    //   replace: true,
    //   state: null,
    // });
  };

  return (
    <CenterModal className="pt-26 pb-32" handleClose={cancelHandler}>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-blue_gray-10 text-2xl">
          {col_type === "user_action"
            ? PageDictionary.block_user.title
            : PageDictionary.block_policy.title}
        </h3>
        <p className="text-sm text-red-40">
          {col_type === "user_action"
            ? PageDictionary.block_user.subtitle(active_status)
            : PageDictionary.block_policy.subtitle(active_status)}
        </p>
        <div className="flex items-center gap-x-9">
          <CustomButton variant type="button" onClick={cancelHandler}>
            {col_type === "user_action"
              ? PageDictionary.block_user.actions.cancel
              : PageDictionary.block_policy.actions.cancel}
          </CustomButton>
          <CustomButton>
            {col_type === "user_action"
              ? PageDictionary.block_user.actions.continue
              : PageDictionary.block_policy.actions.continue}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}
