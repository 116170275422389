import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import { IbankAccountType, iHandleSubmit } from "../../../types";
import CustomButton from "../../../../../common/components/CustomButton";
import { useAppSelector } from "../../../../../common/hooks";
import {
  DropDownItem,
  DropdownMenu,
  DropDownMenuContent,
  DropdownMenuTrigger,
} from "../../../../../common/components/CustomDropDown";
import routes from "../../../../../common/routes";
import { ArrowBack } from "../../../../../static/images/icons";
import Language from "../../../../../common/utils/language/en";
import { mergeClassName } from "../../../../../common/utils/helpers";

const { BankAccountType: PageDictionary } =
  Language.protected.walletModals.withdrawalSetting;

function BankAccountType() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useAppDispatch();
  const { bank_account_types } = useAppSelector(
    (state) => state.wallet.withdrawal.data
  );

  const [selected, setSelected] = useState({ name: "", id: "" });

  const changeHandler = (itm: IbankAccountType) => {
    setSelected({ name: itm.transfer_type, id: itm._id });
  };

  const backHandler = () => {
    navigate(-1);
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!appid || selected.name === "") return;
    const path =
      routes.protected.applications.app.wallet.settings.withdrawal_details.bank.add_bank.link(
        appid
      );
    navigate(path, {
      state: { background: location.state.background, data: selected },
    });
  };

  return (
    <SideModal
      onclickBackDrop={backHandler}
      className="p-8 md:pt-15 md:pl-14 md:pr-28 md:pb-18"
    >
      <header className="flex items-center gap-x-8 text-3643 ">
        <button onClick={backHandler}>
          <span className="flex h-8 w-8">
            <ArrowBack />
          </span>
        </button>
        <h2>{PageDictionary.title}</h2>
      </header>
      <form className="flex flex-col gap-y-15 pt-12" onSubmit={submitHandler}>
        <h3 className="text-2017 font-medium text-gray-430">
          {PageDictionary.form.details}
        </h3>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="h-12 min-w-[12rem] cursor-pointer appearance-none rounded-xl border border-gray-340 bg-arrowdown bg-[90%] bg-no-repeat py-3 pl-6 pr-11 text-start text-base font-bold">
              {selected.name}
            </button>
          </DropdownMenuTrigger>
          <DropDownMenuContent className="shadow-pool_card z-10 flex w-full cursor-pointer flex-col rounded-lg bg-white font-bold">
            {bank_account_types?.map((acc) => (
              <DropDownItem key={acc?._id} className="group">
                <button
                  onClick={() => changeHandler(acc)}
                  className={mergeClassName(
                    "flex w-full items-center gap-x-2 px-4 py-2 text-sm text-black group-first:pt-3.5 group-last:pb-7",
                    selected?.id === acc?._id ? "text-red-10" : ""
                  )}
                >
                  <span>{acc?.transfer_type}</span>
                </button>
              </DropDownItem>
            ))}
          </DropDownMenuContent>
        </DropdownMenu>
        <div className="flex flex-col gap-y-9">
          <CustomButton className="self-start py-3.5 px-14">
            {PageDictionary.form.submit}
          </CustomButton>
        </div>
      </form>
    </SideModal>
  );
}

export default BankAccountType;
