import React from "react";

type Props = {};

export const NotificationIcon = (props: Props) => {
  return (
    <svg
      width="46"
      height="38"
      viewBox="0 0 46 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8658 29.2188C22.2513 28.1609 21.5186 26.1535 21.5186 22.5978V21.8629C21.5186 17.2772 17.8397 13.5191 13.3178 13.4856C13.2967 13.4855 13.2757 13.4854 13.2546 13.4854C11.0604 13.4882 8.95712 14.3626 7.40755 15.9161C5.85798 17.4697 4.989 19.5752 4.99178 21.7694V22.5978C4.99178 26.1533 4.25874 28.1606 3.6439 29.2184C3.49705 29.4699 3.41912 29.7558 3.41798 30.0471C3.41684 30.3384 3.49254 30.6249 3.63743 30.8776C3.78232 31.1303 3.99128 31.3403 4.24325 31.4865C4.49522 31.6327 4.78127 31.7099 5.07258 31.7103H9.11277C9.11277 32.8088 9.54916 33.8623 10.3259 34.6391C11.1027 35.4159 12.1563 35.8523 13.2548 35.8523C14.3533 35.8523 15.4069 35.4159 16.1837 34.6391C16.9604 33.8623 17.3968 32.8088 17.3968 31.7103H21.437C21.7283 31.7099 22.0143 31.6327 22.2662 31.4866C22.5182 31.3404 22.7271 31.1304 22.872 30.8778C23.0169 30.6251 23.0926 30.3387 23.0915 30.0474C23.0904 29.7562 23.0126 29.4704 22.8658 29.2188ZM13.2548 34.1955C12.5959 34.1947 11.9642 33.9326 11.4983 33.4667C11.0324 33.0008 10.7703 32.3692 10.7696 31.7103H15.74C15.7393 32.3692 15.4772 33.0008 15.0113 33.4667C14.5454 33.9326 13.9137 34.1947 13.2548 34.1955Z"
        fill="#343131"
      />
      <rect
        x="17"
        y="1"
        width="28"
        height="24"
        rx="12"
        fill="#DB1B3E"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M29.5954 15.8211V17H24.4975V15.8211H26.3646V10.4492C26.3646 10.2368 26.371 10.018 26.3837 9.79288L25.0583 10.9017C24.9818 10.9611 24.9053 10.9994 24.8289 11.0164C24.7566 11.0291 24.6865 11.0291 24.6186 11.0164C24.5549 11.0036 24.4975 10.9824 24.4465 10.9526C24.3955 10.9187 24.3573 10.8825 24.3318 10.8443L23.8348 10.1625L26.645 7.77284H27.9386V15.8211H29.5954ZM36.8991 15.5344C37.069 15.5344 37.2028 15.5832 37.3005 15.6809C37.4025 15.7744 37.4535 15.8997 37.4535 16.0569V17H31.1321V16.4775C31.1321 16.3713 31.1534 16.2608 31.1958 16.1461C31.2426 16.0272 31.3148 15.9209 31.4125 15.8275L34.21 13.0237C34.4436 12.7858 34.6518 12.5585 34.8344 12.3418C35.0214 12.1252 35.1764 11.9106 35.2996 11.6982C35.4228 11.4858 35.5163 11.2713 35.58 11.0546C35.648 10.8337 35.682 10.6022 35.682 10.36C35.682 10.1391 35.6501 9.94581 35.5864 9.78013C35.5227 9.6102 35.4313 9.46789 35.3124 9.35318C35.1977 9.23848 35.0575 9.15352 34.8918 9.09829C34.7304 9.03882 34.5477 9.00908 34.3438 9.00908C34.1568 9.00908 33.9827 9.03669 33.8212 9.09192C33.6641 9.1429 33.5239 9.21724 33.4007 9.31495C33.2775 9.40841 33.1734 9.51887 33.0884 9.64631C33.0035 9.77376 32.9397 9.91395 32.8972 10.0669C32.825 10.2623 32.7316 10.3919 32.6169 10.4556C32.5022 10.5193 32.3365 10.5342 32.1198 10.5002L31.2914 10.3536C31.3551 9.91183 31.4783 9.52524 31.661 9.19388C31.8437 8.85827 32.071 8.58001 32.3429 8.3591C32.619 8.13394 32.9334 7.96614 33.286 7.85568C33.6428 7.74098 34.0252 7.68363 34.433 7.68363C34.8578 7.68363 35.2465 7.74735 35.5991 7.8748C35.9517 7.998 36.2534 8.1743 36.504 8.4037C36.7546 8.63311 36.9501 8.90924 37.0903 9.23211C37.2304 9.55498 37.3005 9.91395 37.3005 10.309C37.3005 10.6489 37.2496 10.9633 37.1476 11.2521C37.0499 11.541 36.9161 11.8172 36.7461 12.0805C36.5805 12.3439 36.385 12.5988 36.1599 12.8452C35.9347 13.0916 35.699 13.3423 35.4526 13.5972L33.3752 15.7192C33.5748 15.6597 33.7724 15.6151 33.9678 15.5853C34.1675 15.5514 34.3565 15.5344 34.5349 15.5344H36.8991Z"
        fill="white"
      />
    </svg>
  );
};
