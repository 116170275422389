import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { validationRules } from "../../../../common/utils/helpers";

const merchantSchema = z.object({
  full_name: z.string({ message: "Input valid Merchant Full name" }),
  email: z.string({ message: "Input valid Merchant Email" }).email(),
  phone_number: z
    .string()
    .min(10, { message: "Phone number must be at least 10 digits long" })
    .regex(validationRules.phone, {
      message: "Invalid phone number format",
    }),
  password: z.string(),
});

export type MerchantFormType = z.infer<typeof merchantSchema>;

export const merchantResolver = zodResolver(merchantSchema);
