import { IappPolicy } from "../../../types";

export const initialstate = {
  name: "",
  type: "",
  max_daily_txn_freq: "",
  max_daily_txn_amount: "",
  max_transaction_amount: "",
  allowed_duration_btw_transaction: "",
  merchant_app: "",
  description: "",
  quarantine_transaction_amount: "",
  quarantine_transaction_freq: "",
};
export const initialerrorstate = {
  name: false,
  type: false,
  max_daily_txn_freq: false,
  max_daily_txn_amount: false,
  max_transaction_amount: false,
  allowed_duration_btw_transaction: false,
  merchant_app: false,
  description: false,
  quarantine_transaction_amount: false,
  quarantine_transaction_freq: false,
};

export const diffrenceHandler = (
  data: typeof initialstate,
  policy?: IappPolicy
) => {
  let diffrence: Record<string, string> = {};
  let count = 0;
  if (!policy) return { data: diffrence, count };
  const keys = Object.keys(data) as Array<keyof typeof data>;
  keys.forEach((key) => {
    // if (data[key] !== policy[key] && !!data[key] !== !policy[key]) {
    //   count++;
    //   diffrence[key] = data[key];
    // }
    if (data[key]?.toString() !== policy[key]?.toString()) {
      count++;
      diffrence[key] = data[key];
    }
  });
  return { data: diffrence, count };
};
