import { STORAGE_KEYS } from "../config/constant";

export const getItem = (key: string) => {
  return localStorage.getItem(key) || null;
};

export const removeItem = (key: string) => {
  return localStorage.removeItem(key);
};

export const setItem = (key: string, value: any) => {
  return localStorage.setItem(key, value);
};

export const clearData = () => localStorage.clear();

export const clearUserDetails = () => {
  removeItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
  removeItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY);
  removeItem(STORAGE_KEYS.CLIENT_LOCAL_2FA_PROMPT);
};

export const storeUserToken = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY, token);
};
export const storeRefreshToken = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY, token);
};
export const storeExpiredToken = (token: boolean) => {
  return setItem(STORAGE_KEYS.CLIENT_LOCAL_TOKEN_EXPIRED, token);
};
export const hideGolivePrompt = () => {
  return setItem(STORAGE_KEYS.CLIENT_LOCAL_SHOW_GO_LIVE, true);
};
export const ignore2FaPrompt = () => {
  return setItem(STORAGE_KEYS.CLIENT_LOCAL_2FA_PROMPT, true);
};
export const fetchExpiredToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_LOCAL_TOKEN_EXPIRED);
};
export const fetchGolivePrompt = () => {
  return getItem(STORAGE_KEYS.CLIENT_LOCAL_SHOW_GO_LIVE);
};
export const fetch2FaPrompt = () => {
  return getItem(STORAGE_KEYS.CLIENT_LOCAL_2FA_PROMPT);
};
export const removeExpiredToken = () => {
  return removeItem(STORAGE_KEYS.CLIENT_LOCAL_TOKEN_EXPIRED);
};
export const removeGolivePrompt = () => {
  return removeItem(STORAGE_KEYS.CLIENT_LOCAL_SHOW_GO_LIVE);
};

export const fetchUserToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
};
export const fetchRefreshToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_TOKEN_REFRESH_KEY);
};

export const fetchLocalData = () => {
  return {
    access_token: fetchUserToken(),
    ignore_prompt_2fa: fetch2FaPrompt(),
    is_live_mode_ignored: fetchGolivePrompt(),
  };
};

// session storage

export const getSessionItem = (key: string) => {
  return sessionStorage.getItem(key) || null;
};

export const removeSessionItem = (key: string) => {
  return sessionStorage.removeItem(key);
};

export const setSessionItem = (key: string, value: any) => {
  return sessionStorage.setItem(key, value);
};

export const clearSessionData = () => sessionStorage.clear();

export const storeSessionToken = (token: string) => {
  return setSessionItem(STORAGE_KEYS.CLIENT_TOKEN_SESSION_KEY, token);
};
export const storeSessionCompany = (data: string) => {
  return setSessionItem(STORAGE_KEYS.CLIENT_SESSION_DATA_KEY, data);
};

export const fetchSessionToken = () => {
  return getSessionItem(STORAGE_KEYS.CLIENT_TOKEN_SESSION_KEY);
};

export const fetchSessionCompany = () => {
  return getSessionItem(STORAGE_KEYS.CLIENT_SESSION_DATA_KEY);
};

export const fetchSessionData = () => {
  return {
    token: fetchSessionToken(),
    company_id: fetchSessionCompany(),
  };
};
