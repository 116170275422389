import React from "react";
import SideModal from "../SideModal";
import success from "../../../../static/images/success.gif";
import CustomButtom from "../../CustomButton";

type Props = {
  title: string;
  subtitle: string;
  cta: string;
  action: () => void;
};

function SuccessSideModal({ title, subtitle, cta, action }: Props) {
  return (
    <SideModal onclickBackDrop={action} className="p-8 md:px-15 md:pt-24">
      <div className="flex h-full flex-col items-center justify-center gap-y-20">
        <img src={success} alt="successful" />
        <div className=" text-center">
          <h2 className="text-4xl font-semibold leading-10 text-gray-40">
            {title}
          </h2>
          <div className="mt-6">
            <p className=" text-sm font-medium leading-[1.875rem] text-gray-70">
              {subtitle}
            </p>
          </div>
        </div>
        <CustomButtom
          className="py-3.5 px-14 text-base leading-7"
          onClick={action}
        >
          {cta}
        </CustomButtom>
      </div>
    </SideModal>
  );
}

export default SuccessSideModal;
