import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import {
  useAppDispatch,
  useAppSelector,
  useCopyToClipboard,
} from "../../../../common/hooks";
import { iHandleChange } from "../../types";
import Language from "../../../../common/utils/language/en";
import { useForm } from "react-hook-form";
import { ShareholderKycFormType, shareholderKycResolver } from "./schema";
import { BsCheckAll } from "react-icons/bs";
import { CopyIcon } from "../../../../static/images/icons";
import { sendGenericEmail } from "../../../settings/store/thunk";
import { useEffect, useState } from "react";
import { truncatedText } from "../../../../common/utils/helpers";
import { listShareholders, verifyAxisKyc } from "../../store/thunk";

const { shareholder_kyc: PageDictionary } =
  Language.protected.compalinaceModals;

function ShareholderKycForm() {
  const navigate = useNavigate();
  const state = useLocation().state;
  const location = state.background.pathname;
  const kyc_link = state?.data?.link || "";
  const kyc_reference = state?.data?.reference || "";
  const { loading } = useAppSelector((state) => state.compliance.shareholders);
  const dispatch = useAppDispatch();
  const [isloading, setIsloading] = useState(false);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ShareholderKycFormType>({
    resolver: shareholderKycResolver,
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });

  const disableSubmitBtn = Object.keys(errors).length > 0 || loading;

  const { copyToClipboard, isCopied } = useCopyToClipboard();

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(verifyAxisKyc(kyc_reference));
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);

  const handleCopyClick = () => {
    copyToClipboard(kyc_link);
  };

  const closeModal = () => {
    if (loading || isloading) return;
    navigate(location, { replace: true });
    dispatch(listShareholders({ page: 1, page_size: 10 }));
  };

  const changeHandler: iHandleChange = (e) => {
    const { name: tName, value } = e.target;
    if (loading) return;
    const name = tName as keyof ShareholderKycFormType;
    setValue(name, value, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const submitHandler = (data: ShareholderKycFormType) => {
    setIsloading(true);
    dispatch(
      sendGenericEmail({ body: kyc_link, recipients: [data.email] })
    ).then(() => {
      setIsloading(false);
      closeModal();
    });
  };

  return (
    <SideModal
      onclickBackDrop={closeModal}
      className="flex flex-col gap-y-7 p-8 md:pt-15 md:pl-14 md:pr-28"
    >
      <header className="flex flex-col gap-y-12">
        <h3 className="text-4xl">{PageDictionary.title}</h3>
        <p>{PageDictionary.sub}</p>
      </header>
      <div className="flex items-center gap-x-2">
        <a
          className="text-2136 text-[#797D8C]"
          target="_blank"
          rel="noreferrer"
          href={kyc_link}
        >
          {truncatedText(kyc_link, 40)}
        </a>
        <button onClick={handleCopyClick}>
          {isCopied ? (
            <BsCheckAll className="text-2xl" />
          ) : (
            <span>
              <CopyIcon scale={0.75} />
            </span>
          )}
        </button>
      </div>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="flex items-end gap-x-3"
      >
        <CustomInput
          name="email"
          label={PageDictionary.form_elements.email}
          type="email"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.email?.message}
          errortext={errors.email?.message}
        />
        <CustomButton
          isloading={loading}
          disabled={disableSubmitBtn}
          className="px-6 py-2 text-base leading-7"
        >
          {PageDictionary.form_elements.submit}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default ShareholderKycForm;
