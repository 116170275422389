import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { updateUserProfile } from "../../store/thunk";
import { ignore2FaPrompt } from "../../../../common/service/storage";
import { iHandleChange } from "../../types";
import Language from "../../../../common/utils/language/en";

const PageDictionary = Language.protected.settingsModal.toggle2fa;

const initialstate = {
  password: "",
};

function ToggleTwoFactor() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tempstate = useLocation()?.state;
  const location: Location = tempstate.background;
  const [inData, setInData] = useState(initialstate);
  const { is_2fa_enabled } = useAppSelector((state) => state.auth.user);

  const { loading } = useAppSelector((state) => state.settings);

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const cancelHandler = () => {
    if (loading) return;
    if (tempstate?.prompt) {
      ignore2FaPrompt();
    }
    const path = location.pathname;
    navigate(path, { replace: true });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = { is_2fa_enabled: !is_2fa_enabled, ...inData };

    dispatch(updateUserProfile(data)).unwrap().finally(cancelHandler);
  };

  return (
    <CenterModal className="pb-20" handleClose={cancelHandler} blurBg>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
        autoComplete="off"
      >
        <h3 className="text-2xl text-red-10">{PageDictionary.warn_text}</h3>
        <p>{PageDictionary.form_label}</p>
        <div className="self-stretch">
          <CustomInput
            label={PageDictionary.password_label}
            containerClassName="self-stretch flex flex-col"
            labelClassName="text-left"
            type="password"
            name="password"
            value={inData.password}
            onChange={changeHandler}
          />
        </div>
        <div className="flex items-center gap-x-9">
          <CustomButton type="button" variant onClick={cancelHandler}>
            {PageDictionary.cancel}
          </CustomButton>
          <CustomButton isloading={loading} disabled={inData.password === ""}>
            {PageDictionary.submit}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default ToggleTwoFactor;
