import React, { useCallback } from "react";
import { useNavigate, Outlet, useParams, useLocation } from "react-router-dom";
import Banner from "../Banner";
import { DynamicHorizontalNav as HorizontalNav } from "../../../../common/components/Layout/HorizontalNav";
// import { utilityNavLinks } from "../../../../common/utils/helpers/navHelpers";
import Language from "../../../../common/utils/language/en";
import {
  useAppDispatch,
  useCancelOnUnmount,
  useEffectOnce,
} from "../../../../common/hooks";
import { getUtility } from "../../store/thunk";
import { utilityActions } from "../../store/slice";
import QueryFilter from "../QueryFilter";
import { paginationActions } from "../../../../store/helperReducers/paginationSlice";
import CustomPageTitle from "../../../../common/components/CustomPageTitle";

const { header: PageDictionary } = Language.protected.Layout.utility_layout;

function Layout() {
  const { appid } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const cancelPendingRequests = useCancelOnUnmount();

  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, link: any, name: string) => {
      e.preventDefault();
      const path = link.link(appid);
      dispatch(paginationActions.resetData());
      navigate(path);
    },
    [appid] //eslint-disable-line
  );

  useEffectOnce(() => {
    if (!appid) return;
    dispatch(getUtility({ appid }));
    return () => {
      dispatch(utilityActions.resetutility());
      cancelPendingRequests();
    };
  });

  return (
    <section className="flex h-full flex-col">
      <p className="mb-5 text-xs font-medium text-teal-40">
        {PageDictionary.headline_text}
      </p>
      <Banner />
      <section className="flex flex-1 flex-col">
        <CustomPageTitle
          title={`Utility ${
            pathname.includes("history") ? "History" : "Records"
          }`}
          small
          classNames={{
            title: "text-2226 font-bold",
            container: "-mb-5 flex items-center justify-between pt-15",
          }}
          tooltip={
            pathname.includes("history")
              ? "This is the records of utilities purchased by your user"
              : "The utilities wallet is your prefunded wallet used to process your endusers purchases of utilies"
          }
        />
        <div className="flex items-center justify-between">
          <HorizontalNav
            navlinks={[]}
            handleClick={clickHandler}
            className="invisible"
          />
          {pathname.includes("history") ? <QueryFilter /> : null}
        </div>
        <div className="relative h-full flex-1 overflow-y-scroll">
          <Outlet />
        </div>
      </section>
    </section>
  );
}

export default Layout;
