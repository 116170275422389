import React from "react";
import CustomButtom from "../CustomButton";
import CustomToolTip from "../CustomToolTip";
import { mergeClassName } from "../../utils/helpers";

type ClassName = "title" | "button" | "sub" | "container" | "texts";

type Props = {
  small?: boolean;
  title?: string;
  buttonTitle?: string;
  buttonAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  buttonLoader?: boolean;
  tooltip?: string;
  textClassName?: string;
  buttonClassName?: string;
  subTitle?: string;
  classNames?: Partial<Record<ClassName, string>>;
};

function CustomPageTitle({
  buttonTitle,
  buttonAction,
  title,
  small,
  buttonLoader,
  tooltip,
  textClassName,
  buttonClassName,
  subTitle,
  classNames,
}: Props) {
  return (
    <header
      className={mergeClassName(
        "flex select-none flex-wrap items-center justify-between",
        classNames?.container
      )}
    >
      <div className={mergeClassName(classNames?.texts)}>
        {title && (
          <h1
            className={mergeClassName(
              "flex items-center gap-x-2.5 font-bold text-base-20",
              small ? "text-2136" : "text-2826",
              textClassName,
              classNames?.title
            )}
          >
            {title}
            {tooltip && <CustomToolTip tip={tooltip} />}
          </h1>
        )}
        {subTitle && (
          <p className={mergeClassName(classNames?.sub)}>{subTitle}</p>
        )}
      </div>
      {buttonTitle ? (
        <CustomButtom
          className={mergeClassName(
            "py-2.5 text-base font-medium leading-6.5",
            small ? "w-36 rounded-lg px-0 text-center" : "px-9",
            buttonClassName
          )}
          onClick={buttonAction}
          isloading={buttonLoader}
        >
          {buttonTitle}
        </CustomButtom>
      ) : null}
    </header>
  );
}

export default CustomPageTitle;
