// import cuid from "cuid";

import Language from "../language/en";

const {
  ApplicationsPage: { table: ApplicationsPage },
  ApplicationTransactionPage: { table: ApplicationTransactionPage },
  ApplicationUsersPage: { table: ApplicationUsersPage },
  ApplicationPolicyPage: {
    transaction_policy: { table: ApplicationPolicyPage_tp },
    user_category: { table: ApplicationPolicyPage_uc },
    notification_config: { table: ApplicationPolicyPage_nconfig },
    risk_record: { table: ApplicationPolicyPage_risk_r },
  },
  ApplicationPages: {
    Transaction_information: { tables: TransactionInformationPage },
  },
  compalinacePages: {
    business_document: { table: CompalinacePage_bisdoc },
    shareholders: { table: CompalinacePage_shareholder },
  },
  settingsPages: {
    activity_log: { table: SettingsPage_alog },
  },
  UserPage: { table: UserPage },
  walletPages: {
    walletPage: { table: WalletPage },
    cryptoWithdrawalPage: { table: CryptoWithdrawalPage },
  },
  utilityPages: {
    UtilityHistoryPage: { table: UtilityPage },
    WalletRecordsPage: { table: UtilityRecordsPage },
  },
  includePages: {
    WalletPage: { table: IncludeWalletPage },
  },
} = Language.protected;

export const tableHeader = {
  active_whiteLabel_users: {
    columns: [
      {
        title: "Fullname",
        dataIndex: "fullname",
        key: "fullname",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Account No.",
        dataIndex: "account_no",
        key: "account_no",
      },
      {
        title: "TotalTx",
        dataIndex: "total_tx",
        key: "total_tx",
      },
      {
        title: "Action",
        dataIndex: "activeWhiteLabelAction",
        key: "activeWhiteLabelAction",
      },
    ],
  },
  applications: {
    columns: [
      {
        title: ApplicationsPage.app_name,
        dataIndex: "app_name",
        key: "name",
      },
      {
        title: ApplicationsPage.app_id,
        dataIndex: "app_id",
        key: "app_id",
      },
      // {
      //   title: ApplicationsPage.config_key,
      //   dataIndex: "app_config_keys",
      //   key: "keys",
      // },
      {
        title: ApplicationsPage.created,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "",
        dataIndex: "edit_app",
        key: "",
      },
    ],
  },
  transaction_information: {
    general: {
      columns: [
        {
          title: TransactionInformationPage.general_table.amount,
          dataIndex: "dollar_amount",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.type,
          dataIndex: "",
          key: "type",
        },
        {
          title: TransactionInformationPage.general_table.ref,
          dataIndex: "ref_item",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.date,
          dataIndex: "created_at",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.exchange_rate,
          dataIndex: "exchange_rate",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.destination_country,
          dataIndex: "",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.status,
          dataIndex: "txn_status",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.currency,
          dataIndex: "currency",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.balance,
          dataIndex: "acc_balance",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.commission,
          dataIndex: "txn_fee",
          key: "",
        },
        {
          title: TransactionInformationPage.general_table.description,
          dataIndex: "",
          key: "description",
        },
      ],
    },
    customer: {
      columns: [
        {
          title: TransactionInformationPage.customer_table.name,
          dataIndex: "",
          key: "full_name",
        },
        {
          title: TransactionInformationPage.customer_table.email,
          dataIndex: "",
          key: "email",
        },
      ],
    },
    beneficiary: {
      columns: [
        {
          title: TransactionInformationPage.beneficiary_table.name,
          dataIndex: "",
          key: "",
        },
        {
          title: TransactionInformationPage.beneficiary_table.number,
          dataIndex: "",
          key: "",
        },
        {
          title: TransactionInformationPage.beneficiary_table.app,
          dataIndex: "",
          key: "",
        },
        {
          title: TransactionInformationPage.beneficiary_table.country,
          dataIndex: "",
          key: "",
        },
      ],
    },
  },
  withdrawal_setting: {
    crypto: [
      {
        title: CryptoWithdrawalPage.address,
        dataIndex: "address",
        key: "address",
      },
      {
        title: CryptoWithdrawalPage.network,
        dataIndex: "network",
        key: "network",
      },
      {
        title: CryptoWithdrawalPage.token_name,
        dataIndex: "token_name",
        key: "crypto_symbol",
      },
      {
        title: "",
        dataIndex: "deactivate_btn",
        key: "",
      },
    ],
    bank: [
      {
        title: "",
        dataIndex: "deactivate_btn",
        key: "",
      },
    ],
  },
  wallet_record: {
    columns: [
      {
        title: WalletPage.amount,
        dataIndex: "dollar_amount",
        key: "amount",
      },
      {
        title: WalletPage.local_amount,
        dataIndex: "local_amount",
        key: "amount",
      },
      {
        title: WalletPage.tx_type,
        dataIndex: "tx_type",
        key: "type",
      },
      {
        title: WalletPage.tx_ref,
        dataIndex: "ref_item",
        key: "",
      },
      {
        title: WalletPage.description,
        dataIndex: "",
        key: "description",
      },
      {
        title: WalletPage.timestamp,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: WalletPage.status,
        dataIndex: "txn_status",
        key: "",
      },
    ],
  },
  utility_record: {
    columns: [
      {
        title: UtilityRecordsPage.amount,
        dataIndex: "dollar_amount",
        key: "",
      },
      {
        title: UtilityRecordsPage.local_amount,
        dataIndex: "local_amount",
        key: "amount",
      },
      {
        title: UtilityRecordsPage.tx_type,
        dataIndex: "tx_type",
        key: "type",
      },
      {
        title: UtilityRecordsPage.tx_ref,
        dataIndex: "ref_item",
        key: "",
      },
      {
        title: UtilityRecordsPage.description,
        dataIndex: "description",
        key: "",
      },
      {
        title: UtilityRecordsPage.timestamp,
        dataIndex: "created_at",
        key: "timestamp",
      },
      {
        title: UtilityRecordsPage.status,
        dataIndex: "txn_status",
        key: "",
      },
    ],
  },
  utility_history: {
    columns: [
      {
        title: UtilityPage.amount,
        dataIndex: "dollar_amount",
        key: "",
      },
      {
        title: UtilityPage.tx_type,
        dataIndex: "utility_txn_type",
        key: "",
      },
      {
        title: UtilityPage.provider,
        dataIndex: "utility_provider",
        key: "",
      },
      {
        title: UtilityPage.tx_ref,
        dataIndex: "ref_item",
        key: "",
      },
      {
        title: UtilityPage.remark,
        dataIndex: "description",
        key: "",
      },
      {
        title: UtilityPage.timestamp,
        dataIndex: "created_at",
        key: "timestamp",
      },
      {
        title: UtilityPage.status,
        dataIndex: "txn_status",
        key: "",
      },
      {
        title: UtilityPage.action,
        dataIndex: "utility_actions",
        key: "",
      },
    ],
  },
  transactions: {
    columns: [
      {
        title: ApplicationTransactionPage.amount,
        dataIndex: "dollar_amount",
        key: "",
      },
      {
        title: ApplicationTransactionPage.local_amount,
        dataIndex: "local_amount",
        key: "",
      },
      {
        title: ApplicationTransactionPage.app_id,
        dataIndex: "",
        key: "merchant_app",
      },
      {
        title: ApplicationTransactionPage.reference,
        dataIndex: "ref_item",
        key: "",
      },
      {
        title: ApplicationTransactionPage.description,
        dataIndex: "description",
        key: "",
      },
      {
        title: ApplicationTransactionPage.timestamp,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: ApplicationTransactionPage.balance,
        dataIndex: "acc_balance",
        key: "balance",
      },
      {
        title: ApplicationTransactionPage.fee,
        dataIndex: "txn_fee",
        key: "",
      },
      // {
      //   title: ApplicationTransactionPage.status,
      //   dataIndex: "txn_status",
      //   key: "",
      // },
      {
        title: "",
        dataIndex: "report_item",
        key: "",
      },
    ],
  },
  users: {
    columns: [
      {
        title: ApplicationUsersPage.full_name,
        dataIndex: "fullname",
        key: "name",
      },
      {
        title: ApplicationUsersPage.email,
        dataIndex: "",
        key: "email",
      },
      {
        title: ApplicationUsersPage.category,
        dataIndex: "acc_num",
        key: "",
      },
      // {
      //   title: ApplicationUsersPage.kyc,
      //   dataIndex: "kyc_user",
      //   key: "kyc",
      // },
      {
        title: ApplicationUsersPage.total_tx,
        dataIndex: "",
        key: "total_transaction",
      },
      {
        title: ApplicationUsersPage.time,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: ApplicationUsersPage.action,
        dataIndex: "user_action",
        key: "action",
      },
    ],
  },
  app_audit_reporting: {
    columns: [
      {
        title: "Schedule",
        dataIndex: "app_audit_schedule",
        key: "",
      },
      {
        title: "Send Audit Report To",
        dataIndex: "send_audit",
        key: "send_audit",
      },
      {
        title: "Audit Report History",
        dataIndex: "audit_report_history",
        key: "audit_report_history",
      },
      {
        title: "Data Format",
        dataIndex: "data_format",
        key: "data_format",
      },
      {
        title: "Manage",
        dataIndex: "manage",
        key: "manage",
      },
    ],
  },
  policy: {
    transaction: {
      columns: [
        {
          title: ApplicationPolicyPage_tp.policy_name,
          dataIndex: "policy_name",
          key: "name",
        },
        {
          title: ApplicationPolicyPage_tp.rule,
          dataIndex: "policy_rule",
          key: "rule",
        },
        {
          title: ApplicationPolicyPage_tp.reference,
          dataIndex: "ref",
          key: "model_id",
        },
        {
          title: ApplicationPolicyPage_tp.risk_management.title,
          dataIndex: "policy_noti",
          key: "",
          tooltip: ApplicationPolicyPage_tp.risk_management.tooltip,
        },
        {
          title: ApplicationPolicyPage_tp.time,
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: ApplicationPolicyPage_tp.action,
          dataIndex: "policy_action",
          key: "action",
        },
      ],
    },
    user: {
      columns: [
        {
          title: ApplicationPolicyPage_uc.user_cat,
          dataIndex: "user_name",
          key: "name",
        },
        {
          title: ApplicationPolicyPage_uc.incoming_policy,
          dataIndex: "policy_cat_name_in",
          key: "",
        },
        {
          title: ApplicationPolicyPage_uc.outgoing_policy,
          dataIndex: "policy_cat_name_out",
          key: "",
        },
        {
          title: ApplicationPolicyPage_uc.cat_id,
          dataIndex: "cat_id",
          key: "category_id",
        },

        {
          title: ApplicationPolicyPage_uc.date,
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: ApplicationPolicyPage_uc.action,
          dataIndex: "policy_manage",
          key: "",
        },
      ],
    },
    risk_record: {
      columns: [
        {
          title: ApplicationPolicyPage_risk_r.trans_ref,
          dataIndex: "",
          key: "_id",
        },
        {
          title: ApplicationPolicyPage_risk_r.email,
          dataIndex: "",
          key: "email",
        },
        {
          title: ApplicationPolicyPage_risk_r.amount,
          dataIndex: "amount",
          key: "",
        },
        {
          title: ApplicationPolicyPage_risk_r.rule,
          dataIndex: "risk_rules",
          key: "",
        },

        {
          title: ApplicationPolicyPage_risk_r.date,
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: ApplicationPolicyPage_risk_r.action,
          dataIndex: "manage_policy_risk",
          key: "",
        },
      ],
    },
    notification_config: {
      columns: [
        {
          title: ApplicationPolicyPage_nconfig.rule,
          dataIndex: "notification_config_rule",
          key: "type",
        },
        {
          title: ApplicationPolicyPage_nconfig.notification_method,
          dataIndex: "notification_config_method",
          key: "",
        },
        {
          title: ApplicationPolicyPage_nconfig.schedule,
          dataIndex: "notification_config_schedule",
          key: "",
        },
        {
          title: ApplicationPolicyPage_nconfig.notify_to,
          dataIndex: "notification_config_notify_to",
          key: "",
        },
        {
          title: ApplicationPolicyPage_nconfig.manage,
          dataIndex: "notification_config_manage",
          key: "created_at",
        },
      ],
    },
  },
  business_document: {
    columns: [
      {
        title: CompalinacePage_bisdoc.document_name,
        dataIndex: "document_name",
        key: "name",
      },
      {
        title: CompalinacePage_bisdoc.action,
        dataIndex: "business_action",
        key: "",
      },
      {
        title: CompalinacePage_bisdoc.status,
        dataIndex: "business_status",
        key: "status",
      },
      {
        title: CompalinacePage_bisdoc.created_at,
        dataIndex: "business_document_created_at",
        key: "created_at",
      },
    ],
  },
  shareholders: {
    columns: [
      {
        title: CompalinacePage_shareholder.name,
        dataIndex: "name",
        key: "name",
      },
      {
        title: CompalinacePage_shareholder.email,
        dataIndex: "email",
        key: "email",
      },
      {
        title: CompalinacePage_shareholder.shareholder_id,
        dataIndex: "shareholder_id_proof",
        key: "",
      },
      {
        title: CompalinacePage_shareholder.proof_address,
        dataIndex: "shareholder_address_proof",
        key: "",
      },
      {
        title: CompalinacePage_shareholder.address,
        dataIndex: "",
        key: "address",
      },

      {
        title: CompalinacePage_shareholder.created_at,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: CompalinacePage_shareholder.action,
        dataIndex: "shareholder_action",
        key: "created_at",
      },
    ],
  },
  User: {
    columns: [
      {
        title: UserPage.email,
        dataIndex: "",
        key: "email",
      },
      {
        title: UserPage.created_at,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: UserPage.access,
        dataIndex: "user_access",
        key: "",
      },
      {
        title: UserPage.actions,
        dataIndex: "user_access_action",
        key: "",
      },
    ],
  },
  activity_log: {
    column: [
      {
        title: SettingsPage_alog.email,
        dataIndex: "",
        key: "email",
      },
      {
        title: SettingsPage_alog.action_performed,
        dataIndex: "",
        key: "action_performed",
      },
      {
        title: SettingsPage_alog.performed_on,
        dataIndex: "",
        key: "performed_on",
      },
      {
        title: SettingsPage_alog.created_at,
        dataIndex: "",
        key: "time",
      },
    ],
  },
  include_wallet_records: {
    columns: [
      {
        title: IncludeWalletPage.amount,
        key: "amount",
        dataIndex: "include_amount",
      },
      {
        title: IncludeWalletPage.rate,
        key: "exchange_rate",
      },
      {
        title: IncludeWalletPage.tx_ref,
        dataIndex: "ref_item",
        key: "",
      },
      {
        title: IncludeWalletPage.sender_email,
        key: "email",
      },
      {
        title: IncludeWalletPage.timestamp,
        key: "timestamp",
      },
      {
        title: IncludeWalletPage.balance,
        key: "balance",
        dataIndex: "acc_balance",
      },
      {
        title: IncludeWalletPage.fee,
        key: "txn_fee",
        dataIndex: "txn_fee",
      },
    ],
  },
};
