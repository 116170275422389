import React from "react";
import { IconType } from "./types";

function MoneySendIcon({ color = "#7F1770", scale = 1 }: IconType) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      scale={scale}
    >
      <path
        d="M7.52081 10.8854C7.52081 11.6533 8.11457 12.2708 8.84291 12.2708H10.3312C10.9646 12.2708 11.4791 11.7324 11.4791 11.0595C11.4791 10.3391 11.1625 10.0779 10.6954 9.91161L8.31248 9.08035C7.8454 8.9141 7.52874 8.66077 7.52874 7.93244C7.52874 7.26744 8.04331 6.72119 8.67664 6.72119H10.165C10.8933 6.72119 11.4871 7.33869 11.4871 8.10661"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 5.9375V13.0625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4166 9.50016C17.4166 13.8702 13.87 17.4168 9.49998 17.4168C5.12998 17.4168 1.58331 13.8702 1.58331 9.50016C1.58331 5.13016 5.12998 1.5835 9.49998 1.5835"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4167 4.75016V1.5835H14.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4583 5.54183L17.4166 1.5835"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MoneySendIcon;
