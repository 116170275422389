import React from "react";

type Props = {
  amount: string;
  currency: string;
};

function ExchangeRate({ amount, currency }: Props) {
  return <span>{amount ? `${currency}${amount}/$1`: "N/A"}</span>;
}

export default ExchangeRate;
