import React from "react";
import { IconType } from "./types";

export function CheckIcon({ scale = 1 }: IconType) {
  return (
    <svg
      width={scale * 10}
      height={scale * 9}
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke=""
    >
      <path
        d="M1.14149 5.00823L2.67877 7.1157C3.0314 7.59911 3.72348 7.67429 4.17167 7.27786L8.83055 3.15703"
        stroke="inherit"
        strokeWidth="1.52292"
        strokeLinecap="round"
      />
    </svg>
  );
}
