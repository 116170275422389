import React from "react";
import { mergeClassName } from "../../../utils/helpers";

type props = {
  className?: string;
  handleClose?: () => void;
  children: React.ReactNode;
  blurBg?: boolean;
};

function CenterModal({ children, handleClose, className, blurBg }: props) {
  return (
    <div
      onClick={handleClose}
      className="fixed inset-0 z-20 flex h-full w-full items-center justify-center bg-base-10 backdrop-blur-sm"
    >
      <section
        className={mergeClassName(
          "relative flex h-auto w-96 flex-col items-center justify-center overflow-hidden rounded-xl bg-white p-12 px-12 sm:w-[39.375rem]",
          className
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </section>
    </div>
  );
}

export default CenterModal;
