import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const kycSchema = z.object({
  email: z.string({ message: "Input valid email" }).email(),
});

export type ShareholderKycFormType = z.infer<typeof kycSchema>;

export const shareholderKycResolver = zodResolver(kycSchema);
