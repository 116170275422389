import React from "react";
import { Link } from "react-router-dom";
import routes from "../../../routes";
import { useAppSelector } from "../../../hooks";
import { AppLogo } from "../../../../static/images/icons";

const navLinks = [
  {
    label: "Help",
    path: "#",
  },
  {
    label: "Resources",
    path: "#",
  },
  {
    label: "API Docs",
    path: "#",
  },
];

const { onboarding, dashboard } = routes.protected;

export default function Header() {
  const { company } = useAppSelector((state) => state.auth);
  const home_link =
    company?.admin_approved === false ? onboarding.terms.index : dashboard;
  return (
    <header className="flex items-center justify-between bg-base-40 bg-line bg-[center_left] bg-no-repeat px-24 py-5">
      <Link
        to={home_link}
        className="row-start-1 row-end-2 flex h-9 items-center justify-center"
      >
        <span className="text-white md:block">
          <AppLogo hoverAnimate />
        </span>
      </Link>
      <nav>
        <ul className="flex items-center gap-x-10">
          {navLinks.map((item) => (
            <li className="text-white" key={item.label}>
              <Link to={item.path}>{item.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}
