import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import { getUserInfo } from "../../settings/store/thunk";
import * as tp from "../types";
import { fetchSessionData } from "../../../common/service/storage";

export const saveAgreementInfo = createAsyncThunk(
  "onboarding/saveAgreementInfo",
  async (data: tp.IagreementInfo, { fulfillWithValue, rejectWithValue }) => {
    return service
      .saveAgreementInfo(data)
      .then((res) => fulfillWithValue(data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const generateSignatureRef = createAsyncThunk(
  "onboarding/generateSignatureRef",
  async (data: Record<string, any>, { fulfillWithValue, rejectWithValue }) => {
    const { company_id, token } = fetchSessionData();

    if (company_id && token) {
      return service
        .noAuthGenerateSignatureRefrence({ id: company_id, token, data })
        .then((res) => {
          customToast.success(res.message);
          return fulfillWithValue(res?.data).payload;
        })
        .catch((err) => {
          customToast.error(err?.message);
          return rejectWithValue(err);
        });
    }

    return service
      .generateSignatureRef(data)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const completeAgreement = createAsyncThunk(
  "onboarding/completeAgreement",
  async (
    data: tp.IslaRecipientEmail,
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    const { company_id, token } = fetchSessionData();

    if (company_id && token) {
      return service
        .noAuthCompleteAgreement({ id: company_id, token, data })
        .then((res) => {
          customToast.success(res.message);
          return fulfillWithValue(res?.data).payload;
        })
        .catch((err) => {
          customToast.error(err?.message);
          return rejectWithValue(err);
        });
    }

    return service
      .completeAgreement(data)
      .then((res) => {
        customToast.success(res.message);
        dispatch(getUserInfo());
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const generateSignatureAgreementLink = createAsyncThunk(
  "onboarding/generateSignatureAgreementLink",
  async (data: tp.IgenerateLink, { fulfillWithValue, rejectWithValue }) => {
    return service
      .generateSignatureAgreementLink(data)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const refrieveAgreement = createAsyncThunk(
  "onboarding/refrieveAgreement",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    let csmToken = "";
    let id = "";
    const { company_id, token } = fetchSessionData();

    if (company_id && token) {
      csmToken = token;
      id = company_id;
    }
    return service
      .noAuthRetrieveAgreement({ id: id, token: csmToken })
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
