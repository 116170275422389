import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { SocketQuery, WalletSlice } from "../types";
import * as tk from "./thunk";
import { logUserOut } from "../../auth/store/thunk";

const initialState: WalletSlice = {
  app_wallet: {
    data: null,
    loading: false,
  },
  exchange_rate: {
    data: null,
    loading: false,
  },
  records: {
    data: [],
    loading: false,
    pagination: {
      current_page: 1,
      page_count: 1,
      pagesize: 5,
      total_count: 0,
    },
  },
  withdrawal: {
    data: {
      bank_account: [],
      bank_account_types: [],
      threshold: null,
      crypto: null,
    },
    loading: false,
  },
  socket: {
    isEstablishingConnection: false,
    isConnected: false,
    response: null,
    query: {
      app_id: null,
      txn_reference: null,
    },
  },
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetExchangeRate: (state) => {
      state.exchange_rate = { ...initialState.exchange_rate };
    },
    resetWallet: (state) => {
      state.app_wallet = { ...initialState.app_wallet };
      state.exchange_rate = { ...initialState.exchange_rate };
      state.records = { ...initialState.records };
      state.socket = { ...initialState.socket };
      state.withdrawal = { ...initialState.withdrawal };
    },
    startConnecting: (state, { payload }: PayloadAction<SocketQuery>) => {
      state.socket.isEstablishingConnection = true;
      state.socket.query.app_id = payload.app_id;
      state.socket.query.txn_reference = payload.txn_reference;
    },
    connectionEstablished: (state) => {
      state.socket.isConnected = true;
      state.socket.isEstablishingConnection = false;
    },
    receiveMessage: (
      state,
      action: PayloadAction<{
        data: any;
      }>
    ) => {
      state.socket.response = action.payload.data;
    },
    submitMessage: (
      state,
      action: PayloadAction<{
        content: any;
      }>
    ) => {
      return;
    },
    startDisconecting: (state) => {
      state.socket.isEstablishingConnection = false;
    },
    disconnectionEstablished: (state) => {
      state.socket.isConnected = false;
      state.socket.isEstablishingConnection = false;
      state.socket.response = null;
      state.socket.query.app_id = null;
      state.socket.query.txn_reference = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logUserOut.fulfilled, (state) => {
        state.app_wallet = { ...initialState.app_wallet };
        state.exchange_rate = { ...initialState.exchange_rate };
        state.records = { ...initialState.records };
        state.socket = { ...initialState.socket };
        state.withdrawal = { ...initialState.withdrawal };
      })
      .addCase(tk.getWallet.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.app_wallet.data = payload;
      })
      .addCase(tk.listAppTransactions.fulfilled, (state, { payload }) => {
        state.records.data = [...payload];
        state.records.pagination.page_count = Math.ceil(
          5 / state.records.pagination.pagesize
        );
        state.records.pagination.total_count = 5;
      })
      .addCase(tk.getExchangeRate.fulfilled, (state, { payload }) => {
        const exchangeRateKeys = Object.keys(payload) as Array<
          keyof typeof payload
        >;

        let tempRate: any = {};
        exchangeRateKeys.forEach((key) => {
          const split = key.split("/");
          if (key?.toLowerCase().startsWith("usdt")) {
            tempRate["fund_rate"] = {
              to: split[0],
              from: split[1],
              rate: payload[key],
            };
          }
          if (key?.toLowerCase().endsWith("usdt")) {
            tempRate["withdraw_rate"] = {
              to: split[0],
              from: split[1],
              rate: payload[key],
            };
          }
        });

        state.exchange_rate.data = tempRate;
      })
      .addCase(tk.addThreshold.fulfilled, (state, { payload }) => {
        state.withdrawal.data.threshold = payload;
      })
      .addCase(tk.listThresholds.fulfilled, (state, { payload }) => {
        state.withdrawal.data.threshold = payload[0];
      })
      .addCase(tk.updateThreshold.fulfilled, (state, { payload }) => {
        state.withdrawal.data.threshold = payload;
      })
      .addCase(tk.deleteThreshold.fulfilled, (state) => {
        state.withdrawal.data.threshold =
          initialState.withdrawal.data.threshold;
      })
      .addCase(tk.listAppTransactions.pending, (state) => {
        state.records.loading = true;
      })
      .addCase(tk.getExchangeRate.pending, (state) => {
        state.exchange_rate.loading = true;
      })
      .addCase(tk.addBankAccount.fulfilled, (state, { payload }) => {
        state.withdrawal.data.bank_account = [
          payload,
          ...state.withdrawal.data.bank_account,
        ];
      })
      .addCase(tk.listDeactivatedAccount.fulfilled, (state, { payload }) => {
        state.withdrawal.data.bank_account = [...payload];
      })
      .addCase(tk.listBankAccountTypes.fulfilled, (state, action) => {
        state.withdrawal.data.bank_account_types = action.payload;
      })
      .addCase(tk.getBankAccount.fulfilled, (state, { payload }) => {
        if (!payload._id) return;
        if (
          state.withdrawal.data.bank_account.find(
            (itm) => itm._id === payload._id
          )
        ) {
          return;
        }

        state.withdrawal.data.bank_account = [
          payload,
          ...state.withdrawal.data.bank_account,
        ];
      })
      .addCase(tk.updateBankAccount.fulfilled, (state, { payload }) => {
        const temp = state.withdrawal.data.bank_account
          .map((account) =>
            account._id === payload._id ? { ...payload } : account
          )
          .sort((a, b) => (a.is_active === b.is_active ? 0 : 1));
        state.withdrawal.data.bank_account = [...temp];
      })
      .addCase(tk.bankWithdrawal.fulfilled, (state, { payload }) => {
        state.app_wallet.data!.balance = payload.balance;
      })
      .addCase(tk.deactivateCryptoAddress.fulfilled, (state) => {
        state.withdrawal.data.crypto = initialState.withdrawal.data.crypto;
      })
      .addMatcher(
        isAnyOf(tk.addCryptoAddress.fulfilled, tk.getCryptoAddress.fulfilled),
        (state, { payload }) => {
          if (!payload) return;
          state.withdrawal.data.crypto = payload;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.listBankAccountTypes.pending,
          tk.getBankAccount.pending,
          tk.updateBankAccount.pending,
          tk.addBankAccount.pending,
          tk.addCryptoAddress.pending,
          tk.getCryptoAddress.pending,
          tk.deactivateCryptoAddress.pending,
          tk.listDeactivatedAccount.pending
        ),
        (state) => {
          state.withdrawal.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.listBankAccountTypes.fulfilled,
          tk.listBankAccountTypes.rejected,
          tk.listDeactivatedAccount.fulfilled,
          tk.listDeactivatedAccount.rejected,
          tk.getBankAccount.fulfilled,
          tk.getBankAccount.rejected,
          tk.updateBankAccount.fulfilled,
          tk.updateBankAccount.rejected,
          tk.addBankAccount.fulfilled,
          tk.addBankAccount.rejected,
          tk.addCryptoAddress.fulfilled,
          tk.addCryptoAddress.rejected,
          tk.getCryptoAddress.fulfilled,
          tk.getCryptoAddress.rejected,
          tk.deactivateCryptoAddress.fulfilled,
          tk.deactivateCryptoAddress.rejected
        ),
        (state) => {
          state.withdrawal.loading = initialState.withdrawal.loading;
        }
      )
      .addMatcher(
        isAnyOf(tk.getWallet.pending, tk.bankWithdrawal.pending),
        (state) => {
          state.app_wallet.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.getWallet.rejected,
          tk.getWallet.fulfilled,
          tk.bankWithdrawal.rejected,
          tk.bankWithdrawal.fulfilled
        ),
        (state) => {
          state.app_wallet.loading = initialState.app_wallet.loading;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.listAppTransactions.rejected,
          tk.listAppTransactions.fulfilled
        ),
        (state) => {
          state.records.loading = initialState.records.loading;
        }
      )
      .addMatcher(
        isAnyOf(tk.getExchangeRate.rejected, tk.getExchangeRate.fulfilled),
        (state) => {
          state.exchange_rate.loading = initialState.exchange_rate.loading;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.addThreshold.pending,
          tk.listThresholds.pending,
          tk.updateThreshold.pending,
          tk.deleteThreshold.pending
        ),
        (state) => {
          state.withdrawal.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.addThreshold.rejected,
          tk.addThreshold.fulfilled,
          tk.listThresholds.rejected,
          tk.listThresholds.fulfilled,
          tk.updateThreshold.rejected,
          tk.updateThreshold.fulfilled,
          tk.deleteThreshold.rejected,
          tk.deleteThreshold.fulfilled
        ),
        (state) => {
          state.withdrawal.loading = initialState.withdrawal.loading;
        }
      );
  },
});

export const walletActions = walletSlice.actions;

export default walletSlice.reducer;
