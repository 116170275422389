import React from "react";
import { IconType } from "./types";

function AirtimeIcon({ color = "#FF5932", scale = 1 }: IconType) {
  return (
    <svg
      width="17"
      height="24"
      viewBox="0 0 17 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      scale={scale}
    >
      <path
        d="M1.625 19.5V4.875H11.375V12.1875H13V1.625C12.9996 1.19416 12.8282 0.78108 12.5236 0.476427C12.2189 0.171773 11.8058 0.0004302 11.375 0H1.625C1.19416 0.0004302 0.78108 0.171773 0.476427 0.476427C0.171773 0.78108 0.0004302 1.19416 0 1.625V19.5C0.0004302 19.9308 0.171773 20.3439 0.476427 20.6486C0.78108 20.9532 1.19416 21.1246 1.625 21.125H4.875V19.5H1.625ZM1.625 1.625H11.375V3.25H1.625V1.625Z"
        fill={color}
      />
      <path
        d="M13.6689 18.1851V18.6167H9.26702V18.1851H13.6689ZM13.6689 19.1173V19.5488H9.26702V19.1173H13.6689ZM13.3172 16.5711V20.9902H12.5102L10.5898 18.2088H10.5552V20.9902H9.62306V16.5711H10.4409L12.3505 19.3503H12.3872V16.5711H13.3172Z"
        fill={color}
      />
      <circle
        cx="11.3125"
        cy="18.5625"
        r="4.64286"
        stroke={color}
        strokeWidth="0.714286"
      />
    </svg>
  );
}

export default AirtimeIcon;
