import { Middleware } from "redux";

const errorMiddleware: Middleware = (store) => {
  // const pendingFailedRequest: any[] = [];

  return (next) => (action: any) => {
    // const isRefreshingToken = store.getState()?.auth?.isRefreshingToken;
    // if (action.type.includes("rejected") && isRefreshingToken === true) {
    //   const action_name = action.type.split("/rejected").join("");
    //   const actionName = () => {
    //     return { type: action_name, payload: action.meta.arg };
    //   };
    //   pendingFailedRequest.push(actionName);
    // }
    // if (isRefreshingToken === false && pendingFailedRequest.length > 0) {
    //   pendingFailedRequest.forEach((itm: any, index: number) => {
    //     store.dispatch(itm());
    //     pendingFailedRequest.splice(index, 1);
    //   });
    // }

    next(action);
  };
};

export default errorMiddleware;
