import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast, { toast } from "../../../common/components/CustomToast";
import routes from "../../../common/routes";
import * as storage from "../../../common/service/storage";
import auth from "../service";
import * as type from "../type";

export const loginUser = createAsyncThunk(
  "auth/login",
  async (req: type.IloginUser, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .loginUser(req)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const verify2faToken = createAsyncThunk(
  "auth/verify2faToken",
  async (req: type.I2faToken, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .verify2faToken(req)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const send2faVerificationToken = createAsyncThunk(
  "auth/send2faVerificationToken",
  async (req: type.Iemail, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .send2faVerificationToken(req)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const emailValidate = createAsyncThunk(
  "auth/emailValidate",
  async (req: type.IregisterUserThunk, { rejectWithValue, dispatch }) => {
    const { data } = req;
    return auth
      .emailValidate({ email: data.email })
      .then((res) => {
        dispatch(registerUser(req));
      })
      .catch((err) => {
        if (err.message === "User account not found") {
          dispatch(registerUser(req));
        } else {
          customToast.error(err.message);
        }
        return rejectWithValue(err);
      });
  }
);
export const registerUser = createAsyncThunk(
  "auth/register",
  async (
    req: type.IregisterUserThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { data, navigate } = req;
    return auth
      .registerUser(data)
      .then((res) => {
        navigate(routes.nonprotected.verified, { state: data });
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const verified = createAsyncThunk(
  "auth/verified",
  async (req: type.IverifyAccount, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .verified(req)
      .then((res) => {
        // navigate(routes.protected.dashboard);
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const resendToken = createAsyncThunk(
  "auth/resendToken",
  async (data: type.Iemail, { fulfillWithValue, rejectWithValue }) => {
    return auth
      .resendToken(data)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (req: type.IemailThunk, { rejectWithValue, fulfillWithValue }) => {
    const { data, navigate } = req;
    return auth
      .resetPassword(data)
      .then((res) => {
        navigate(routes.nonprotected.enter_otp, { state: { data } });
        customToast.success(res.message);
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const verifyResetPassword = createAsyncThunk(
  "auth/verifyResetPassword",
  async (
    req: type.IverifyResetPasswordThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { data, navigate } = req;
    return auth
      .validateResetPassword(data)
      .then((res) => {
        navigate(routes.nonprotected.login);
        customToast.success(res.message);
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        customToast.error(err.message);
        if (err.message === "Invalid password reset token") {
          dispatch(resendToken({ email: data.email }));
          navigate(routes.nonprotected.enter_otp, { state: { data } });
        }
        if (
          err.message ===
          "Oops you have reached the maximum request for this operation. Retry again in 24 hours"
        ) {
          navigate(routes.nonprotected.welcome, { state: null, replace: true });
        }
        return rejectWithValue(err);
      });
  }
);
export const logUserOut = createAsyncThunk(
  "auth/logout",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    let data: any = { access: "", refresh: "" };
    if (toast.isActive("TEST-MODE")) {
      toast.dismiss("TEST-MODE");
    }
    if (storage.fetchRefreshToken() && storage.fetchUserToken()) {
      data = {
        access: storage.fetchUserToken(),
        refresh: storage.fetchRefreshToken(),
      };
    }

    return auth
      .logUserOut(data)
      .then((res) => {
        customToast.success(res.message);
        storage.clearUserDetails();
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        customToast.error(err.message);
        storage.clearData();
        return rejectWithValue(err);
      });
  }
);
export const getCountries = createAsyncThunk(
  "auth/getCountries",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return auth
      .getCountries()
      .then((res) => {
        return fulfillWithValue(res?.data.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const refreshUserToken = createAsyncThunk(
  "auth/refresh-token",
  async (
    error_call: boolean,
    { fulfillWithValue, rejectWithValue, dispatch, getState }
  ) => {
    const accessToken = storage?.fetchUserToken();
    const refreshToken = storage?.fetchRefreshToken();

    const refreshTokenData = {
      access: accessToken !== null ? accessToken : "",
      refresh: refreshToken !== null ? refreshToken : "",
    };

    return auth
      .refreshToken(refreshTokenData)
      .then((res) => {
        const access = res?.data.access;
        const refresh = res?.data.refresh;
        storage.storeUserToken(access);
        storage.storeRefreshToken(refresh);
        if (error_call) {
          window.location.reload();
        }
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        if (err.code === "ERR_CANCELED") {
          dispatch(refreshUserToken(false));
        } else {
          storage.clearData();
          window.location.replace(routes.nonprotected.login);
          storage.storeExpiredToken(true);
        }

        return rejectWithValue(err).payload;
      });
  }
);
