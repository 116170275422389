import { useLayoutEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import {
  useAppDispatch,
  useAppSelector,
  usePageTitle,
} from "../../../../common/hooks";
import routes from "../../../../common/routes";
import { onboardNavLinks } from "../../../../common/utils/helpers/navHelpers";
import { FixedHorizontalNav as HorizontalNav } from "../../../../common/components/Layout/HorizontalNav";
import Language from "../../../../common/utils/language/en";
import * as storerage from "../../../../common/service/storage";
import {
  listBusinessDocuments,
  listShareholders,
  retriveBusinessProfile,
} from "../../../compliance/store/thunk";
import { AppLogo } from "../../../../static/images/icons";
import LogoutButton from "../../../../common/components/LogoutButton";

const PageDictionary = Language.protected.Layout.onboarding_layout;

function Layout() {
  const [query] = useSearchParams();
  const external_link_company_id = storerage.fetchSessionCompany();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    business_profile: { data: business_profile_data },
    business_documents: { data: business_documents_data },
    shareholders: {
      loading: shareholder_loading_state,
      data: shareholder_data,
    },
  } = useAppSelector((state) => state.compliance);
  const { company } = useAppSelector((state) => state.auth);
  const agreement_info = company?.agreement_info;

  const agreement_complete = agreement_info?.signature?.complete;
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const external_link_company = query.get("company_id");
    const external_link_token = query.get("token");

    if (external_link_token) {
      storerage.storeSessionToken(external_link_token);
    }
    if (external_link_company) {
      storerage.storeSessionCompany(external_link_company);
    }
  }, []); //eslint-disable-line

  usePageTitle(PageDictionary.page_title);

  const clickHandler = () => {
    navigate(routes.protected.onboarding.shareholders.add, {
      state: { background: location },
    });
  };

  const navigateHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { business_document, bussiness_profile, shareholders } =
      routes.protected.onboarding;
    switch (true) {
      case e.currentTarget.href.includes(business_document.index):
        const values = Object.values(business_documents_data);
        if (values.find((doc) => doc.data._id !== "")) break;
        dispatch(listBusinessDocuments());

        break;
      case e.currentTarget.href.includes(bussiness_profile):
        if (business_profile_data._id === "") {
          dispatch(retriveBusinessProfile());
        }
        break;
      case e.currentTarget.href.includes(shareholders.index):
        if (shareholder_data.length === 0) {
          dispatch(listShareholders({ page_size: 100, page: 1 }));
        }
        break;

      default:
        break;
    }
  };

  return (
    <section className="flex h-screen flex-col overflow-y-hidden pr-22 pl-16 pb-10 [&>div]:px-10">
      <header className="flex items-center justify-between bg-white bg-line bg-[center_left] bg-no-repeat py-10">
        <span className="row-start-1 row-end-2 flex h-9 items-center justify-center text-black md:block">
          <AppLogo hoverAnimate />
        </span>
        <LogoutButton className="md:p-0 [&>sppan]:p-0" />
      </header>
      <div className="pb-4">
        <h1 className="select-none text-2826 font-semibold">
          {PageDictionary.title}
        </h1>
        <p className="w-2/3 pt-4 font-medium text-gray-470">
          {PageDictionary.copy}
        </p>
        <nav className="flex items-center justify-between  border-b border-gray-580">
          <HorizontalNav
            handleClick={navigateHandler}
            navlinks={onboardNavLinks.filter((itm) =>
              !agreement_complete || external_link_company_id
                ? itm.name === "Terms"
                : itm.name !== "Terms"
            )}
            containerClassName="py-2.5"
          />
          {location.pathname ===
            routes.protected.onboarding.shareholders.index &&
          shareholder_loading_state === false ? (
            <CustomButton
              disabled={shareholder_loading_state}
              onClick={clickHandler}
              className=" px-2.5 py-3 text-base leading-7"
            >
              {PageDictionary.add_shareholder}
            </CustomButton>
          ) : null}
        </nav>
      </div>
      <Outlet />
    </section>
  );
}

export default Layout;
