import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IsettingsSlice } from "../types/module";
import { getActivityLog, updatePassword, updateUserProfile } from "./thunk";
import { logUserOut } from "../../auth/store/thunk";

const initialState: IsettingsSlice = {
  loading: false,
  error: null,
  activity_log: [],
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(logUserOut.fulfilled, (state) => {
        state.activity_log = { ...initialState.activity_log };
      })
      .addCase(getActivityLog.fulfilled, (state, { payload }) => {
        state.activity_log = payload;
      })
      .addMatcher(
        isAnyOf(
          updatePassword.pending,
          getActivityLog.pending,
          updateUserProfile.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          updatePassword.fulfilled,
          updatePassword.rejected,
          getActivityLog.fulfilled,
          getActivityLog.rejected,
          updateUserProfile.fulfilled,
          updateUserProfile.rejected
        ),
        (state) => {
          state.loading = initialState.loading;
        }
      );
  },
});

// export const {} = settingSlice.actions;

export default settingSlice.reducer;
