import React from "react";
import { AppLogo } from "../../../static/images/icons";
import { mergeClassName } from "../../utils/helpers";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: any;
  isloading?: boolean;
}

const CustomButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ variant, disabled, children, className, isloading, ...props }, ref) => {
    return (
      <>
        <button
          ref={ref}
          {...props}
          disabled={disabled || isloading}
          className={mergeClassName(
            "h-14 cursor-pointer rounded-2xl py-2 px-12 font-semibold disabled:cursor-not-allowed disabled:bg-slate-200 ",
            variant
              ? "border border-solid border-[#182FFF] bg-white text-[#182FFF]"
              : "bg-[#182FFF] text-white",
            isloading ? "flex items-center gap-x-2" : "",
            className
          )}
        >
          {isloading && (
            <span>
              <AppLogo animate hideName scale={0.5} />
            </span>
          )}
          {children}
        </button>
      </>
    );
  }
);

CustomButton.displayName = "CustomButton";

export default CustomButton;
