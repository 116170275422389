import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
// import { useCancelOnUnmount, useEffectOnce } from "../../../common/hooks";
import * as CreateApplication from "./CreateApplication";
import * as Policy from "./Policy";
import ShowApiScreen from "./ShowApiScreen";
import * as Transaction from "./Transaction";
import * as Include from "./Include";

function ApplicationModal() {
  // const cancelPendingRequests = useCancelOnUnmount();
  // useEffectOnce(() => {
  //   return () => {
  //     cancelPendingRequests();
  //   };
  // });
  return (
    <Routes>
      <Route
        path={routes.protected.applications.create_new_app.create}
        element={<CreateApplication.CreateApplicationForm />}
      />
      <Route
        path={routes.protected.applications.create_new_app.update.index}
        element={<CreateApplication.CreateApplicationForm />}
      />
      <Route
        path={
          routes.protected.applications.app.transactions.transaction_information
            .index
        }
        element={<Transaction.TransactionInformation />}
      />
      <Route
        path={
          routes.protected.applications.app.transactions.report_transaction
            .index
        }
        element={<Transaction.ReportTransaction />}
      />

      {/* Users */}
      <Route
        path={routes.protected.applications.app.users.block_user.index}
        element={<Policy.BlockUser />}
      />

      {/* policy */}
      <Route
        path={
          routes.protected.applications.app.risk_control.policy.create_policy
            .index
        }
        element={<Policy.CreatePolicy />}
      />
      <Route
        path={
          routes.protected.applications.app.risk_control.policy.update_policy
            .index
        }
        element={<Policy.CreatePolicy />}
      />
      <Route
        path={
          routes.protected.applications.app.risk_control.policy.view_policy
            .index
        }
        element={<Policy.PolicyRules />}
      />
      <Route
        path={
          routes.protected.applications.app.risk_control.categories
            .create_category.index
        }
        element={<Policy.CreateCategory />}
      />
      <Route
        path={
          routes.protected.applications.app.risk_control.categories
            .update_category.index
        }
        element={<Policy.CreateCategory />}
      />
      <Route
        path={
          routes.protected.applications.app.risk_control.categories.block_user
            .index
        }
        element={<Policy.BlockUser />}
      />
      <Route
        path={
          routes.protected.applications.app.risk_control.risks
            .notification_config.add.index
        }
        element={<Policy.CreateNotification />}
      />

      {/* Api setting confirmation */}
      <Route
        path={
          routes.protected.applications.app.api_settings.shown_api_setting.index
        }
        element={<ShowApiScreen />}
      />
      <Route
        path={
          routes.protected.applications.app.api_settings.regen_api_keys.index
        }
        element={<ShowApiScreen />}
      />
      {/* <Route
        path={
          routes.protected.applications.app.api_settings.download_api_keys.index
        }
        element={<ShowApiScreen />}
      /> */}

      {/* include */}
      <Route
        path={routes.protected.applications.app.include.toggle_include.on.index}
        element={<Include.TurnOnInclude />}
      />
      <Route
        path={
          routes.protected.applications.app.include.toggle_include.off.index
        }
        element={<Include.TurnOffInclude />}
      />
      <Route
        path={
          routes.protected.applications.app.include.template.view_template.index
        }
        element={<Include.ViewTemplate />}
      />
    </Routes>
  );
}

export default ApplicationModal;
