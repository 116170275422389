import React from "react";
import { IconType } from "./types";

export function ArrowBack({ color = "#000000", scale = 1 }: IconType) {
  return (
    <svg
      width={scale * 32}
      height={scale * 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>back</title>
      <path
        d="M15.9997 26.6666L5.33301 15.9999L15.9997 5.33325L17.8997 7.19992L10.433 14.6666H26.6663V17.3333H10.433L17.8997 24.7999L15.9997 26.6666Z"
        fill={color}
      />
    </svg>
  );
}
