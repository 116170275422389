import { DropEvent, FileRejection } from "react-dropzone";
import { TiDelete } from "react-icons/ti";
import Dropzone, {
  Iaccept,
} from "../../../../common/components/CustomDragandDrop";
import { mergeClassName } from "../../../../common/utils/helpers";

type Props = {
  title?: string;
  images: any[];
  onSubmit?: () => void;
  onRemoveMedia: (id: string) => void;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  supportFormatText?: string;
  accept: Iaccept;
  name: string;
  disabled?: boolean;
  required?: boolean;
  hasserror?: boolean;
  className?: {
    container?: string;
    title?: string;
  };
  onError?: (err: Error) => void;
};

function MediaUpload({
  onDrop,
  images,
  onSubmit,
  onRemoveMedia,
  title,
  supportFormatText,
  accept,
  name,
  disabled,
  required,
  hasserror,
  className,
  onError,
}: Props) {
  return (
    <>
      <div
        className={mergeClassName(
          "shadow-storyImageUpload flex-1",
          className?.container
        )}
      >
        {title ? (
          <p
            className={mergeClassName(
              "mb-4.5 pl-0.5 text-base font-medium capitalize text-base-20",
              className?.title
            )}
          >
            {title}
          </p>
        ) : null}
        <div
          className={mergeClassName(
            "overflow-clip rounded-xl border",
            hasserror ? "border-red-10" : "border-transparent"
          )}
        >
          <Dropzone
            onDrop={onDrop}
            accept={accept}
            supportFormatText={supportFormatText}
            name={name}
            disabled={disabled}
            required={required}
            onError={onError}
          />
        </div>
        {images.length !== 0 && (
          <div className="pt-5">
            {/* <h5 className="text-gray-250 text-sm font-bold leading-none">
            Uploading - {`${images.length}/${images.length}`} files
          </h5> */}

            <div className="flex flex-col gap-y-2.5 pt-3">
              <h5 className="text-start text-sm font-bold leading-none text-gray-400">
                Uploaded
              </h5>

              {images.map((image) => (
                <div
                  key={image.id}
                  className="before:bg-purple-450 relative flex items-center justify-between rounded border border-green-10 p-2.5 before:absolute before:bottom-0 before:left-0 before:h-1 before:w-1/2"
                >
                  <span className="text-xs leading-none">{image.name}</span>
                  <button
                    type="button"
                    onClick={() => onRemoveMedia(image.id)}
                    className="text-base text-gray-650 hover:text-red-300"
                  >
                    <TiDelete size={16} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* <button
        disabled={images.length === 0}
        onClick={onSubmit}
        type="button"
        className="mt-11 rounded-md bg-blue-100 px-18 py-3 text-base text-white disabled:bg-slate-400"
      >
        Publish Story
      </button> */}
    </>
  );
}

export default MediaUpload;
