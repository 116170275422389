import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigationType,
  NavigationType,
} from "react-router-dom";

const useHistory = () => {
  const [routeHistory, setRouteHistory] = useState<string[]>([]);
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    setRouteHistory((prevHistory) => {
      const currentPath = location.pathname;

      if (navigationType === NavigationType.Replace) {
        return prevHistory.filter((route) => route !== currentPath);
      }
      return prevHistory
        .filter((route) => route !== currentPath)
        .concat(currentPath);
    });
  }, [location, navigationType]);

  return { routeHistory };
};

export default useHistory;
