import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import CreateMerchantForm from "./CreateMerchantForm";
import BuyPlan from "./BuyPlan";

function WhitelabelModal() {
  return (
    <Routes>
      <Route
        element={<CreateMerchantForm />}
        path={routes.protected.white_label.modals.create_merchant}
      />
      <Route
        element={<BuyPlan />}
        path={routes.protected.white_label.modals.purchase_plan}
      />
    </Routes>
  );
}

export default WhitelabelModal;
