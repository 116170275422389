import { Route, Routes } from "react-router-dom";
import UpdatePassword from "./UpdatePassword";
import ToggleTwoFactor from "./ToggleTwoFactor";
import LogOut from "./LogOut";
import TwoFactorAuthPrompt from "./TwoFactorAuthPrompt";
import routes from "../../../common/routes";

function SettingsModal() {
  return (
    <Routes>
      <Route
        path={routes.protected.settings.two_factor_confirm_password_update}
        element={<UpdatePassword />}
      />
      <Route
        path={routes.protected.settings.two_factor_toggle}
        element={<ToggleTwoFactor />}
      />
      <Route
        path={routes.protected.settings.two_factor_prompt}
        element={<TwoFactorAuthPrompt />}
      />
      <Route path={routes.protected.settings.logout} element={<LogOut />} />
    </Routes>
  );
}

export default SettingsModal;
