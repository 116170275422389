import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../common/hooks";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../common/components/ModalTitleHeader";
import CustomButton from "../../../../common/components/CustomButton";

import {
  FormatNumber,
  addCommas,
  removeCommas,
  removeNonNumeric,
  toCurrency,
} from "../../../../common/utils/helpers";
import { iHandleChange, iHandleSubmit } from "../../types";
import Language from "../../../../common/utils/language/en";
import { withdrawUtilityWallet } from "../../store/thunk";

const { WithdrawlForm: PageDictionary } =
  Language.protected.walletModals.withdrawal;

const initialstate = {
  amount: "",
};

function WithdrawalForm() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const applocation = useLocation().state;
  const location = applocation?.background;
  const prev_amount = applocation?.amount;
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();

  const {
    exchange_rate: { data: exchange_rate },
    utility_balance: { loading },
  } = useAppSelector((state) => state.utility);

  const inData_amount = removeCommas(inData.amount);

  const rate = exchange_rate?.withdraw_rate?.rate
    ? FormatNumber(
        parseInt(inData_amount ? inData_amount : "0") *
          exchange_rate?.withdraw_rate?.rate
      )
    : "0.00";

  useEffectOnce(() => {
    if (prev_amount) {
      setInData((prev) => ({ ...prev, amount: prev_amount }));
    }
  });

  const changeHandler: iHandleChange = (e) => {
    const { name, value } = e.target;
    setInData((prev) => ({
      ...prev,
      [name]: addCommas(removeNonNumeric(value)),
    }));
  };

  const backdropHandler = () => {
    if (loading) return;
    navigate(location.pathname);
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!appid || loading) return;

    dispatch(withdrawUtilityWallet({ appid, data: { amount: inData_amount } }))
      .unwrap()
      .finally(() => backdropHandler());
  };

  return (
    <SideModal
      onclickBackDrop={backdropHandler}
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <ModalTitleHeader
        title={PageDictionary.title}
        subtitle={PageDictionary.utility_subtitle}
      />
      <form className="flex flex-col gap-y-10 pt-11" onSubmit={submitHandler}>
        <div>
          <label
            className="mb-2 inline-block text-base font-medium text-base-20"
            htmlFor="balance"
          >
            <span className="">{PageDictionary.form.amount_label}</span>
          </label>
          <div className="relative">
            <span className="absolute left-5 top-1/2 -translate-y-1/2 text-2xl font-medium text-gray-450">
              {toCurrency(0).split("0.00")}
            </span>
            <input
              type="text"
              id="balance"
              name="amount"
              className="h-[3.75rem] w-full rounded-xl border border-blue-50 bg-inputbg pt-3 pb-3 pl-11 text-black placeholder:text-base placeholder:text-gray-600"
              value={inData.amount}
              onChange={changeHandler}
            />
            <p className="absolute right-5 text-sm">
              {PageDictionary.form.amount_local_label}
              <span className="uppercase text-green-10">{`${exchange_rate?.withdraw_rate?.to} ${rate}`}</span>
            </p>
          </div>
        </div>

        <CustomButton
          disabled={inData.amount === ""}
          isloading={loading}
          className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        >
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default WithdrawalForm;
