import React from "react";
import { IconType } from "./types";

export function CopyIcon({ color = "#000", scale = 1 }: IconType) {
  return (
    <svg
      width={scale * 23}
      height={scale * 27}
      viewBox="0 0 23 27"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-[#7D818D]"
      fontSize="inherit"
    >
      <path
        d="M19.4049 3.37427H6.4668C6.27617 3.37427 6.09336 3.46316 5.95856 3.6214C5.82377 3.77963 5.74805 3.99424 5.74805 4.21802C5.74805 4.44179 5.82377 4.65641 5.95856 4.81464C6.09336 4.97287 6.27617 5.06177 6.4668 5.06177H18.6862V19.4056C18.6862 19.6294 18.7619 19.844 18.8967 20.0022C19.0315 20.1605 19.2143 20.2494 19.4049 20.2494C19.5955 20.2494 19.7784 20.1605 19.9131 20.0022C20.0479 19.844 20.1237 19.6294 20.1237 19.4056V4.21802C20.1237 3.99424 20.0479 3.77963 19.9131 3.6214C19.7784 3.46317 19.5955 3.37427 19.4049 3.37427Z"
        fill="inherit"
      />
      <path
        d="M16.5318 6.74927H3.59375C3.1968 6.74927 2.875 7.12703 2.875 7.59302V22.7805C2.875 23.2465 3.1968 23.6243 3.59375 23.6243H16.5318C16.9287 23.6243 17.2505 23.2465 17.2505 22.7805V7.59302C17.2505 7.12703 16.9287 6.74927 16.5318 6.74927Z"
        fill="inherit"
      />
    </svg>
  );
}
