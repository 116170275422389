import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import * as thunk from "./thunk";
import * as tp from "../types";
import { logUserOut } from "../../auth/store/thunk";
import { featureParser } from "./helper";

const initialState: tp.Iapplication = {
  error: null,
  allApps: {
    loading: false,
    data: [],
  },
  selected_transaction: {
    data: null,
    loading: false,
  },
  app_users: {
    loading: false,
    data: [],
  },
  transactions: {
    incoming_trans: {
      data: [],
      loading: false,
    },
    outgoing_trans: {
      data: [],
      loading: false,
    },
  },
  app_policies: {
    data: [],
    loading: false,
  },
  app_policies_user_category: {
    data: [],
    loading: false,
  },
  policy: {
    // query_params: "",
    policy_name: "",
    notification: {
      data: [],
      loading: false,
    },
    risk_policies: {
      data: [],
      loading: false,
    },
  },
  api_info: {
    loading: false,
    data: {
      // api_keys: {
      //   public: "",
      //   secret: "",
      // },
      webhooks: {
        settlement: null,
        transaction: null,
      },
      url: "",
    },
  },
};

const applicationSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    selectTransaction: (state, { payload }: PayloadAction<any>) => {
      if (payload === null) return;
      state.selected_transaction.data = payload;
    },
    removeTransaction: (state) => {
      state.selected_transaction = initialState.selected_transaction;
    },
    addPolicyName: (state, { payload }: PayloadAction<string>) => {
      state.policy.policy_name = payload;
    },
    // updateQueryParams: (state, { payload }: PayloadAction<string>) => {
    //   state.policy.query_params = payload;
    // },
    resetAppUsers: (state) => {
      state.app_users = initialState.app_users;
    },
    resetTransactions: (
      state,
      { payload }: PayloadAction<tp.IappTransaction>
    ) => {
      if (payload === "CR") {
        state.transactions.incoming_trans =
          initialState.transactions.incoming_trans;
      }
      if (payload === "DR") {
        state.transactions.outgoing_trans =
          initialState.transactions.outgoing_trans;
      }
    },
    resetPolicyPageData: (state) => {
      state.app_policies = initialState.app_policies;
      state.app_policies_user_category =
        initialState.app_policies_user_category;
    },
    resetPolicyNotificationAndRiskPageData: (
      state,
      { payload }: PayloadAction<"notification" | "risk_policies">
    ) => {
      switch (payload) {
        case "notification":
          state.policy[payload] = initialState.policy[payload];
          break;
        default:
          state.policy[payload] = initialState.policy[payload];
          break;
      }
      state.policy.policy_name = initialState.policy.policy_name;
      // state.policy.query_params = initialState.policy.query_params;
    },
    resetApiInfo: (state) => {
      state.api_info = { ...initialState.api_info };
    },
    // updatePage: (state, action: PayloadAction<tp.IupdatePage>) => {
    //   const { page, type } = action.payload;
    //   // switch (type) {
    //   //   case "notification":
    //   //   case "risk_policies":
    //   //     state.policy[type].pagination.current_page = page;
    //   //     break;
    //   //   default:
    //   //     // state[type].pagination.current_page = page;
    //   //     break;
    //   // }
    // },
    enableNoticationConfigEdit: (
      state,
      { payload }: PayloadAction<tp.InotificationConfig>
    ) => {
      state.policy.notification.data = state.policy.notification.data.map(
        (itm) =>
          itm._id === payload.nid && itm.policy === payload.pid
            ? { ...itm, is_disabled: false }
            : itm
      );
    },
    editNoticationConfig: (
      state,
      { payload }: PayloadAction<tp.IeditNotificationConfig>
    ) => {
      state.policy.notification.data = state.policy.notification.data.map(
        (itm) =>
          itm._id === payload.nid && itm.policy === payload.pid
            ? {
                ...itm,
                [payload.type]: payload.value,
                is_error: payload.is_error,
              }
            : itm.is_all_rules_applied
            ? { ...itm, [payload.type]: payload.value }
            : itm
      );
    },
    applyNotificationRuleToAll: (
      state,
      { payload }: PayloadAction<tp.InotificationConfigApplyToAll>
    ) => {
      state.policy.notification.data = state.policy.notification.data.map(
        (itm) => {
          if (itm._id === payload.notification_id) {
            return { ...itm, apply_this_rule: payload.apply_this_rule };
          }

          return {
            ...itm,
            notification_method: payload.apply_this_rule
              ? payload.data.notification_method
              : itm.tempInfo?.notification_method,
            schedule: payload.apply_this_rule
              ? payload.data.schedule
              : itm.tempInfo?.schedule,
            notify_to: payload.apply_this_rule
              ? payload.data.notify_to
              : itm.tempInfo?.notify_to,
            is_all_rules_applied: payload.apply_this_rule,
            is_disabled: true,
          };
        }
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logUserOut.fulfilled, (state) => {
        const allKeys = Object.keys(state) as Array<keyof typeof state>;
        allKeys.forEach((key) => {
          state[key] = initialState[key];
        });
        // state.allApps = initialState.allApps;
        // state.api_info = initialState.api_info;
        // state.app_policies = initialState.app_policies;
        // state.app_policies_user_category =
        //   initialState.app_policies_user_category;
        // state.app_users = initialState.app_users;
        // state.error = initialState.error;
        // state.policy = initialState.policy;
        // state.transactions = initialState.transactions;
      })
      .addCase(thunk.getAllApps.fulfilled, (state, { payload }) => {
        if (!payload.results) return;
        const temp = payload.data.map((app) => ({
          ...app,
          name: app.name === "Default App" ? "Finswich Default App" : app.name,
          app_features: featureParser(app.app_features),
          channels:
            typeof app.channels === "string"
              ? typeof JSON.parse(app.channels) === "string"
                ? JSON.parse(JSON.parse(app.channels))
                : JSON.parse(app.channels)
              : [],
          parent_company: app.parent_company ? app.parent_company : "",
          industry: app.industry ? app.industry : "",
          description: app.description ? app.description : "",
          application_url: app.application_url ? app.application_url : "",
          user_base: app.user_base ? app.user_base : "",
          logo_url: app.logo_url ? app.logo_url : "",
          parent_company_logo_url: app.parent_company_logo_url
            ? app.parent_company_logo_url
            : "",
          app_color_theme: {
            text: app.app_color_theme.text
              ? app.app_color_theme.text
              : "#6F6F6F",
            background: app.app_color_theme.background
              ? app.app_color_theme.background
              : "#FFFFFF",
            header: app.app_color_theme.header
              ? app.app_color_theme.header
              : "#0037A1",
          },
          // app_color_theme: {
          //   text: app.app_color_theme.text
          //     ? app.app_color_theme.text
          //     : "#FFFFFF",
          //   background: app.app_color_theme.background
          //     ? app.app_color_theme.background
          //     : "#232D51",
          // },
        }));
        state.allApps.data = [...temp];
      })
      .addCase(thunk.addNewApp.fulfilled, (state, { payload }) => {
        const temp = {
          ...payload,
          app_features: featureParser(payload.app_features),
          channels:
            typeof payload.channels === "string"
              ? typeof JSON.parse(payload.channels) === "string"
                ? JSON.parse(JSON.parse(payload.channels))
                : JSON.parse(payload.channels)
              : [],
        };
        state.allApps.data = [temp, ...state.allApps.data];
      })
      .addCase(thunk.updateExistingApp.fulfilled, (state, { payload }) => {
        const temp = state.allApps.data.map((itm) => {
          if (itm._id === payload._id) {
            return {
              ...payload,
              app_features: featureParser(payload.app_features),
              channels:
                typeof payload.channels === "string"
                  ? typeof JSON.parse(payload.channels) === "string"
                    ? JSON.parse(JSON.parse(payload.channels))
                    : JSON.parse(payload.channels)
                  : [],
            };
          }

          return itm;
        });
        state.allApps.data = [...temp];
      })
      .addCase(thunk.addCustomDomainForApp.fulfilled, (state, { payload }) => {
        const temp = state.allApps.data.map((itm) => {
          if (itm._id === payload._id) {
            return {
              ...payload,
              app_features: featureParser(payload.app_features),
              channels:
                typeof payload.channels === "string"
                  ? typeof JSON.parse(payload.channels) === "string"
                    ? JSON.parse(JSON.parse(payload.channels))
                    : JSON.parse(payload.channels)
                  : [],
            };
          }

          return itm;
        });
        state.allApps.data = [...temp];
      })
      .addCase(thunk.verifyDomainForApp.fulfilled, (state, action) => {
        const updatedApps = state.allApps.data.map((app) => {
          if (app._id === action.payload.appid) {
            return action.payload.app;
          }
          return app;
        });

        state.allApps.data = updatedApps;
      })

      .addCase(thunk.removeDomainForApp.fulfilled, (state, action) => {
        const updatedApps = state.allApps.data.map((app) => {
          if (app._id === action.payload.appid) {
            return action.payload.app;
          }
          return app;
        });

        state.allApps.data = updatedApps;
      })

      .addCase(thunk.listAppUsers.fulfilled, (state, action) => {
        state.app_users.data = action.payload.data;
      })
      .addCase(thunk.updateAppUserStatus.fulfilled, (state, action) => {
        const temp = state.app_users.data.map((itm) =>
          itm._id === action.payload._id ? { ...action.payload } : itm
        );
        state.app_users.data = [...temp];
      })
      .addCase(thunk.listTransactions.fulfilled, (state, { meta, payload }) => {
        const { data } = payload;
        const res_type =
          meta.arg.type === "CR" ? "incoming_trans" : "outgoing_trans";
        state.transactions[res_type].data = [...data];
      })
      .addCase(thunk.listPolicies.fulfilled, (state, { payload }) => {
        state.app_policies.data = [...payload];
      })
      .addCase(thunk.listUserCategories.fulfilled, (state, action) => {
        state.app_policies_user_category.data = [...action.payload];
      })
      .addCase(thunk.createPolicy.fulfilled, (state, { payload }) => {
        state.app_policies.data.push(payload);
      })
      .addCase(thunk.updatePolicy.fulfilled, (state, { payload }) => {
        const policies = state.app_policies.data.map((policy) =>
          policy._id === payload._id ? { ...payload } : policy
        );
        const categories = state.app_policies_user_category.data.map(
          (category) => {
            if (category?.credit_policy?._id === payload._id) {
              return { ...category, credit_policy: payload };
            }
            if (category?.debit_policy?._id === payload._id) {
              return { ...category, debit_policy: payload };
            }

            return category;
          }
        );
        state.app_policies.data = [...policies];
        state.app_policies_user_category.data = [...categories];
      })
      .addCase(thunk.createUserCategory.fulfilled, (state, { payload }) => {
        state.app_policies_user_category.data.push(payload);
      })
      .addCase(thunk.updateUserCategory.fulfilled, (state, { payload }) => {
        const temp_categoreies = state.app_policies_user_category.data.map(
          (category) =>
            category._id === payload._id ? { ...payload } : category
        );
        state.app_policies_user_category.data = [...temp_categoreies];
      })
      .addCase(thunk.listRiskRecords.fulfilled, (state, { payload }) => {
        state.policy.risk_policies.data = [...payload];
      })
      .addCase(thunk.addNotificationConfig.fulfilled, (state, { payload }) => {
        state.policy.notification.data = [
          {
            ...payload,
            apply_this_rule: false,
            is_all_rules_applied: false,
            is_disabled: true,
            is_loading: false,
            is_error: false,
            tempInfo: {
              notification_method: payload.notification_method,
              schedule: payload.schedule,
              notify_to: payload.notify_to,
            },
          },
          ...state.policy.notification.data,
        ];
      })
      .addCase(thunk.listNotificationConfig.fulfilled, (state, { payload }) => {
        const temp = payload.map((itm) => ({
          ...itm,
          apply_this_rule: false,
          is_all_rules_applied: false,
          is_disabled: true,
          is_loading: false,
          is_error: false,
          tempInfo: {
            notification_method: itm.notification_method,
            schedule: itm.schedule,
            notify_to: itm.notify_to,
          },
        }));
        state.policy.notification.data = [...temp];
      })
      .addCase(
        thunk.updateNotificationConfig.fulfilled,
        (state, { payload }) => {
          const temp = state.policy.notification.data.map((itm) =>
            itm._id === payload._id
              ? {
                  ...payload,
                  apply_this_rule: false,
                  is_all_rules_applied: false,
                  is_disabled: true,
                  is_loading: false,
                  is_error: false,
                  tempInfo: {
                    notification_method: itm.notification_method,
                    schedule: itm.schedule,
                    notify_to: itm.notify_to,
                  },
                }
              : itm
          );
          state.policy.notification.data = [...temp];
        }
      )
      .addCase(thunk.updateNotificationConfig.rejected, (state, { meta }) => {
        const temp = state.policy.notification.data.map((itm) =>
          itm._id === meta.arg.nid
            ? {
                ...itm,
                apply_this_rule: false,
                is_all_rules_applied: false,
                is_disabled: true,
                is_loading: false,
                notification_method: itm.tempInfo?.notification_method,
                schedule: itm.tempInfo?.schedule,
                notify_to: itm.tempInfo?.notify_to,
              }
            : itm
        );
        state.policy.notification.data = [...temp];
      })
      .addCase(thunk.includeSwitchOn.fulfilled, (state, { payload, meta }) => {
        const temp = state.allApps.data.map((itm) => {
          if (itm._id === meta.arg.id) {
            return {
              ...itm,
              include_is_active: true,
              include_auth_url: payload.auth_url,
              include_callback: payload.callback,
              include_secret: payload.secret_key,
            };
          }

          return itm;
        });
        state.allApps.data = [...temp];
      })
      .addCase(thunk.includeSwitchOff.fulfilled, (state, { meta }) => {
        const temp = state.allApps.data.map((itm) => {
          if (itm._id === meta.arg.id) {
            return {
              ...itm,
              include_is_active: false,
            };
          }

          return itm;
        });
        state.allApps.data = [...temp];
      })

      .addMatcher(
        isAnyOf(
          thunk.getAppTransaction.fulfilled,
          thunk.getTransaction.fulfilled
        ),
        (state, { payload }) => {
          state.selected_transaction.data = payload;
        }
      )
      .addMatcher(
        isAnyOf(thunk.getAppTransaction.pending, thunk.getTransaction.pending),
        (state) => {
          state.selected_transaction.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.getAppTransaction.fulfilled,
          thunk.getTransaction.fulfilled,
          thunk.getAppTransaction.rejected,
          thunk.getTransaction.rejected
        ),
        (state) => {
          state.selected_transaction.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(thunk.listTransactions.pending),
        (state, { meta }) => {
          const type =
            meta.arg.type === "CR" ? "incoming_trans" : "outgoing_trans";
          state.transactions[type].loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.listTransactions.fulfilled,
          thunk.listTransactions.rejected
        ),
        (state, { meta }) => {
          const type =
            meta.arg.type === "CR" ? "incoming_trans" : "outgoing_trans";
          state.transactions[type].loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.listPolicies.pending,
          thunk.createPolicy.pending,
          thunk.updatePolicy.pending
        ),
        (state) => {
          state.app_policies.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.listUserCategories.pending,
          thunk.createUserCategory.pending,
          thunk.updateUserCategory.pending
        ),
        (state) => {
          state.app_policies_user_category.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.listPolicies.fulfilled,
          thunk.listPolicies.rejected,
          thunk.createPolicy.fulfilled,
          thunk.createPolicy.rejected,
          thunk.updatePolicy.fulfilled,
          thunk.updatePolicy.rejected
        ),
        (state) => {
          state.app_policies.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.listUserCategories.fulfilled,
          thunk.listUserCategories.rejected,
          thunk.createUserCategory.fulfilled,
          thunk.createUserCategory.rejected,
          thunk.updateUserCategory.fulfilled,
          thunk.updateUserCategory.rejected
        ),
        (state) => {
          state.app_policies_user_category.loading = false;
        }
      )
      .addMatcher(isAnyOf(thunk.listRiskRecords.pending), (state) => {
        state.policy.risk_policies.loading = true;
      })
      .addMatcher(
        isAnyOf(
          thunk.listRiskRecords.fulfilled,
          thunk.listRiskRecords.rejected
        ),
        (state) => {
          state.policy.risk_policies.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.addNotificationConfig.pending,
          thunk.listNotificationConfig.pending,
          thunk.retrieveNotificationConfig.pending
        ),
        (state) => {
          state.policy.notification.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.addNotificationConfig.fulfilled,
          thunk.addNotificationConfig.rejected,
          thunk.listNotificationConfig.fulfilled,
          thunk.listNotificationConfig.rejected,
          thunk.retrieveNotificationConfig.fulfilled,
          thunk.retrieveNotificationConfig.rejected
        ),
        (state) => {
          state.policy.notification.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(thunk.updateNotificationConfig.pending),
        (state, { meta }) => {
          state.policy.notification.data = state.policy.notification.data.map(
            (itm) =>
              itm._id === meta.arg.nid && itm.policy === meta.arg.pid
                ? { ...itm, is_loading: true }
                : itm
          );
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.updateNotificationConfig.fulfilled,
          thunk.updateNotificationConfig.rejected
        ),
        (state, { meta }) => {
          state.policy.notification.data = state.policy.notification.data.map(
            (itm) =>
              itm._id === meta.arg.nid && itm.policy === meta.arg.pid
                ? { ...itm, is_loading: false, is_disabled: true }
                : itm
          );
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.retrieveApiKey.fulfilled
          // thunk.regenerateApiKey.fulfilled
        ),
        (state, action: PayloadAction<any>) => {
          state.api_info.data.url = action.payload.url;
          state.api_info.data.webhooks.settlement =
            action.payload.webhooks.settlement;
          state.api_info.data.webhooks.transaction =
            action.payload.webhooks.transaction;
        }
      )
      .addMatcher(
        isAnyOf(thunk.retrieveApiKey.pending, thunk.regenerateApiKey.pending),
        (state) => {
          state.api_info.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.retrieveApiKey.fulfilled,
          thunk.retrieveApiKey.rejected,
          thunk.regenerateApiKey.fulfilled,
          thunk.regenerateApiKey.rejected
        ),
        (state) => {
          state.api_info.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.listAppUsers.pending,
          thunk.retriveAppUser.pending,
          thunk.updateAppUserStatus.pending
        ),
        (state) => {
          state.app_users.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.listAppUsers.fulfilled,
          thunk.retriveAppUser.fulfilled,
          thunk.updateAppUserStatus.fulfilled,
          thunk.listAppUsers.rejected,
          thunk.retriveAppUser.rejected,
          thunk.updateAppUserStatus.rejected
        ),
        (state) => {
          state.app_users.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.getAllApps.pending,
          thunk.addNewApp.pending,
          thunk.updateExistingApp.pending,
          thunk.createReportTransaction.pending,
          thunk.includeSwitchOff.pending,
          thunk.includeSwitchOn.pending
        ),
        (state) => {
          state.allApps.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          thunk.getAllApps.rejected,
          thunk.getAllApps.fulfilled,
          thunk.addNewApp.rejected,
          thunk.addNewApp.fulfilled,
          thunk.updateExistingApp.rejected,
          thunk.updateExistingApp.fulfilled,
          thunk.createReportTransaction.rejected,
          thunk.createReportTransaction.fulfilled,
          thunk.includeSwitchOn.rejected,
          thunk.includeSwitchOn.fulfilled,
          thunk.includeSwitchOff.rejected,
          thunk.includeSwitchOff.fulfilled
        ),
        (state) => {
          state.allApps.loading = false;
        }
      );
  },
});

export const appActions = applicationSlice.actions;

export default applicationSlice.reducer;
