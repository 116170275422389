import React from "react";
import { mergeClassName } from "../../utils/helpers";
import CustomButton from "../CustomButton";

type ClassNames = "container" | "texts" | "headline" | "sub" | "img" | "action";

type Props = {
  img?: string;
  classNames?: Partial<Record<ClassNames, string>>;
  headline?: string;
  sub?: string;
  actionText?: string;
  action?: () => void;
};

export default function CustomBanner({
  classNames,
  headline,
  img,
  sub,
  action,
  actionText,
}: Props) {
  return (
    <section
      className={mergeClassName(
        "flex h-auto max-h-48 items-center justify-between overflow-hidden rounded-2xl",
        classNames?.container
      )}
    >
      <div
        className={mergeClassName(
          "flex w-full max-w-lg flex-col items-start gap-y-2 py-8 px-14",
          classNames?.texts
        )}
      >
        {headline && (
          <h2
            className={mergeClassName(
              "text-3236 font-bold leading-9",
              classNames?.headline
            )}
          >
            {headline}
          </h2>
        )}
        {sub && (
          <p className={mergeClassName("text-xs", classNames?.sub)}>{sub}</p>
        )}
        {actionText && (
          <CustomButton
            onClick={action}
            className={mergeClassName(classNames?.action)}
          >
            {actionText}
          </CustomButton>
        )}
      </div>
      {img && (
        <img
          src={img}
          alt="banner"
          className={mergeClassName(classNames?.img)}
        />
      )}
    </section>
  );
}
