import { IheaderBtn } from "./types";

export const header_btn: IheaderBtn[] = [
  { name: "incoming", label: "Incoming Tx" },
  { name: "outgoing", label: "Outgoing Tx" },
];

export const PageDictionary = {
  options: {
    optionLabel_first: { value: "Daily", key: "day" },
    optionLabel_second: { value: "This Week", key: "week" },
    optionLabel_third: { value: "This Month", key: "month" },
    optionLabel_fourth: { value: "All Time", key: "year" },
  },
  datasetLabel_one: "Outgoing Tx",
  datasetLabel_two: "Incoming Tx",
};

export const ioptions = {
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: true,
  plugins: {
    title: {
      display: false,
      text: "Chart.js Line Chart - Multi Axis",
    },
    tooltip: {
      backgroundColor: "#FFF",
      titleFontColor: "#0066FF",
      titleFontSize: 12,
      titleFontStyle: "normal",
      titleMarginBottom: 15,
      bodyFontColor: "#000",
      bodyFontSize: 36,
      bodyFontStyle: "bold",
      footerFontColor: "#000",
      footerFontSize: 14,
      footerFontStyle: "bold",
      footerMarginTop: 15,
      titleColor: "#71717A",
      bodyColor: "#18181B",

      //   xAlign: "center",
      //   yAlign: "bottom",
    },
  },
  layout: {
    padding: 24,
  },
  maintainAspectRatio: false,
  backgroundColor: "#000000",
  elements: {
    point: {
      backgroundColor: "red",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
      grid: {
        drawTicks: false,
      },
      ticks: {
        display: false,
        padding: 0,
      },
      border: {
        display: false,
      },
    },
    y1: {
      type: "linear" as const,
      display: false,
      position: "right" as const,
      //   grid: {
      //     drawOnChartArea: false,
      //   },
    },
  },
};

export const initialstate = {
  incoming: true,
  outgoing: true,
};
