import React from "react";
import { Route } from "react-router-dom";
import Auth from "../components/Layout/Auth";
import Protected from "../components/Layout/Protected";
import Public from "../components/Layout/Public";
import SuspsenseFallBack from "../components/SuspsenseFallBack";
import { GenerateLayout } from "./generateLayout";
import { Iroute } from "../types";

export const generateRoute = ({ path, Component, access, layout }: Iroute) => {
  return (
    <Route
      key={`${path}`}
      element={
        access === "guest-only" ? (
          <Auth />
        ) : access === "loggedin-user" && layout !== "onboarding" ? (
          <Protected />
        ) : (
          <Public />
        )
      }
    >
      <Route element={<GenerateLayout layout={layout} />}>
        <Route
          path={path}
          element={
            <React.Suspense fallback={<SuspsenseFallBack />}>
              <Component />
            </React.Suspense>
          }
        />
      </Route>
    </Route>
  );
};
