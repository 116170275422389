import React from "react";
import { IconType } from "./types";

export function TransactionArrow({ color = "#000", scale = 1 }: IconType) {
  return (
    <svg
      width={scale * 10}
      height={scale * 10}
      viewBox="0 0 10 10"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.92315 7.17954C1.92315 7.39195 2.09535 7.56415 2.30777 7.56415L5.76931 7.56415C5.98172 7.56415 6.15392 7.39195 6.15392 7.17954C6.15392 6.96712 5.98172 6.79492 5.76931 6.79492H2.69238V3.718C2.69238 3.50558 2.52018 3.33338 2.30777 3.33338C2.09535 3.33338 1.92315 3.50558 1.92315 3.718L1.92315 7.17954ZM7.42042 1.52296L2.0358 6.90757L2.57973 7.4515L7.96435 2.06689L7.42042 1.52296Z"
        fill="inherit"
      />
    </svg>
  );
}
