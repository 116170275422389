import { useCopyToClipboard } from "../../../../../../common/hooks";
import { ParseFormattedNumber } from "../../../../../../common/utils/helpers";
import { IoCheckmarkDoneSharp, IoCopySharp } from "react-icons/io5";

type Props = {
  currency: string;
  amount: string;
};

function AmountToPay({ currency, amount }: Props) {
  const { copyToClipboard, isCopied } = useCopyToClipboard();

  const clickHandler = () => {
    copyToClipboard(ParseFormattedNumber(amount).toString());
  };

  return (
    <div className="flex flex-col gap-y-0.5">
      <h6 className="text-xs text-[#525869]">Amount to Pay</h6>
      <div className="flex items-center">
        <p className="text-2xl font-bold leading-7 text-[#0B112F]">
          {currency + " " + amount}
        </p>
        <button onClick={clickHandler} className="text-[#7D818D]">
          {isCopied ? (
            <IoCheckmarkDoneSharp size={16} />
          ) : (
            <IoCopySharp size={16} />
          )}
        </button>
      </div>
    </div>
  );
}

export default AmountToPay;
