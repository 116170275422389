import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import * as tp from "../types";
import { IWalletTransThunk } from "../../utility/types";

export const getWallet = createAsyncThunk(
  "wallet/getWallet",
  async (appid: string, { fulfillWithValue, rejectWithValue }) => {
    return service
      .getWallet(appid)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const fundWallet = createAsyncThunk(
  "wallet/fundWallet",
  async (req: IWalletTransThunk, { fulfillWithValue, rejectWithValue }) => {
    return service
      .fundWallet(req.appid, req.data)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const fundWalletPolling = createAsyncThunk(
  "wallet/fundWalletPolling",
  async (appid: string, { fulfillWithValue, rejectWithValue }) => {
    return service
      .fundWalletPolling(appid)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const uploadPaymentEvidence = createAsyncThunk(
  "wallet/uploadPaymentEvidence",
  async (req: tp.IuploadEvidence, { rejectWithValue, fulfillWithValue }) => {
    return service
      .uploadPaymentEvidence(req)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const listAppTransactions = createAsyncThunk(
  "wallet/listAppTransactions",
  async (
    req: tp.IwithdrawalTransactionThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .listAppTransactions(req.appid, req.transaction_type)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const getExchangeRate = createAsyncThunk(
  "wallet/getExchangeRate",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getExchangeRate(id)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const addThreshold = createAsyncThunk(
  "wallet/withdrawalThreshold/add",
  async (req: tp.IthresholdThunk, { rejectWithValue, fulfillWithValue }) => {
    return service
      .addWithdrawalThreshold(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const listThresholds = createAsyncThunk(
  "wallet/withdrawalThreshold/list",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .listWithdrawalThresholds(id)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const updateThreshold = createAsyncThunk(
  "wallet/withdrawalThreshold/update",
  async (
    req: tp.IupdatethresholdThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .updateWithdrawalThreshold(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const deleteThreshold = createAsyncThunk(
  "wallet/withdrawalThreshold/delete",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .deleteWithdrawalThreshold(id, "")
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const listBankAccountTypes = createAsyncThunk(
  "wallet/withdrawal/listBankAccountTypes",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .listBankAccountTypes(id)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const listDeactivatedAccount = createAsyncThunk(
  "wallet/withdrawal/listDeactivatedAccount",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .listDeactivatedAccount(id)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        // customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const addBankAccount = createAsyncThunk(
  "wallet/withdrawal/addBankAccount",
  async (
    req: tp.IaddBankAccountThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { appid, data } = req;
    return service
      .addBankAccount(appid, data)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const getBankAccount = createAsyncThunk(
  "wallet/withdrawal/getBankAccount",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getBankAccount(id)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const updateBankAccount = createAsyncThunk(
  "wallet/withdrawal/updateBankAccount",
  async (
    req: tp.IupdateBankThunk,
    { rejectWithValue, fulfillWithValue /* getState, dispatch */ }
  ) => {
    const { appid, bankid, data } = req;
    return service
      .updateBankAccount(appid, bankid, data)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const bankWithdrawal = createAsyncThunk(
  "wallet/withdrawal/bankWithdrawal",
  async (
    req: tp.IbankWithdrawalThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { appid, data } = req;
    return service
      .bankWithdrawal(appid, data)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const addCryptoAddress = createAsyncThunk(
  "wallet/withdrawal/addCryptoAddress",
  async (
    req: tp.IcryptoWithdrawalThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { appid, data } = req;
    if (!data) return;
    return service
      .addCryptoAddress(appid, data)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const getCryptoAddress = createAsyncThunk(
  "wallet/withdrawal/getCryptoAddress",
  async (
    req: tp.IcryptoWithdrawalThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { appid } = req;
    return service
      .getCryptoAddress(appid)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);

export const deactivateCryptoAddress = createAsyncThunk(
  "wallet/withdrawal/deactivateCryptoAddress",
  async (
    req: tp.IcryptoWithdrawalThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { appid, wid } = req;
    if (!wid) return;
    return service
      .deactivateCryptoAddress(appid, wid)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
