import { useLocation } from "react-router-dom";
import AmountToPay from "./AmountToPay";

function OtherForms() {
  const { state } = useLocation();
  const amount = state?.amount as string;
  const currency = state?.currency as string;

  return (
    <div className="flex flex-col gap-y-9 pt-11">
      <AmountToPay amount={amount} currency={currency} />

      <ol className="list-inside list-decimal space-y-4 text-[#525869]">
        <li className="text-inherit">
          <span className="text-sm">Click on the “Payment Link” below.</span>
          <div className="space-y-3.5 rounded border border-dashed border-[#384EB74D] bg-[#F8F8FF] px-4 pt-2 pb-6 ">
            <p className="text-sm">
              Once you click “Proceed to Pay”, you will receive a USSD prompt on
              07045274781, Approve Payment.
            </p>
            <button className="rounded-md bg-[#0A59F3] py-2.5 px-5 text-xs text-white">
              Proceed to Pay
            </button>
          </div>
        </li>

        {/* <li className="space-y-2.5 text-inherit">
          <span className="text-sm">
            Approve Payment from the account of “Allowed users” with active KYC
            below.
            <span className="block italic text-red-10">
              Third Party Funding is not allowed.
            </span>
          </span>
          <div className="flex flex-col gap-y-5 rounded border border-dashed border-[#384EB74D] bg-[#F8F8FF] py-3 px-4 ">
            <table className="w-full text-left">
              <thead className="text-sm text-gray-410">
                <tr>
                  <th>Account Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="text-base text-base-20">
                <tr>
                  <td>Charles Avis A</td>
                  <td>
                    <div className="w-fit rounded bg-[#12C089] py-1.5 px-4 text-white">
                      Active KYC
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Teslim Ayantola</td>
                  <td>
                    <div className="w-fit rounded bg-[#12C089] py-1.5 px-4 text-white">
                      Active KYC
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-sm text-[#525869]">
            Want to Add another “Allowed user”?{" "}
            <span className="font-bold">Start KYC here</span>
          </p>
        </li> */}
      </ol>
    </div>
  );
}

export default OtherForms;
