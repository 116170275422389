import React from "react";
import { AppLogo } from "../../../static/images/icons";

function SuspsenseFallBack() {
  return (
    <div role="status" className="flex h-full items-center justify-center">
      <span>
        <AppLogo animate hideName scale={3} />
      </span>
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default SuspsenseFallBack;
