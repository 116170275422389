import React from "react";
import { toCurrency } from "../../../utils/helpers";
import { FormatNumber } from "../../../utils/helpers";

type Props = {
  amount: string;
  currency_type?: "dollars";
  local?: string;
};

function AmountData({ currency_type, amount, local }: Props) {
  let currency = "";
  if (currency_type === "dollars") {
    currency = FormatNumber(amount, true);
  }
  if (local) {
    currency = toCurrency(parseInt(amount ? amount : "0"), "en-ng", local);
  }
  return <span>{currency}</span>;
}

export default AmountData;
