export const BASE_URL = process.env.REACT_APP_FINSWICH;
export const SOCKET_URL = process.env.REACT_APP_SOCKET;
export const PREVIEW_URL = process.env.REACT_APP_PREVIEW;
export const TERMS_URL = process.env.REACT_APP_TERMS;

export const REQUEST_TIMEOUT = 60000;
export const PAGE_SIZE = 10;

export const STORAGE_KEYS = {
  CLIENT_TOKEN_STORAGE_KEY: "finswich.token",
  CLIENT_TOKEN_REFRESH_KEY: "finswich.refresh_token",
  CLIENT_LOCAL_TOKEN_EXPIRED: "exp",
  CLIENT_TOKEN_SESSION_KEY: "finswich.session.token",
  CLIENT_SESSION_DATA_KEY: "finswich.external.id",
  CLIENT_LOCAL_2FA_PROMPT: "finswich.session.2fa",
  CLIENT_LOCAL_SHOW_GO_LIVE: "finswich.session.golive",
};
