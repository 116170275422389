import React from "react";
import SideNav from "../SideNav";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Menu from "../Menu";

function AppLayout() {
  return (
    <div className="bg-slate-50">
      <Header />
      <div className="relative flex h-screen bg-white design_screen:mx-auto  design_screen:max-w-screen-design_screen">
        <SideNav className="absolute top-0 left-0 bottom-0 bg-white" />
        <div className="absolute top-0 right-0 bottom-0 left-16 h-full flex-1 pt-8 pl-8 md:left-72">
          <Menu />
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AppLayout;
