import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../common/hooks";
import SideModal from "../../../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../../../common/components/ModalTitleHeader";
import CustomInput from "../../../../../../common/components/CustomInput";
import CustomButton from "../../../../../../common/components/CustomButton";
import ModalNavLinks from "../../../../components/ModalNavLinkFunding";
// import { walletActions } from "../../../../store/slice";
import { FormatNumber } from "../../../../../../common/utils/helpers";
import { iHandleChange } from "../../../../types";
import {
  addCommas,
  removeCommas,
  removeNonNumeric,
} from "../../../../../../common/utils/helpers";
import routes from "../../../../../../common/routes";
import Language from "../../../../../../common/utils/language/en";
import { fundWallet } from "../../../../store/thunk";

const { FundWalletForm: PageDictionary } =
  Language.protected.walletModals.funding;

const initialstate = {
  message: "",
  type: "funding_amount",
  shareholder_id: "",
};

function FundWalletForm() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation().state?.background;
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { data: shareholders } = useAppSelector(
    (state) => state.compliance.shareholders
  );
  // const { isEstablishingConnection, isConnected } = useAppSelector(
  //   (state) => state.wallet.socket
  // );
  const is_bank = useAppSelector(
    (state) => state.app.allApps.data.find((app) => app._id === appid)?.is_bank
  );

  const exchange_rate = useAppSelector(
    (state) => state.wallet.exchange_rate.data
  );

  const usdValue = exchange_rate?.fund_rate?.from
    ? parseInt(
        removeCommas(inData.message) ? removeCommas(inData.message) : "0"
      ) / exchange_rate?.fund_rate?.rate
    : 0;

  const rate = FormatNumber(usdValue, true);

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value } = e.target;
    setInData((prev) => ({
      ...prev,
      message: addCommas(removeNonNumeric(value)),
    }));
  }, []);
  const radioChangeHandler: iHandleChange = (e) => {
    const { value } = e.target;
    setInData((prev) => ({
      ...prev,
      shareholder_id: value,
    }));
  };

  const backDrop = () => {
    if (!appid || loading) return;
    let path = routes.protected.applications.app.wallet.link(appid);
    navigate(path);
  };

  // start socket for wallet funding
  useEffect(() => {
    if (!appid) return;
    // let path = "";

    // if (isConnected === true) {
    //   path =
    //     routes.protected.applications.app.wallet.fund.payment_processing.link(
    //       appid
    //     );
    //   dispatch(
    //     walletActions.submitMessage({
    //       content: { ...inData, message: removeCommas(inData.message) },
    //     })
    //   );
    //   navigate(path, {
    //     state: { background: location, amount: inData.message },
    //   });
    // }
    if (!exchange_rate?.fund_rate.from) {
      backDrop();
    }
  }, []); // eslint-disable-line

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!appid) return;
    const amount = removeCommas(inData.message);
    setLoading(true);
    dispatch(fundWallet({ appid, data: { amount } }))
      .unwrap()
      .then(() => {
        const path =
          routes.protected.applications.app.wallet.fund.payment_processing.link(
            appid,
            1
          );
        navigate(path, {
          state: {
            background: location,
            amount: inData.message,
            currency: exchange_rate?.fund_rate?.from,
          },
        });
      });

    // dispatch(
    //   walletActions.startConnecting({ app_id: appid, txn_reference: null })
    // );
  };

  return (
    <SideModal
      onclickBackDrop={backDrop}
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <ModalTitleHeader
        title={PageDictionary.header.title}
        subtitle={PageDictionary.header.subtitle}
      />
      <form className="flex flex-col gap-y-10 pt-11" onSubmit={submitHandler}>
        <ModalNavLinks is_bank={is_bank} />
        <div className="relative">
          <span className="absolute bottom-5 left-4 z-[2] uppercase">
            {exchange_rate?.fund_rate?.from}
          </span>
          <CustomInput
            label={PageDictionary.form.amount_label}
            className="pl-[4.25rem]"
            value={inData.message}
            onChange={changeHandler}
          />
          <p className="absolute right-5">
            {PageDictionary.form.amount_dollar}{" "}
            <span className="text-red-10">{rate}</span>
          </p>
        </div>

        <p className="text-sm text-gray-400">{PageDictionary.form.warn_text}</p>

        <ul className="list-inside list-decimal">
          <li className="space-y-2.5 text-inherit">
            <span className="text-sm">
              Transfer money from the account of “Allowed users” with active KYC
              below.{" "}
              <span className="block italic text-red-10">
                Third Party Funding is not allowed.
              </span>
            </span>
            <div className="flex flex-col gap-y-5 rounded border border-dashed border-[#384EB74D] bg-[#F8F8FF] py-3 px-4 ">
              <table className="w-full text-left">
                <thead className="text-sm text-gray-410">
                  <tr>
                    <th>Account Name</th>
                    <th>Status</th>
                    <th className="text-center">Select</th>
                  </tr>
                </thead>
                <tbody className="text-base text-base-20">
                  {shareholders.map((shareholder) => (
                    <tr key={shareholder._id}>
                      <td className="capitalize">{shareholder.name}</td>
                      <td>
                        <div className="w-fit rounded bg-[#12C089] py-1.5 px-4 text-white">
                          Active KYC
                        </div>
                      </td>
                      <td>
                        <CustomInput
                          type="radio"
                          name="shareholder_id"
                          value={shareholder._id}
                          onChange={radioChangeHandler}
                          disabled={loading}
                          checked={inData.shareholder_id === shareholder._id}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="text-sm text-[#525869]">
              Want to Add another “Allowed user”?{" "}
              <Link
                className="font-bold"
                to={routes.protected.compliance.shareholders.link(
                  "fund-wallet"
                )}
              >
                Start KYC here
              </Link>
            </p>
          </li>
        </ul>

        <CustomButton
          disabled={
            inData.message === "" ||
            inData.shareholder_id === "" ||
            usdValue < 10
          }
          // isloading={isEstablishingConnection}
          isloading={loading}
          className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        >
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default FundWalletForm;
