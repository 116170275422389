import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import {
  APIResponseModel,
  ChartResponse,
  StatBoardData,
  StatBoardQuery,
} from "../types";

const service = {
  async getDashboardInfo(): Promise<APIResponseModel<StatBoardData>> {
    const { method, url } = ENDPOINTS.dashboard.get_summary;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getDashboardChart(
    query: StatBoardQuery
  ): Promise<APIResponseModel<ChartResponse>> {
    const { method, url: makeUrl } = ENDPOINTS.dashboard.graph_data;
    const url = makeUrl(query);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
