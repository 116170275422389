import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { UploadIcon } from "../../../static/images/icons";

export interface Iaccept {
  [key: string]: string[];
}

type Props = {
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  accept: Iaccept;
  open?: () => void;
  supportFormatText?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  onError?: (err: Error) => void;
};

function Dropzone({
  onDrop,
  accept,
  open,
  supportFormatText,
  name,
  disabled,
  required,
  onError,
}: Props) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    disabled,
    onError,
  });

  return (
    <div className="">
      <div
        {...getRootProps({
          className: "p-6 pb-12 bg-purple-10 rounded",
        })}
      >
        <input
          className="input-zone"
          {...getInputProps({ name, required })}
          data-testid={`cy-${name}`}
        />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <div>
              <span className="flex items-center justify-center fill-purple-20 stroke-purple-20">
                <UploadIcon />
              </span>
              <div className="mt-7 flex flex-col gap-y-2.5">
                <div className="flex items-center justify-center gap-x-1 text-sm font-bold">
                  <p className="text-geay-10">Drag & drop files or </p>
                  <button
                    type="button"
                    onClick={open}
                    className="border-b border-purple-20 leading-none text-purple-20"
                  >
                    Browse
                  </button>
                </div>
              </div>
              {supportFormatText ? (
                <p className="mt-2.5 text-xs leading-4.5 text-gray-420">
                  {supportFormatText}
                </p>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dropzone;
