import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";
import {
  useAppDispatch,
  useCancelOnUnmount,
  useEffectOnce,
} from "../../../../common/hooks";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import CustomInput from "../../../../common/components/CustomInput";
import * as Cstm from "../../../../common/components/CustomSelect";
import CustomButton from "../../../../common/components/CustomButton";
import routes from "../../../../common/routes";
import { validationRules } from "../../../../common/utils/helpers";
import { getCountries } from "../../../auth/store/thunk";
import { iHandleChange, iHandleSubmit } from "../../types";
import Language from "../../../../common/utils/language/en";
import { saveAgreementInfo } from "../../store/thunk";

const { agreement_infromation: PageDictionary } =
  Language.protected.onboardingModals;

const initialstate = {
  company_name: "",
  country: "",
  address: "",
  authorizer_fullname: "",
  authorizer_designation: "",
};

type Countrystate = {
  data: any[];
  loading: boolean;
};

const countrystate = {
  data: [],
  loading: false,
};

function AgreementInformation() {
  const navigate = useNavigate();
  const location = useLocation();
  const background = location.state?.background;
  const pathname = background?.pathname;
  const [inData, setInData] = useState(initialstate);
  const [inError, setInError] = useState<Record<string, boolean>>({});
  const [countryList, setCountryList] = useState<Countrystate>(countrystate);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const cancelPendingRequests = useCancelOnUnmount();

  useEffectOnce(() => {
    setCountryList((prev) => ({ ...prev, loading: true }));
    dispatch(getCountries())
      .unwrap()
      .then((res) => setCountryList((prev) => ({ ...prev, data: res })))
      .finally(() => setCountryList((prev) => ({ ...prev, loading: false })));

    return () => {
      cancelPendingRequests();
    };
  });

  const disable_btn =
    Object.values(inError).includes(true) || Object.values(inData).includes("");

  const changeHandler: iHandleChange = (e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));
    const rules = e.target.getAttribute("data-rules");

    if (!rules) return;
    const regex = new RegExp(rules.substring(1, rules.length - 1));
    setInError((prev) => ({
      ...prev,
      [name]: regex.test(value) ? false : true,
    }));
  };
  const selectHandler = (value: string) => {
    setInData((prev) => ({ ...prev, country: value }));
  };

  const closeModal = () => {
    navigate(pathname, { replace: true });
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(saveAgreementInfo(inData))
      .unwrap()
      .then(() => {
        navigate(routes.protected.onboarding.terms.authorize, {
          state: { background },
        });
      })
      .catch(closeModal)
      .finally(() => setLoading(false));
  };

  return (
    <SideModal
      onclickBackDrop={closeModal}
      className="p-8 md:py-9 md:pl-14 md:pr-22"
    >
      <form onSubmit={submitHandler} className="flex flex-col">
        <div className="">
          <h3 className="text-2226 font-bold">
            {PageDictionary.agreement_title}
          </h3>
          <p className="mt-3 text-base font-medium leading-7">
            {PageDictionary.agreement_copy}
          </p>
        </div>
        <div className="flex flex-col gap-y-3.5 pt-7 pb-4.5">
          <CustomInput
            labelClassName="text-xs"
            label={PageDictionary.formLabels.company_name}
            name="company_name"
            onChange={changeHandler}
            value={inData.company_name}
            rules={validationRules.string}
            haserror={inError?.company_name}
            disabled={loading}
          />
          <Cstm.Select
            label={PageDictionary.formLabels.select_country}
            onChange={selectHandler}
            disabled={loading}
            loading={countryList.loading}
            value={inData.country}
          >
            {countryList?.data?.map((itm) => (
              <Cstm.SelectItem key={itm.country} value={itm.country}>
                <span>{itm.country}</span>
              </Cstm.SelectItem>
            ))}
          </Cstm.Select>
          <CustomInput
            labelClassName="text-xs"
            label={PageDictionary.formLabels.address}
            onChange={changeHandler}
            value={inData.address}
            name="address"
            rules={validationRules.string}
            haserror={inError?.address}
          />
        </div>
        <div className="flex flex-col gap-y-4">
          <h3 className="inline-flex items-center gap-x-3.5 text-2226 font-bold">
            {PageDictionary.authorizer_title}{" "}
            <span className="inline-flex items-center gap-x-2 text-xs font-normal text-red-10">
              <FiAlertCircle /> {PageDictionary.required}
            </span>
          </h3>
          <p className="text-xs leading-5 text-red-10">
            {PageDictionary.authorizer_copy}
          </p>
        </div>

        <div className="flex flex-col gap-y-4.5 pt-5 pb-7">
          <CustomInput
            labelClassName="text-xs"
            label={PageDictionary.formLabels.authorizer_name}
            onChange={changeHandler}
            value={inData.authorizer_fullname}
            name="authorizer_fullname"
            rules={validationRules.string}
            haserror={inError?.authorizer_fullname}
          />
          <CustomInput
            labelClassName="text-xs"
            label={PageDictionary.formLabels.authorizer_designation}
            onChange={changeHandler}
            value={inData.authorizer_designation}
            name="authorizer_designation"
            rules={validationRules.string}
            haserror={inError?.authorizer_designation}
          />
        </div>
        <CustomButton
          disabled={disable_btn}
          isloading={loading}
          className="mt-2.5 self-start px-[3.25rem] py-3.5 text-base leading-7"
        >
          {PageDictionary.formLabels.button}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default AgreementInformation;
