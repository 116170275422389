import React, { useEffect, useState } from "react";
import Language from "../../../../common/utils/language/en";
import { Select, SelectItem } from "../../../../common/components/CustomSelect";
import {
  useAppDispatch,
  useAppSelector,
  useCancelOnUnmount,
  useEffectOnce,
} from "../../../../common/hooks";
import { getCountries } from "../../../auth/store/thunk";
import { useParams } from "react-router-dom";
import { getUtilityHistory, listUtilityCategory } from "../../store/thunk";
import { paginationActions } from "../../../../store/helperReducers/paginationSlice";

const { header: PageDictionary } = Language.protected.Layout.utility_layout;

type ifilterType = "countries" | "categories" | "sub_categories";

const initialstate = {
  countries: [],
  categories: [],
  sub_categories: [],
};
const filterstate = {
  countries: "",
  categories: "",
  sub_categories: "",
};

function QueryFilter() {
  const { appid } = useParams();
  const [filter, setFilter] =
    useState<Record<ifilterType, string>>(filterstate);
  const [params, setParams] =
    useState<Record<ifilterType, any[]>>(initialstate);
  const dispatch = useAppDispatch();

  const { filter: filterParams, pagesize } = useAppSelector(
    (state) => state.pagination
  );

  const { category, country, subcategory } = filterParams;
  const filterParamsData = Object.values(filterParams);

  const cancelPendingRequests = useCancelOnUnmount();

  useEffectOnce(() => {
    if (!appid) return;
    dispatch(listUtilityCategory({ appid }))
      .unwrap()
      .then((res) => {
        setParams((prev) => ({
          ...prev,
          categories: [...res],
        }));
      });
    dispatch(getCountries())
      .unwrap()
      .then((res) =>
        setParams((prev) => ({
          ...prev,
          countries: [/* { country: "" }, */ ...res],
        }))
      );
    return () => {
      cancelPendingRequests();
    };
  });

  useEffect(() => {
    if (!appid) return;
    if (filterParamsData.find((param) => param !== "")) {
      dispatch(
        getUtilityHistory({
          appid,
          size: pagesize,
          page: 1,
          cat: category,
          country: country,
          sub_cat: subcategory,
        })
      );
    }
  }, [JSON.stringify(filterParams)]); //eslint-disable-line

  const filterHandler = (itm: any, datatype: ifilterType) => {
    let value = "";
    let type: string = "";
    setFilter((prev) => ({
      ...prev,
      [datatype]: datatype === "countries" ? itm.country : itm.name,
    }));

    if (datatype === "categories") {
      setParams((prev) => ({
        ...prev,
        sub_categories: [...itm.sub_categories],
      }));

      if (filter.sub_categories !== "") {
        setFilter((prev) => ({ ...prev, sub_categories: "" }));
      }
    }

    switch (datatype) {
      case "categories":
        value = itm.name;
        type = "category";
        break;
      case "countries":
        value = itm.country;
        type = "country";
        break;
      case "sub_categories":
        value = itm.name;
        type = "subcategory";
        break;

      default:
        break;
    }

    dispatch(paginationActions.addQueryParams({ key: type, value }));
  };

  const resetFilterHandler = (datatype: ifilterType) => {
    filterHandler({ country: "", name: "", sub_categories: [] }, datatype);
  };

  return (
    <div className="flex items-center gap-x-4">
      <span className="text-sm font-medium text-blue-40">
        {PageDictionary.filter_label}
      </span>
      <div className="relative">
        <Select
          label=""
          labelClassName="mb-0 hidden"
          className="min-w-[13.25rem] max-w-[14.625rem]"
          onChange={(itm) => filterHandler(itm, "countries")}
          value={filter.countries}
          placeholder={PageDictionary.filter.country}
        >
          {params.countries
            // ?.filter((itm) => filter.countries !== itm.country)
            ?.map((itm, id) => {
              return (
                <SelectItem key={id} value={itm}>
                  <span>{itm?.country}</span>
                </SelectItem>
              );
            })}
        </Select>
        {filter.countries !== "" ? (
          <button
            onClick={() => resetFilterHandler("countries")}
            type="button"
            className="absolute -bottom-6 right-2 text-xs text-red-10 hover:text-black"
          >
            Reset
          </button>
        ) : null}
      </div>
      <div className="relative">
        <Select
          label=""
          labelClassName="mb-0 hidden"
          className="min-w-[13.25rem] max-w-[14.625rem]"
          onChange={(itm) => filterHandler(itm, "categories")}
          value={filter.categories}
          placeholder={PageDictionary.filter.category}
        >
          {params.categories
            // ?.filter((itm) => filter.countries !== itm.country)
            ?.map((itm, id) => {
              return (
                <SelectItem key={id} value={itm}>
                  <span>{itm?.name}</span>
                </SelectItem>
              );
            })}
        </Select>
        {filter.categories !== "" ? (
          <button
            onClick={() => resetFilterHandler("categories")}
            type="button"
            className="absolute -bottom-6 right-2 text-xs text-red-10 hover:text-black"
          >
            Reset
          </button>
        ) : null}
      </div>
      {params.sub_categories.length > 0 ? (
        <div className="relative">
          <Select
            label=""
            labelClassName="mb-0 hidden"
            className="min-w-[13.25rem] max-w-[14.625rem]"
            onChange={(itm) => filterHandler(itm, "sub_categories")}
            value={filter.sub_categories}
            placeholder={PageDictionary.filter.sub_category}
          >
            {params.sub_categories
              // ?.filter((itm) => filter.countries !== itm.country)
              ?.map((itm, id) => {
                return (
                  <SelectItem key={id} value={itm}>
                    <span>{itm?.name}</span>
                  </SelectItem>
                );
              })}
          </Select>
          {filter.sub_categories !== "" ? (
            <button
              onClick={() => resetFilterHandler("sub_categories")}
              type="button"
              className="absolute -bottom-6 right-2 text-xs text-red-10 hover:text-black"
            >
              Reset
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default QueryFilter;
