import React from "react";
import Language from "../../utils/language/en";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import routes from "../../routes";
import { logUserOut } from "../../../module/auth/store/thunk";
import { IoLogOut } from "react-icons/io5";
import { mergeClassName } from "../../utils/helpers";

const { sidenav: PageDictionary } = Language.protected.Layout;

type Props = {
  className?: string;
};

export default function LogoutButton({ className }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.auth);

  const logoutHandler = () => {
    navigate(routes.protected.settings.logout, {
      replace: true,
      state: { background: location },
    });
    dispatch(logUserOut())
      .unwrap()
      .finally(() => navigate(routes.nonprotected.login, { replace: true }));
    // .catch((err) => navigate(location.pathname, { replace: true }));
  };
  return (
    <button
      disabled={loading}
      onClick={logoutHandler}
      className={mergeClassName(
        "group row-start-5 row-end-6  text-base text-red-10 hover:text-gray-10 md:pl-10 md:pr-8",
        className
      )}
    >
      <span className="flex items-center justify-center gap-x-6 py-3 md:px-6 ">
        <IoLogOut className="h-7 w-7 transition-all duration-300 ease-in-out group-hover:scale-x-[-1] group-hover:transition-all group-hover:duration-300 group-hover:ease-in-out" />
        <span className="hidden md:inline-block">{PageDictionary.logout}</span>
      </span>
    </button>
  );
}
