import React from "react";
import { IconType } from "./types";

export function ArrowDownIcon({ color = "#000000", scale = 1 }: IconType) {
  return (
    <svg
      width={scale * 14}
      height={scale * 16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.39391 14.8918C6.72864 15.2265 7.27136 15.2265 7.60609 14.8918L13.0609 9.43698C13.3956 9.10225 13.3956 8.55953 13.0609 8.2248C12.7262 7.89006 12.1835 7.89006 11.8487 8.2248L7 13.0735L2.15127 8.2248C1.81653 7.89006 1.27382 7.89006 0.939085 8.2248C0.604351 8.55953 0.604351 9.10225 0.939085 9.43698L6.39391 14.8918ZM6.14286 -1.87848e-09L6.14286 14.2857L7.85714 14.2857L7.85714 1.87848e-09L6.14286 -1.87848e-09Z"
        fill={color}
      />
    </svg>
  );
}
