import React from "react";
import { NavLink } from "react-router-dom";
import { FixedProps } from "../types";
import { mergeClassName } from "../../../../utils/helpers";

export function FixedHorizontalNav({
  navlinks,
  activeclassName,
  className,
  containerClassName,
  listitemclassName,
  handleClick,
}: FixedProps) {
  return (
    <ul
      className={mergeClassName(
        "flex flex-grow items-end gap-x-6 overflow-x-auto py-10",
        containerClassName
      )}
    >
      {navlinks.map(({ link, name }) => (
        <li
          key={name}
          className={mergeClassName(
            "select-none whitespace-nowrap text-[1.125rem] leading-6 first:pr-5 first:pl-0 last:pr-0 last:pl-5",
            listitemclassName
          )}
        >
          <NavLink
            to={link}
            onClick={handleClick}
            className={({ isActive }) =>
              mergeClassName(
                "border-b-2 px-2 pb-2",
                isActive
                  ? "border-black text-[1.375rem] font-medium text-black"
                  : "border-transparent text-base font-normal text-gray-360",
                isActive ? activeclassName : className
              )
            }
          >
            {name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
