type AuditReportHistoryBtnProps = {
  audit_report_history: number;
};

export const AuditReportHistoryBtn = ({
  audit_report_history,
}: AuditReportHistoryBtnProps) => {
  return (
    <div className="">
      <button className="flex w-[7.5rem] items-center justify-center gap-x-2 rounded-xl bg-[#9A9F9D] py-2 text-white">
        <span>View</span>
        <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-[#E41D1D] text-xs text-white">
          {audit_report_history}
        </span>
      </button>
    </div>
  );
};
