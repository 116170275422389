import cuid from "cuid";

export const MOCK = {
  utility: {
    mockgetUtilityHistory: {
      success: true,
      message: "Data Fetch Successful",
      results: 13,
      data: [
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: {
            _id: "63cd9278c2665456027f8d9a",
            merchant_app: "63c79453c3660ec3393876d9",
            full_name: "Megatron Griffin",
            model_id: "85564cd860384cc6bbbb0a6766eb2983",
            created_at: "2023-01-22T20:46:00.908754+01:00",
            updated_at: "2023-04-17T07:00:43.888471+01:00",
            deleted_at: null,
            is_deleted: false,
            is_active: true,
            meta_data: {},
            extra_fields: {},
            first_name: "Megatron",
            last_name: "Griffin",
            email: "meg_griffin@gmail.com",
            phone_number: "07069199170",
            user_reference: "poormeggriffin",
            mode: "TEST",
            country: "Nigeria",
            consumer: "63cd4ee275038f93d075b56f",
          },
          utilty_context: {
            operator: "Nigeria Airtel",
            product_category: "Mobile PIN",
            product_type: "Mobile Pin / Voucher",
          },
          model_id: "2b3bf20d468b4d15a9bb7b069e73447b",
          created_at: "2023-06-20T08:54:42+01:00",
          updated_at: "2023-06-20T08:54:58.392404+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "fiat",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: null,
          asset_category: "fiat",
          amount: 200.0,
          usdt_amount: 0.2623,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.11160000000007,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "UTL-hZ4KKPAvGR43kYLL1687247368",
          description: "Charge for utility purchase from meg_griffin@gmail.com",
          bank_account_details: {},
          external_api_initiated: true,
          quarantined: false,
          exchange_rate: 762.5,
          payment_evidence: null,
          currency: "NGN",
          refund_completed: false,
          mode: "TEST",
          merchant_app: "63c79453c3660ec3393876d9",
        },
      ],
    },
    mockgetUtilityRecords: {
      success: true,
      message: "Data Fetch Successful",
      results: 13,
      data: [
        {
          _id: cuid(),
          app_consumer: null,
          utilty_context: {},
          model_id: "cb17e8fb0586429ba674a052b04db464",
          created_at: "2023-06-15T09:25:55+01:00",
          updated_at: "2023-06-15T09:26:06.131263+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "utility",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: "63e4eab27d282d291761a88b",
          asset_category: "fiat",
          amount: 7.0,
          usdt_amount: 7.0,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.636,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "FND-0A35dymh5uCGSAzL1686817552012",
          description: "Utility wallet withdrawal",
          bank_account_details: {},
          external_api_initiated: false,
          quarantined: false,
          exchange_rate: 1.0,
          payment_evidence: null,
          currency: "USD",
          refund_completed: false,
          mode: "LIVE",
          merchant_app: "63c79453c3660ec3393876d9",
        },
        {
          _id: cuid(),
          app_consumer: null,
          utilty_context: {},
          model_id: "cb17e8fb0586429ba674a052b04db464",
          created_at: "2023-06-15T09:25:55+01:00",
          updated_at: "2023-06-15T09:26:06.131263+01:00",
          deleted_at: null,
          is_deleted: false,
          is_active: true,
          extra_fields: {},
          nfc_card: null,
          wallet: "647ed39b8842df4d52116dc3",
          wallet_category: "utility",
          user_id: "63e5dc90763eb40db6da8821",
          role: "user",
          asset: "63e4eab27d282d291761a88b",
          asset_category: "fiat",
          amount: 7.0,
          usdt_amount: 7.0,
          txn_fee: 0.0,
          from_crypto_address: null,
          to_crypto_address: null,
          from_card: null,
          status: "success",
          channel: "app",
          balance: 474.636,
          destination: "wallet",
          source: "utility_wallet",
          type: "DR",
          category: "utility",
          txn_reference: "FND-0A35dymh5uCGSAzL1686817552012",
          description: "Utility wallet withdrawal",
          bank_account_details: {},
          external_api_initiated: false,
          quarantined: false,
          exchange_rate: 1.0,
          payment_evidence: null,
          currency: "USD",
          refund_completed: false,
          mode: "LIVE",
          merchant_app: "63c79453c3660ec3393876d9",
        },
      ],
    },
    mockgetUtility: {
      success: true,
      message: "Data Fetch Successful",
      data: {
        _id: cuid(),
        model_id: "734fe18e4e49477680399eb8218aff07",
        created_at: "2023-01-18T07:40:23.561172+01:00",
        updated_at: "2023-01-24T08:50:02.341265+01:00",
        deleted_at: null,
        is_deleted: false,
        is_active: true,
        meta_data: {},
        user_id: "63c0f51d09e42dd4772f02b4",
        user_email: "stark@gmail.com",
        address: "TNZ2SRvg3h5h1tLYJeNvxv9TXw3fooHYfx",
        crypto_symbol: "USDT",
        blockchain_symbol: "TRX",
        merchant_app_id: "63c79453c3660ec3393876d9",
        balance: Math.floor(Math.random() * 10000),
      },
    },
  },
  setting: {
    mockActivityLog: {
      success: true,
      message: "Log Fetch Successful",
      data: [
        {
          _id: "613b90fc16f11c001f2fc30a",
          email: "johndoe@gmail.com",
          action_performed: "Logged In",
          performed_on: "Finswich",
          created_at: "2022-04-01T08:12:33.500Z",
        },
        {
          _id: "613b910216f11c001f2fc30b",
          email: "janedoe@yahoo.com",
          action_performed: "Registered",
          performed_on: "Finswich",
          created_at: "2022-03-31T16:45:27.500Z",
        },
        {
          _id: "613b910b16f11c001f2fc30c",
          email: "johndoe@gmail.com",
          action_performed: "Posted a Comment",
          performed_on: "Finswich",
          created_at: "2022-03-30T10:27:14.500Z",
        },
        {
          _id: "613b911416f11c001f2fc30d",
          email: "johndoe@gmail.com",
          action_performed: "Made a Purchase",
          performed_on: "Finswich",
          created_at: "2022-03-29T18:02:56.500Z",
        },
        {
          _id: "613b911d16f11c001f2fc30e",
          email: "janedoe@yahoo.com",
          action_performed: "Added Item to Cart",
          performed_on: "Finswich",
          created_at: "2022-03-28T13:15:45.500Z",
        },
        {
          _id: "613b912616f11c001f2fc30f",
          email: "johndoe@gmail.com",
          action_performed: "Updated Profile",
          performed_on: "Finswich",
          created_at: "2022-03-27T11:30:22.500Z",
        },
        {
          _id: "613b912f16f11c001f2fc310",
          email: "janedoe@yahoo.com",
          action_performed: "Changed Password",
          performed_on: "Finswich",
          created_at: "2022-03-26T07:52:09.500Z",
        },
        {
          _id: "613b90fc16f11c001f2fc30a",
          email: "johndoe@gmail.com",
          action_performed: "Logged In",
          performed_on: "Finswich",
          created_at: "2022-04-01T08:12:33.500Z",
        },
        {
          _id: "613b910216f11c001f2fc30b",
          email: "janedoe@yahoo.com",
          action_performed: "Registered",
          performed_on: "Finswich",
          created_at: "2022-03-31T16:45:27.500Z",
        },
        {
          _id: "613b910b16f11c001f2fc30c",
          email: "johndoe@gmail.com",
          action_performed: "Posted a Comment",
          performed_on: "Finswich",
          created_at: "2022-03-30T10:27:14.500Z",
        },
        {
          _id: "613b911416f11c001f2fc30d",
          email: "johndoe@gmail.com",
          action_performed: "Made a Purchase",
          performed_on: "Finswich",
          created_at: "2022-03-29T18:02:56.500Z",
        },
        {
          _id: "613b911d16f11c001f2fc30e",
          email: "janedoe@yahoo.com",
          action_performed: "Added Item to Cart",
          performed_on: "Finswich",
          created_at: "2022-03-28T13:15:45.500Z",
        },
        {
          _id: "613b912616f11c001f2fc30f",
          email: "johndoe@gmail.com",
          action_performed: "Updated Profile",
          performed_on: "Finswich",
          created_at: "2022-03-27T11:30:22.500Z",
        },
        {
          _id: "613b912f16f11c001f2fc310",
          email: "janedoe@yahoo.com",
          action_performed: "Changed Password",
          performed_on: "Finswich",
          created_at: "2022-03-26T07:52:09.500Z",
        },
        {
          _id: "613b90fc16f11c001f2fc30a",
          email: "johndoe@gmail.com",
          action_performed: "Logged In",
          performed_on: "Finswich",
          created_at: "2022-04-01T08:12:33.500Z",
        },
        {
          _id: "613b910216f11c001f2fc30b",
          email: "janedoe@yahoo.com",
          action_performed: "Registered",
          performed_on: "Finswich",
          created_at: "2022-03-31T16:45:27.500Z",
        },
        {
          _id: "613b910b16f11c001f2fc30c",
          email: "johndoe@gmail.com",
          action_performed: "Posted a Comment",
          performed_on: "Finswich",
          created_at: "2022-03-30T10:27:14.500Z",
        },
        {
          _id: "613b911416f11c001f2fc30d",
          email: "johndoe@gmail.com",
          action_performed: "Made a Purchase",
          performed_on: "Finswich",
          created_at: "2022-03-29T18:02:56.500Z",
        },
        {
          _id: "613b911d16f11c001f2fc30e",
          email: "janedoe@yahoo.com",
          action_performed: "Added Item to Cart",
          performed_on: "Finswich",
          created_at: "2022-03-28T13:15:45.500Z",
        },
        {
          _id: "613b912616f11c001f2fc30f",
          email: "johndoe@gmail.com",
          action_performed: "Updated Profile",
          performed_on: "Finswich",
          created_at: "2022-03-27T11:30:22.500Z",
        },
        {
          _id: "613b912f16f11c001f2fc310",
          email: "janedoe@yahoo.com",
          action_performed: "Changed Password",
          performed_on: "Finswich",
          created_at: "2022-03-26T07:52:09.500Z",
        },
      ],
    },
  },
};
