import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import CreateUser from "./CreateUser";
import SuspendUser from "./SuspendUser";
import RescindUserInvite from "./RescindUserInvite";

function UserModal() {
  return (
    <Routes>
      <Route path={routes.protected.user.add_user} element={<CreateUser />} />
      <Route
        path={routes.protected.user.update_user.index}
        element={<CreateUser />}
      />
      <Route
        path={routes.protected.user.suspend_user.index}
        element={<SuspendUser />}
      />
      <Route
        path={routes.protected.user.rescind_invite.index}
        element={<RescindUserInvite />}
      />
    </Routes>
  );
}

export default UserModal;
