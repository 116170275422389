import React from "react";
import Language from "../../../../../common/utils/language/en";

type Props = {
  status: "pending" | "success" | "cancelled" | "rejected";
};

const { txn_status: PageDictionary } =
  Language.protected.walletPages.walletPage.table_component;

function TxnStatus({ status }: Props) {
  let style = "";
  let value = "";

  switch (status) {
    case "pending":
      style = "text-orange-40 capitalize";
      break;
    case "success":
      style = "text-green-70 capitalize";
      break;

    default:
      style = "text-red-10 capitalize";
      break;
  }

  if (Object.keys(PageDictionary).includes(status)) {
    value = PageDictionary[status];
  }

  return <span className={style}>{value}</span>;
}

export default TxnStatus;
