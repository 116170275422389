import React from "react";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import { useForm } from "react-hook-form";
import { MerchantFormType, merchantResolver } from "./schema";
import CustomInput from "../../../../common/components/CustomInput";
import { iHandleChange } from "../../../applications/types";
import CustomButton from "../../../../common/components/CustomButton";

export default function CreateMerchantForm() {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<MerchantFormType>({
    resolver: merchantResolver,
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });

  const disableSubmitBtn = Object.keys(errors).length > 0;

  const changeHandler: iHandleChange = (e) => {
    const { name: tName, value } = e.target;

    const name = tName as keyof MerchantFormType;
    setValue(name, value, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const submitHandler = (data: MerchantFormType) => {
    alert("click on backdrop to close");
    console.log(data);
  };
  return (
    <SideModal className="flex flex-col gap-y-15 p-8 md:pt-15 md:pl-14 md:pr-28 ">
      <header className="flex flex-col gap-y-3">
        <h3 className="text-4xl">Create Merchant</h3>
        <p>Set up a merchant to have access to your app.</p>
      </header>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="flex flex-col gap-y-8"
      >
        <CustomInput
          name="full_name"
          label="Merchant Full name"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.full_name?.message}
          errortext={errors.full_name?.message}
        />
        <CustomInput
          name="email"
          label="Merchant Email"
          type="email"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.email?.message}
          errortext={errors.email?.message}
        />
        <CustomInput
          name="phone_number"
          label="Merchant Phone"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.phone_number?.message}
          errortext={errors.phone_number?.message}
        />
        <CustomInput
          name="password"
          label="Password"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.password?.message}
          errortext={errors.password?.message}
        />
        <CustomButton
          disabled={disableSubmitBtn}
          className="self-start px-6 py-2 text-base leading-7"
        >
          Submit
        </CustomButton>
      </form>
    </SideModal>
  );
}
