import React, { useId, useRef, useState } from "react";
import { CheckIcon } from "../../../static/images/icons";
import { iHandleChangeCheck } from "../../types";
import { mergeClassName } from "../../utils/helpers";

type ClassNames = "label" | "field" | "container";

interface props extends React.InputHTMLAttributes<HTMLInputElement> {
  text_left?: boolean;
  label?: string;
  checked: boolean;
  // sclassName?: string;
  // labelClassName?: string;
  isAlt?: boolean;
  classNames?: Partial<Record<ClassNames, string>>;
}

function CustomCheckBox({
  text_left,
  label,
  checked,
  onChange,
  className,
  classNames,
  value, //eslint-disable-line
  isAlt,
  ...props
}: props) {
  const id = useId();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const inChandleHandler: iHandleChangeCheck = (e) => {
    setIsFocused(false);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div
      className={mergeClassName(
        "flex cursor-pointer items-center gap-x-2",
        text_left ? "flex-row-reverse" : "flex-row",
        className
      )}
    >
      <input
        {...props}
        checked={checked}
        aria-checked={checked}
        onChange={inChandleHandler}
        type="checkbox"
        className="sr-only"
        id={`${id}-checkbox`}
        ref={inputRef}
        onFocus={(e) => setIsFocused(true)}
        onBlur={(e) => setIsFocused(false)}
        data-testid="checkbox"
        value={value}
      />
      <label
        className={mergeClassName(
          "inline-flex cursor-pointer items-center gap-x-2 text-sm font-bold leading-[1.375rem] text-base-20 outline",
          isFocused ? "outline-blue-20" : "outline-transparent",
          classNames?.field && "text-base capitalize leading-none",
          classNames?.label
        )}
        htmlFor={`${id}-checkbox`}
        data-testid="checkbox-label"
      >
        <span
          className={mergeClassName(
            "checkbox flex items-center justify-center bg-gray-380 bg-center p-0.5",
            checked ? "stroke-black" : "stroke-transparent",
            isAlt ? "h-[1.438rem] w-[1.438rem] rounded-md bg-[#D6D6D6]" : "",
            isAlt && checked ? "bg-[#602738] stroke-white" : "",
            classNames?.field
          )}
          data-testid="checkbox-check"
        >
          <CheckIcon scale={1} color={checked ? "black" : "transparent"} />
        </span>
        {label}
      </label>
    </div>
  );
}

export default CustomCheckBox;
