import React from "react";
import { IconType } from "./types";

function InvestmentIcon({ color = "#545454", scale = 1 }: IconType) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      scale={scale}
    >
      <path
        d="M2.75201 20.6254H21.25V14.0764M3.71701 18.8439L11.212 11.3499L13.766 13.9039L21.25 6.41993V11.9479M15.6795 6.41943H21.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.2245 6.20117H3.8405C3.23823 6.20117 2.75 6.68941 2.75 7.29167V10.7897C2.75 11.3919 3.23823 11.8802 3.8405 11.8802H8.2245C8.82677 11.8802 9.315 11.3919 9.315 10.7897V7.29167C9.315 6.68941 8.82677 6.20117 8.2245 6.20117Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.281 8.09424H9.3145M2.75 9.98724H7.7835"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.02797 5.21451C6.53607 5.21451 6.94797 4.80261 6.94797 4.29451C6.94797 3.78641 6.53607 3.37451 6.02797 3.37451C5.51987 3.37451 5.10797 3.78641 5.10797 4.29451C5.10797 4.80261 5.51987 5.21451 6.02797 5.21451Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InvestmentIcon;
