import { useEffect, useRef } from "react";

export function usePageTitle(pageTitle: string) {
  const mountOnce = useRef(false);

  useEffect(() => {
    if (mountOnce.current === true) {
      return;
    }
    document.title = `FinSwich | ${pageTitle}`;
    mountOnce.current = true;
  }, []); //eslint-disable-line
}
