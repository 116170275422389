import React, { useId } from "react";
import CustomToolTip from "../CustomToolTip";
import Language from "../../utils/language/en";
import { mergeClassName } from "../../utils/helpers";

const { input_feild: PageDictionary } = Language.components;

interface props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
  rules?: RegExp;
  haserror?: boolean;
  errortext?: string;
  tooltip?: string;
  cols: number;
  rows: number;
}

function CustomTextArea(prop: props) {
  const {
    label,
    name,
    className,
    containerClassName,
    rules,
    labelClassName,
    haserror,
    errortext,
    tooltip,
    ...props
  } = prop;
  const id = useId();

  return (
    <div className={mergeClassName("form_group flex-1", containerClassName)}>
      {label || tooltip ? (
        <label
          className={mergeClassName(
            "mb-2 text-base font-medium text-base-20",
            tooltip ? "flex items-center gap-x-1" : "inline-block",
            labelClassName
          )}
          htmlFor={`${id}-${name}`}
        >
          {label}
          {tooltip && <CustomToolTip tip={tooltip} />}
        </label>
      ) : null}
      <div className="relative">
        <textarea
          {...props}
          className={mergeClassName(
            "w-full rounded-xl border bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600",
            haserror ? "border-red-10" : "border-blue-50",
            className
          )}
          id={`${id}-${name}`}
          data-rules={rules}
          data-haserror={haserror}
          name={name}
        />
      </div>
      {haserror && (
        <p
          data-testid="error-text"
          className="mt-0.5 text-right text-xs text-red-10"
        >
          {errortext ? errortext : PageDictionary.invalid_value}
        </p>
      )}
    </div>
  );
}

export default CustomTextArea;
