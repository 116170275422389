import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useAppDispatch, useCopyToClipboard } from "../../../../common/hooks";
import CustomInput from "../../../../common/components/CustomInput";
import { CopyIcon } from "../../../../static/images/icons";
import { iHandleChange } from "../../types";
import Language from "../../../../common/utils/language/en";
import {
  mergeClassName,
  validationRules,
} from "../../../../common/utils/helpers";
import { generateSignatureAgreementLink } from "../../store/thunk";
import { TbLoaderQuarter } from "react-icons/tb";

type Props = {
  external_compliance_link: string;
};

const { authorize: PageDictionary } = Language.protected.onboardingModals;

const initialValues = {
  email: "",
  is_sent: false,
  is_loading: false,
  has_error: false,
};

function NoAuthority({ external_compliance_link }: Props) {
  const [values, setValues] = useState(initialValues);
  const { copyToClipboard, isCopied } = useCopyToClipboard();
  const dispatch = useAppDispatch();

  const changeHandler: iHandleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
      is_sent: value === "" || value !== prev.email ? false : prev.is_sent,
    }));

    const rules = e.target.getAttribute("data-rules");

    if (!rules) return;
    const regex = new RegExp(rules.substring(1, rules.length - 1));
    setValues((prev) => ({
      ...prev,
      has_error: regex.test(value) ? false : true,
    }));
  };

  const shareViaEmailHandler = () => {
    // if (values.email === "" || values.is_sent) return;

    setValues((prev) => ({ ...prev, is_loading: true }));
    dispatch(generateSignatureAgreementLink({ email: values.email }))
      .unwrap()
      .then(() => {
        setValues((prev) => ({ ...prev, is_sent: true }));
      })
      .finally(() => {
        setValues((prev) => ({ ...prev, is_loading: false }));
      });
  };

  const copyHandler = () => copyToClipboard(external_compliance_link);

  return (
    <>
      <div className="relative text-sm">
        <input
          type="text"
          placeholder="https://compliance.instance.finance/e-link/?23ujueuueue..."
          className="h-[3.75rem] w-full cursor-pointer select-none rounded-xl border border-blue-50 bg-inputbg pt-2 pb-3 pl-4 pr-28 text-black placeholder:text-base placeholder:text-gray-600 focus:outline-none"
          value={external_compliance_link}
          onClick={copyHandler}
          readOnly
        />
        <button
          type="button"
          onClick={copyHandler}
          className="absolute top-3.5 right-5 flex items-center gap-x-2"
        >
          <span>{isCopied ? "Copied" : "Copy"}</span>
          <CopyIcon />
        </button>
      </div>

      <div className="flex items-center gap-x-2.5">
        <CustomInput
          label={PageDictionary.formLabels.share_email}
          containerClassName="flex items-center  gap-x-2.5 flex-1"
          placeholder={PageDictionary.formLabels.authorize_email_placeholder}
          value={values.email}
          onChange={changeHandler}
          className="flex-1"
          name="email"
          type="email"
          rules={validationRules.email}
          haserror={values.has_error}
        />

        <button
          type="button"
          onClick={shareViaEmailHandler}
          disabled={
            values.is_loading || values.email === "" || values.has_error
          }
          className={mergeClassName(
            "flex h-12 w-12 items-center justify-center rounded-full text-white  disabled:bg-gray-300",
            values.is_sent ? "bg-green-50 text-sm" : "bg-cyan-20 text-2xl"
          )}
        >
          {values.is_sent ? <span>Sent</span> : null}
          {values.is_loading ? (
            <span className="animate-spin text-xl">
              <TbLoaderQuarter />
            </span>
          ) : null}
          {!values.is_sent && !values.is_loading ? <BsArrowRight /> : null}
        </button>
      </div>
    </>
  );
}

export default NoAuthority;
