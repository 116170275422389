import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import * as appThunk from "../../module/applications/store/thunk";
import { listUsers } from "../../module/user/store/thunk";
import * as utiThunk from "../../module/utility/store/thunk";

type Ifilter = Record<string, string>;
type Ipagination = {
  total_count: number;
  current_page: number;
  pagesize: number;
  page_count: number;
  filter: Ifilter;
};

const initialState: Ipagination = {
  total_count: 0,
  current_page: 1,
  pagesize: 10,
  page_count: 1,
  filter: {
    search: "",
    country: "",
    category: "",
    subcategory: "",
  },
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    resetData: (state) => {
      const keys = Object.keys(state) as Array<keyof typeof state>;
      keys.forEach((key) => {
        switch (key) {
          case "filter":
            state[key] = { ...initialState[key] };
            break;

          default:
            state[key] = initialState[key];
            break;
        }
      });
    },
    updatePageNumber: (state, { payload }: PayloadAction<number>) => {
      state.current_page = payload;
    },
    addQueryParams: (
      state,
      { payload }: PayloadAction<{ key: keyof Ifilter; value: string }>
    ) => {
      const { key, value } = payload;
      state.filter[key] = value;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        appThunk.listAppUsers.fulfilled,
        appThunk.listTransactions.fulfilled,
        appThunk.getAllApps.fulfilled,
        listUsers.fulfilled,
        utiThunk.getUtilityRecords.fulfilled,
        utiThunk.getUtilityHistory.fulfilled
      ),
      (state, action) => {
        if (action.payload.results === undefined) return;
        state.page_count = Math.ceil(action.payload.results / state.pagesize);
        state.total_count = action.payload.results;
      }
    );
  },
});

export const paginationActions = paginationSlice.actions;

export default paginationSlice.reducer;
