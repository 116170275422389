import { ENDPOINTS } from "../../../common/service/config/endpoint";
import * as rq from "../../../common/service/request";
import * as tp from "../types";

const service = {
  async saveAgreementInfo(
    data: tp.IagreementInfo
  ): Promise<tp.APIResponseModel<{}>> {
    const { method, url } =
      ENDPOINTS.compliance.agreement_info.save_agreement_info;
    return rq.makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async generateSignatureRef(
    data: Record<string, any>
  ): Promise<tp.APIResponseModel<tp.IsignatureRef>> {
    const { method, url } =
      ENDPOINTS.compliance.agreement_info.generate_signature_ref;
    return rq.makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async completeAgreement(
    data: tp.IslaRecipientEmail
  ): Promise<tp.APIResponseModel<{}>> {
    const { method, url } =
      ENDPOINTS.compliance.agreement_info.complete_agreement;
    return rq.makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async generateSignatureAgreementLink(
    data: tp.IgenerateLink
  ): Promise<tp.APIResponseModel<tp.IagreementLink>> {
    const { method, url } =
      ENDPOINTS.compliance.agreement_info.generate_signature_agreement_link;
    return rq.makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async noAuthRetrieveAgreement(
    req: tp.NoAuth
  ): Promise<tp.APIResponseModel<tp.Company>> {
    const { method, url: makeUrl } =
      ENDPOINTS.compliance.agreement_info.no_auth.retrieve_agreement;
    const url = makeUrl(req.id, req.token);
    return rq.makeUnauthorizedRequestWithHeadersAndPayload(method, url);
  },
  async noAuthGenerateSignatureRefrence(
    req: tp.NoAuthGenSigRef
  ): Promise<tp.APIResponseModel<tp.IsignatureRef>> {
    const { method, url: makeUrl } =
      ENDPOINTS.compliance.agreement_info.no_auth.generate_signature_reference;
    const url = makeUrl(req.id, req.token);
    return rq.makeUnauthorizedRequestWithHeadersAndPayload(
      method,
      url,
      req.data
    );
  },
  async noAuthCompleteAgreement(
    req: tp.NoAuthCompAgree
  ): Promise<tp.APIResponseModel<{}>> {
    const { method, url: makeUrl } =
      ENDPOINTS.compliance.agreement_info.no_auth.complete_agreement;
    const url = makeUrl(req.id, req.token);
    return rq.makeUnauthorizedRequestWithHeadersAndPayload(
      method,
      url,
      req.data
    );
  },
};

export default service;
