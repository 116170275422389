import { z } from "zod";
import { validationRules } from "../../../../common/utils/helpers";
import { zodResolver } from "@hookform/resolvers/zod";

export const shareHolderSchema = z.object({
  name: z.string({ message: "Input name of Shareholder" }),
  email: z.string({ message: "Input email of Shareholder" }).email(),
  phone_number: z
    .string()
    // .min(10, { message: "Phone number must be at least 10 digits long" })
    .regex(validationRules.phone, {
      message: "Invalid phone number format",
    }),
  address: z.string({ message: "Input Shareholder Address" }),
  share_percent: z.coerce
    .number({ message: "Input Shareholder %" })
    .min(1, { message: "Input Shareholder %" }),
});

export type ShareholderFormType = z.infer<typeof shareHolderSchema>;

export const shareholderResolver = zodResolver(shareHolderSchema);
