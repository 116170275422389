import React from "react";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import template from "../../../../../static/images/include_template.png";
import { useParams } from "react-router-dom";

export function ViewTemplate() {
  const templateid = useParams()?.templateid as string;
  return (
    <SideModal className="px-9 pt-28 pb-9 flex flex-col gap-y-7">
      <header className="flex flex-col gap-y-3">
        <h3 className="mb-1 text-3236 font-extrabold leading-7 text-[#372A59]">
          {templateid}
        </h3>
        <p className="max-w-[15rem] text-xs font-medium text-[#392943]">
          Supers sleek template designed for high tasty people.
        </p>
      </header>
      <div>
        <img src={template} className="w-full" alt="template" />
      </div>
    </SideModal>
  );
}
