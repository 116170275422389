import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeAuthorizedRequestWithFormData,
  makeAuthorizedRequestWithHeadersAndPayload,
  queryHandler,
} from "../../../common/service/request";

import * as tp from "../types";

const service = {
  business_document: {
    async create(
      data: tp.IcreateBusinessDocument
    ): Promise<tp.APIResponseModel<tp.IbusinessDocument>> {
      const { method, url } = ENDPOINTS.compliance.business_document.create;
      return makeAuthorizedRequestWithFormData(method, url, data);
    },
    async list(): Promise<tp.APIResponseModel<tp.IbusinessDocument[]>> {
      const { method, url } = ENDPOINTS.compliance.business_document.list;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async update(
      req: tp.IupdateBusinessDocument
    ): Promise<tp.APIResponseModel<tp.IbusinessDocument>> {
      const { docid, file } = req;
      const { method, url: makeUrl } =
        ENDPOINTS.compliance.business_document.update;
      const url = makeUrl(docid);
      return makeAuthorizedRequestWithFormData(method, url, { file });
    },
    async retrive(req: any): Promise<tp.APIResponseSuccessModel> {
      const { document_id, data } = req;
      const { method, url: makeUrl } =
        ENDPOINTS.compliance.business_document.retrive;
      const url = makeUrl(document_id);
      return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
    },
  },
  shareholder: {
    async add(
      data: tp.IaddShareholder
    ): Promise<tp.APIResponseModel<tp.Ishareholder>> {
      const { method, url } = ENDPOINTS.compliance.shareholder.add;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
    },
    async list(
      req: tp.ListShareholderTypeThunk
    ): Promise<tp.APIResponseModel<tp.Ishareholder[]>> {
      const { method, url } = ENDPOINTS.compliance.shareholder.list;
      const query = queryHandler(req);
      return makeAuthorizedRequestWithHeadersAndPayload(method, url + query);
    },
    async remove(id: string): Promise<tp.APIResponseSuccessModel> {
      const { method, url: makeUrl } = ENDPOINTS.compliance.shareholder.remove;
      const url = makeUrl(id);
      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async retrive(id: string): Promise<tp.APIResponseModel<tp.Ishareholder>> {
      const { method, url: makeUrl } = ENDPOINTS.compliance.shareholder.retrive;
      const url = makeUrl(id);
      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async restartKyc(id: string): Promise<tp.APIResponseModel<string>> {
      const { method, url: makeUrl } =
        ENDPOINTS.compliance.shareholder.restart_kyc;
      const url = makeUrl(id);
      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async verifyAxisKyc(
      id: string
    ): Promise<tp.APIResponseModel<tp.VerifyAxisKYCResponseType>> {
      const { method, url: makeUrl } =
        ENDPOINTS.compliance.shareholder.verify_axis_kyc;
      const url = makeUrl(id);
      return (await makeAuthorizedRequestWithHeadersAndPayload(method, url))
        .data;
    },
  },
  business_profile: {
    async create(
      data: tp.IcreateBusinessProfile
    ): Promise<tp.APIResponseModel<tp.IbusinessProfile>> {
      const { method, url } = ENDPOINTS.compliance.business_profile.create;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
    },
    async retrive(): Promise<tp.APIResponseModel<tp.IbusinessProfile>> {
      const { method, url } = ENDPOINTS.compliance.business_profile.retrive;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async update(
      req: tp.IupdateBusinessProfileThunk
    ): Promise<tp.APIResponseModel<tp.IbusinessProfile>> {
      const { method, url: makeUrl } =
        ENDPOINTS.compliance.business_profile.update;
      const url = makeUrl(req.id);
      return makeAuthorizedRequestWithHeadersAndPayload(method, url, req.data);
    },
  },
};

export default service;
