import React, { useCallback /* useRef */ } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppSelector,
  useAppDispatch,
  useEffectOnce,
  useCancelOnUnmount,
} from "../../../../common/hooks";
// import AppSelector from "../../../applications/components/AppSelector";
import CustomButton from "../../../../common/components/CustomButton";
import { DynamicHorizontalNav as HorizontalNav } from "../../../../common/components/Layout/HorizontalNav";
import { listBankAccountTypes } from "../../store/thunk";
import { withdrawalSettingNavLinks } from "../../../../common/utils/helpers/navHelpers";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";

const { wallet_setting_layout: PageDictionary } = Language.protected.Layout;

function SettingsLayout() {
  const appid = useParams()?.appid;
  // const layoutRef = useRef<HTMLElement | null>(null);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loading,
    data: { crypto, bank_account_types },
  } = useAppSelector((state) => state.wallet.withdrawal);

  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, link: any, name: string) => {
      e.preventDefault();
      const path = link.link(appid);
      navigate(path);
    },
    [appid, navigate]
  );

  const is_bank = useAppSelector(
    (state) => state.app.allApps.data.find((app) => app._id === appid)?.is_bank
  );

  const cancelPendingRequests = useCancelOnUnmount();
  useEffectOnce(() => {
    return () => {
      cancelPendingRequests();
    };
  });

  const links = is_bank
    ? withdrawalSettingNavLinks.filter((link) => link.name !== "crypto")
    : withdrawalSettingNavLinks;

  const disable_add_account = /* bank_account?._id !== undefined &&
    location.pathname.includes("bank") 
      ? true
      : */ false;
  const disable_add_wallet =
    crypto?._id && location.pathname.includes("/wallet/settings/crypto")
      ? true
      : false;

  const addAccountHandler = () => {
    if (!appid) return;
    let path = "";

    switch (true) {
      case location.pathname.includes("/wallet/settings/crypto"):
        path =
          routes.protected.applications.app.wallet.settings.withdrawal_details.crypto.add_crypto.link(
            appid
          );
        break;

      default:
        path =
          routes.protected.applications.app.wallet.settings.withdrawal_details.bank.add_bank.link(
            appid
          );
        break;
    }
    if (bank_account_types.length === 0) {
      dispatch(listBankAccountTypes(appid))
        .unwrap()
        .then((res) =>
          navigate(path, {
            state: {
              background: location,
              data: {
                name: res[0].transfer_type,
                id: res[0]._id,
              },
            },
          })
        );
    } else {
      navigate(path, {
        state: {
          background: location,
          data: {
            name: bank_account_types[0]?.transfer_type,
            id: bank_account_types[0]?._id,
          },
        },
      });
    }
  };

  return (
    <section className="flex flex-col py-8 pr-22 pl-14">
      {/* <AppSelector
        path={routes.protected.applications.app.wallet.link(appid ? appid : "")}
        layoutRef={layoutRef}
      /> */}
      <div className="flex items-center justify-between">
        <h1 className="invisible text-2136 font-semibold">
          {PageDictionary.header.title}
        </h1>
        <CustomButton
          disabled={disable_add_account || disable_add_wallet || loading}
          onClick={addAccountHandler}
          className="py-3 px-8 text-1424 font-semibold !text-white"
        >
          {PageDictionary.header.add_details}
        </CustomButton>
      </div>
      <nav>
        <HorizontalNav navlinks={links} handleClick={clickHandler} />
      </nav>
      <Outlet />
    </section>
  );
}

export default SettingsLayout;
