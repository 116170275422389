import { useLayoutEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { useLocation } from "react-router-dom";

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
});

const NProgressBar = () => {
  const { pathname, state } = useLocation();

  useLayoutEffect(() => {
    NProgress.configure({
      minimum: pathname === "/" || state?.background ? 0 : 0.01,
    });
    const startProgress = () => NProgress.start();
    const stopProgress = () => NProgress.done();

    startProgress();

    const timeoutId = setTimeout(stopProgress, 500); // Add a small delay for smoother transition

    return () => {
      clearTimeout(timeoutId);
    };
  }, [pathname, state?.background]);

  return null;
};

export default NProgressBar;
