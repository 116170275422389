import React from "react";
import { IconType } from "./types";

export function MessageQuestion({ scale = 1 }: IconType) {
  return (
    <svg
      // width={scale * 22}
      // height={scale * 22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5833 16.8943H11.9167L7.8375 19.6076C7.2325 20.0109 6.41668 19.5801 6.41668 18.8468V16.8943C3.66668 16.8943 1.83334 15.0609 1.83334 12.3109V6.81087C1.83334 4.06087 3.66668 2.22754 6.41668 2.22754H15.5833C18.3333 2.22754 20.1667 4.06087 20.1667 6.81087V12.3109C20.1667 15.0609 18.3333 16.8943 15.5833 16.8943Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 10.4133V10.2209C11 9.59753 11.385 9.26752 11.77 9.00168C12.1459 8.74502 12.5216 8.41503 12.5216 7.81003C12.5216 6.96669 11.8433 6.28833 11 6.28833C10.1567 6.28833 9.47836 6.96669 9.47836 7.81003"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9959 12.6041H11.0041"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
