import dayjs from "dayjs";

type Props = {
  timestring: string;
};

function DateTime({ timestring }: Props) {
  let time = "";
  if (timestring !== "") {
    time = dayjs(timestring).format("DD MMM YYYY HH:mm:ss");
  }
  return <span>{time}</span>;
}

export default DateTime;
