import React from "react";
import { IconType } from "./types";

function WalletIcon({ color = "#293B96", scale = 1 }: IconType) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      scale={scale}
    >
      <path
        d="M16.5367 12.4207C16.1517 12.7965 15.9317 13.3373 15.9867 13.9148C16.0692 14.9048 16.9767 15.629 17.9667 15.629H19.7084V16.7198C19.7084 18.6173 18.1592 20.1665 16.2617 20.1665H6.99419C7.27836 19.9282 7.52585 19.6348 7.71835 19.3048C8.05752 18.7548 8.25002 18.104 8.25002 17.4165C8.25002 15.3907 6.60919 13.7498 4.58335 13.7498C3.72169 13.7498 2.92419 14.0523 2.29169 14.5565V10.5507C2.29169 8.65318 3.84085 7.104 5.73835 7.104H16.2617C18.1592 7.104 19.7084 8.65318 19.7084 10.5507V11.8707H17.8567C17.3433 11.8707 16.8758 12.0723 16.5367 12.4207Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.29169 11.3757V7.18658C2.29169 6.09575 2.96085 5.12404 3.97835 4.73904L11.2567 1.98904C12.3934 1.55821 13.6125 2.40157 13.6125 3.62073V7.10405"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6789 12.8061V14.6944C20.6789 15.1986 20.2756 15.6111 19.7623 15.6294H17.9656C16.9756 15.6294 16.0681 14.9052 15.9856 13.9152C15.9306 13.3377 16.1506 12.7969 16.5356 12.4211C16.8748 12.0727 17.3423 11.8711 17.8556 11.8711H19.7623C20.2756 11.8894 20.6789 12.3019 20.6789 12.8061Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41669 11H12.8334"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25002 17.4167C8.25002 18.1042 8.05752 18.755 7.71835 19.305C7.52585 19.635 7.27836 19.9283 6.99419 20.1667C6.35252 20.7442 5.50919 21.0833 4.58335 21.0833C3.24502 21.0833 2.08086 20.3683 1.44836 19.305C1.10919 18.755 0.916687 18.1042 0.916687 17.4167C0.916687 16.2617 1.44835 15.2258 2.29169 14.5567C2.92419 14.0525 3.72169 13.75 4.58335 13.75C6.60919 13.75 8.25002 15.3908 8.25002 17.4167Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.15466 17.4164L4.06215 18.3239L6.01466 16.5181"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WalletIcon;
