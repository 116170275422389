import { menuNavLinks } from "../../../utils/helpers/navHelpers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { mergeClassName } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { RiCloseCircleLine } from "react-icons/ri";
import { appStateActions } from "../../../../store/helperReducers/appStateSlice";
import routes from "../../../routes";

export default function Menu() {
  const { openedTabs } = useAppSelector((state) => state.appState);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const closeTabHandler = (id?: string, link?: string) => {
    if (!id || !link) return;
    dispatch(appStateActions.removeTab(id));
    if (pathname.includes(link)) {
      navigate(routes.protected.applications.list);
    }
  };

  return (
    <nav>
      <ul className="flex items-center gap-x-1.5">
        {[...menuNavLinks, ...openedTabs].map(
          ({ link, title, ...props }, index) => (
            <li className="relative" key={title}>
              <Link
                to={link}
                className={mergeClassName(
                  "flex items-center justify-center gap-x-6 rounded-10 bg-purple-50 p-2 text-base leading-5 text-gray-320 md:justify-start md:py-3 md:px-6",
                  (pathname.includes(routes.protected.applications.index) &&
                    index === 1) ||
                    (!pathname.includes(
                      routes.protected.applications.app.index
                    ) &&
                      index === 0)
                    ? "bg-blue-120 text-white"
                    : "",
                  props?.primary && pathname.includes(props?.primary)
                    ? "rounded-b-none bg-white text-gray-330 shadow-menu"
                    : ""
                )}
              >
                {props?.icon}
                <span className="hidden md:inline-block">{title}</span>
              </Link>
              {
                /* props?.primary?.includes("/app/") &&  */ props?.primary &&
                pathname.includes(props?.primary) ? (
                  <button
                    className="absolute top-1 right-1 "
                    onClick={() => closeTabHandler(props.id, props.primary)}
                  >
                    <RiCloseCircleLine />
                  </button>
                ) : null
              }
            </li>
          )
        )}
      </ul>
    </nav>
  );
}
