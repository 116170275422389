import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../../../common/components/CustomButton";
import CustomInput from "../../../../../../common/components/CustomInput";
import SideModal from "../../../../../../common/components/CustomModal/SideModal";
import routes from "../../../../../../common/routes";
import ModalTitleHeader from "../../../../../../common/components/ModalTitleHeader";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../../../common/hooks";
import {
  FormatNumber,
  removeCommas,
  removeUnderScoreAddSpace,
} from "../../../../../../common/utils/helpers";
import {
  bankWithdrawal,
  getBankAccount,
  listDeactivatedAccount,
} from "../../../../store/thunk";
import { iHandleChange } from "../../../../types";
import Language from "../../../../../../common/utils/language/en";

const { AuthorizeWithdrawal: PageDictionary } =
  Language.protected.walletModals.withdrawal;

const formatData = (fields: any, amount: string) => {
  if (!fields || !amount) return;
  const temp = [{ name: "amount", value: amount }, ...fields];
  return temp;
};

function AuthorizeBank() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation().state;
  const background = location?.background;
  const amount = location?.amount;
  const dispatch = useAppDispatch();

  const {
    withdrawal: {
      data: { bank_account },
    },
    exchange_rate: { data: exchange_rate },
    app_wallet: { loading },
  } = useAppSelector((state) => state.wallet);
  const fields = bank_account.find(
    (account) => account.is_active === true
  )?.fields;
  const [password, setPassword] = useState("");

  const rate = exchange_rate?.withdraw_rate?.from
    ? FormatNumber(
        parseInt(removeCommas(amount) ? removeCommas(amount) : "0") *
          exchange_rate?.withdraw_rate?.rate
      )
    : "0.00";

  const inData = formatData(
    fields,
    `${exchange_rate?.withdraw_rate?.from?.toUpperCase()} ${rate}`
  );

  useEffectOnce(() => {
    if (!appid) return;
    if (bank_account.length === 0) {
      dispatch(listDeactivatedAccount(appid))
        .unwrap()
        .then(() => dispatch(getBankAccount(appid)));
    }
  });

  const backdropHandler = () => {
    if (!appid || loading) return;
    const path = routes.protected.applications.app.wallet.withdrawl.link(appid);
    navigate(path, { state: { background, amount } });
  };

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value } = e.target;
    setPassword(value);
  }, []);

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!appid) return;
    const success_path =
      routes.protected.applications.app.wallet.withdrawl.success.link(appid);
    const error_path = background?.pathname;

    const req = {
      appid,
      data: {
        amount: removeCommas(amount),
        password,
        withdrawal_mode: "FIAT",
      },
    };

    dispatch(bankWithdrawal(req))
      .unwrap()
      .then(() => {
        navigate(success_path, {
          state: { background },
        });
      })
      .catch(() => {
        navigate(error_path, {
          state: { background },
        });
      });
  };
  return (
    <SideModal
      onclickBackDrop={backdropHandler}
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <ModalTitleHeader
        title={PageDictionary.title}
        subtitle={PageDictionary.subtitle}
      />

      <div className="grid grid-cols-2 gap-y-7 py-12">
        {inData?.map((itm) => (
          <div className="flex flex-col gap-y-1.5" key={itm.name}>
            <div className="text-sm capitalize leading-6 text-gray-410">
              {removeUnderScoreAddSpace(itm.name)}
            </div>
            <div className="text-lg font-bold leading-6 text-black">
              {
                /* itm.name === "amount"
                ? toCurrency(parseInt(itm.value))
                :  */ itm.value
              }
            </div>
          </div>
        ))}
      </div>

      <form
        autoComplete="off"
        className="flex flex-col gap-y-10 pt-5"
        onSubmit={submitHandler}
      >
        <CustomInput
          label={PageDictionary.form.password_label}
          value={password}
          disabled={loading}
          name="password"
          type="password"
          onChange={changeHandler}
          autoComplete="new-password"
        />

        <CustomButton
          isloading={loading}
          className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        >
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default AuthorizeBank;
