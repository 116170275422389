import { IappPolicyCategory } from "../../../types";

export const initialstate = {
  name: "",
  credit_policy: {
    name: "",
    id: "",
  },
  debit_policy: {
    name: "",
    id: "",
  },
};

export const diffrenceHandler = (
  data: typeof initialstate,
  policy?: IappPolicyCategory
) => {
  let diffrence: Record<string, string> = {};
  let count = 0;
  if (!policy) return { data: diffrence, count };
  const keys = Object.keys(data) as Array<keyof typeof data>;
  keys.forEach((key) => {
    switch (key) {
      case "name":
        if (data[key] !== policy[key] && !!data[key] !== !policy[key]) {
          count++;
          diffrence[key] = data[key];
        }
        break;

      default:
        if (data[key].id !== policy[key]._id) {
          count++;
          diffrence[key] = data[key].id;
        }
        break;
    }
  });
  return { data: diffrence, count };
};

export type Policy = "credit_policy" | "debit_policy";
