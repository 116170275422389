import { Middleware } from "redux";
// import ReconnectingWebSocket from "reconnecting-websocket";
/* import { walletActions } from "./slice";
import { fetchUserToken } from "../../../common/service/storage";
import { ItransactionMessage } from "../types"; */

const transactionMiddleware: Middleware = (store) => {
  // let socket: ReconnectingWebSocket;

  return (next) => (action) => {
    // const isConnectionEstablished =
    //   socket && store.getState().wallet.socket.isConnected === true;
    // const isConnectionEstablishedNoSocket =
    //   store.getState().wallet.socket.isConnected === true;
    // const appid = action?.payload?.app_id;
    // const txn_ref = action?.payload?.txn_reference;

    // //   establish connection
    // if (
    //   walletActions.startConnecting.type === action?.type &&
    //   isConnectionEstablishedNoSocket === false &&
    //   appid
    // ) {
    //   socket = new ReconnectingWebSocket(
    //     `${process.env.REACT_APP_SOCKET}/${appid}/?token=${fetchUserToken()}${
    //       txn_ref ? `&txn_reference=${txn_ref}` : ""
    //     }`
    //   );

    //   socket.onmessage = ({ data }: MessageEvent<string>) => {
    //     const res: ItransactionMessage = JSON.parse(data);
    //     store.dispatch(walletActions.connectionEstablished());
    //     store.dispatch(walletActions.receiveMessage({ data: res }));
    //   };
    // }

    // // recive message when connected
    // if (socket?.readyState === 1 && isConnectionEstablished) {
    //   socket.onmessage = ({ data }: MessageEvent<string>) => {
    //     const res: ItransactionMessage = JSON.parse(data);
    //     store.dispatch(walletActions.receiveMessage({ data: res }));
    //   };
    // }

    // // send a package back when connected
    // if (walletActions.submitMessage.match(action) && isConnectionEstablished) {
    //   socket?.send(JSON.stringify(action.payload.content));
    // }

    // if (
    //   walletActions.startDisconecting.match(action) &&
    //   isConnectionEstablished
    // ) {
    //   socket.close();
    //   socket.onclose = (e: any) => {
    //     store.dispatch(walletActions.disconnectionEstablished());
    //   };
    // }

    next(action);
  };
};

export default transactionMiddleware;
