import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../common/hooks";
import {
  // downloadApiKey,
  regenerateApiKey,
  retrieveApiKey,
} from "../../store/thunk";
import { iHandleChange, iHandleSubmit } from "../../types";

const initialstate = {
  appid: "",
  data: {
    password: "",
  },
};

function ShowApiScreen() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname, state } = useLocation();
  const [inData, setInData] = useState(initialstate);
  const { loading } = useAppSelector((state) => state.app.api_info);

  const isRegenPath = pathname.includes("/r/confirm");
  // const isDownloadPath = pathname.includes("/d/confirm");
  const isShowApiPath = pathname.includes("/s/confirm");

  // const success_path = appid /* && !isDownloadPath */
  //   ? routes.protected.applications.app.api_settings.link(appid)
  //   : /* : routes.protected.applications.index; */ "";

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value } = e.target;
    setInData((prev) => ({ ...prev, data: { ...prev.data, password: value } }));
  }, []);

  useEffectOnce(() => {
    if (!appid) return;
    setInData((prev) => ({ ...prev, appid }));
  });

  const dispatchHandler = (data: any) => {
    if (!isRegenPath && !isShowApiPath) return;
    if (isRegenPath) {
      return dispatch(regenerateApiKey(data));
    }
    // isShowApiPath is true
    return dispatch(retrieveApiKey(data));
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    dispatchHandler(inData)
      ?.unwrap()
      .then(() => navigate(state?.background?.pathname, { replace: false }))
      .catch(() => navigate(-1));
  };

  const cancelHandler = () => {
    if (loading) return;
    navigate(-1);
  };
  return (
    <CenterModal className="pt-26 pb-32" handleClose={cancelHandler} blurBg>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
        autoComplete="off"
      >
        <h3 className="text-2xl text-red-10">
          Permission is required to perform this action
        </h3>
        <p>Enter password if you want to continue?</p>

        <CustomInput
          label="Enter Password:"
          containerClassName="self-stretch flex flex-col"
          labelClassName="text-left"
          type="password"
          autoComplete="new-password"
          name="password"
          value={inData.data.password}
          onChange={changeHandler}
        />
        <div className="flex items-center gap-x-9">
          <CustomButton type="button" variant onClick={cancelHandler}>
            Cancel
          </CustomButton>
          <CustomButton
            isloading={loading}
            type="submit"
            disabled={inData.data.password === ""}
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default ShowApiScreen;
