import React from "react";
import CustomToolTip from "../CustomToolTip";

type Props = {
  title: string;
  subtitle: string;
  tooltip?: string;
};

function ModalTitleHeader({ title, subtitle, tooltip }: Props) {
  return (
    <header className="flex flex-col gap-2">
      <div>
        <h3 className="text-2xl font-bold leading-6.5 text-base-40">{title}</h3>
        {tooltip && <CustomToolTip tip={tooltip} />}
      </div>
      <p className="text-sm text-gray-60">{subtitle}</p>
    </header>
  );
}

export default ModalTitleHeader;
