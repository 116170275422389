import React from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import routes from "../../../../common/routes";

type props = {
  is_bank?: boolean;
};

function ModalNavLinkFunding({ is_bank }: props) {
  const { appid } = useParams();
  const state = useLocation().state;
  const navigate = useNavigate();

  const navClickHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!appid) return;

    let path = "";

    if (
      e.currentTarget.href.includes(
        routes.protected.applications.app.wallet.fund.nav
      )
    ) {
      path = routes.protected.applications.app.wallet.fund.link(appid);
    }
    if (
      e.currentTarget.href.includes(
        routes.protected.applications.app.wallet.fund.crypto.nav
      )
    ) {
      path = routes.protected.applications.app.wallet.fund.crypto.link(appid);
    }
    navigate(path, { state });
  };

  return (
    <div className="flex items-center gap-x-6">
      <NavLink
        onClick={navClickHandler}
        className={({ isActive }) =>
          isActive
            ? " inline-block min-w-[6.5rem] rounded border border-black bg-black px-2.5 py-2 text-center text-sm font-medium text-white"
            : " inline-block min-w-[6.5rem] rounded border border-gray-360 bg-white px-2.5 py-2 text-center text-sm font-medium text-gray-530"
        }
        to={routes.protected.applications.app.wallet.fund.nav}
      >
        Bank Transfer
      </NavLink>
      {!is_bank && (
        <NavLink
          onClick={navClickHandler}
          className={({ isActive }) =>
            isActive
              ? " inline-block min-w-[6.5rem] rounded border border-black bg-black px-2.5 py-2 text-center text-sm font-medium text-white"
              : " inline-block min-w-[6.5rem] rounded border border-gray-360 bg-white px-2.5 py-2 text-center text-sm font-medium text-gray-530"
          }
          to={routes.protected.applications.app.wallet.fund.crypto.nav}
        >
          Crypto
        </NavLink>
      )}
    </div>
  );
}

export default ModalNavLinkFunding;
