import axios, { CancelTokenSource } from "axios";
import { instance } from "../service/request";
import { useEffectOnce } from "./useEffectOnce";
import { useRef } from "react";
import { useAppSelector } from "./redux";

export const useCancelOnUnmount = (): (() => void) => {
  const cancelToken = useRef<CancelTokenSource>(axios.CancelToken.source());
  const { isRefreshingToken } = useAppSelector((state) => state.auth);

  // useEffect(() => {}, []);

  useEffectOnce(() => {
    // instance.defaults.cancelToken = undefined;
    instance.defaults.cancelToken = cancelToken.current.token;
    return () => {
      if (isRefreshingToken) return;
      cancelToken.current.cancel("Request cancelled by component unmount.");
    };
  });

  const cancelPendingRequests = (): void => {
    if (isRefreshingToken) return;
    cancelToken.current.cancel("Request cancelled by user.");
    // instance.defaults.cancelToken = cancelToken.current.token;
    cancelToken.current = axios.CancelToken.source();
  };

  return cancelPendingRequests;
};
