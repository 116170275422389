import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { addShareholder } from "../../store/thunk";
import { iHandleChange } from "../../types";
import Language from "../../../../common/utils/language/en";
import { useForm } from "react-hook-form";
import { ShareholderFormType, shareholderResolver } from "./schema";
import routes from "../../../../common/routes";

const { shareholder_form: PageDictionary } =
  Language.protected.compalinaceModals;

function ShareholderForm() {
  const navigate = useNavigate();
  const background = useLocation().state.background;
  const location = background.pathname;
  const { loading } = useAppSelector((state) => state.compliance.shareholders);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ShareholderFormType>({
    resolver: shareholderResolver,
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {},
  });

  const disableSubmitBtn = Object.keys(errors).length > 0 || loading;

  const closeModal = () => {
    if (loading) return;
    navigate(location, { replace: true });
  };

  const changeHandler: iHandleChange = (e) => {
    const { name: tName, value } = e.target;
    if (loading) return;
    const name = tName as keyof ShareholderFormType;
    setValue(name, value, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const submitHandler = (data: ShareholderFormType) => {
    console.log(data);
    dispatch(addShareholder({ ...data, kyc_type: "Individual" }))
      .unwrap()
      .then((res) => {
        if (res?.meta_data?.kyc_url) {
          navigate(routes.protected.onboarding.shareholders.kyc, {
            state: {
              background,
              data: {
                link: res.meta_data?.kyc_url,
                reference: res.meta_data?.axis_kyc_data?.reference,
              },
            },
            replace: true,
          });
        } else {
          closeModal();
        }
      });
  };

  return (
    <SideModal
      onclickBackDrop={closeModal}
      className="p-8 md:pt-15 md:pl-14 md:pr-28"
    >
      <h3 className="text-4xl">{PageDictionary.title}</h3>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="flex flex-col gap-y-8 pt-11"
      >
        <CustomInput
          name="name"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          label={PageDictionary.form_elements.name}
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.name?.message}
          errortext={errors.name?.message}
        />
        <CustomInput
          name="email"
          label={PageDictionary.form_elements.email}
          type="email"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.email?.message}
          errortext={errors.email?.message}
        />
        <CustomInput
          name="phone_number"
          label={PageDictionary.form_elements.phone_number}
          type="text"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.phone_number?.message}
          errortext={errors.phone_number?.message}
        />
        <CustomInput
          name="address"
          label={PageDictionary.form_elements.address}
          type="text"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.address?.message}
          errortext={errors.address?.message}
        />
        <CustomInput
          name="share_percent"
          label={PageDictionary.form_elements.shareholder_percentage}
          type="text"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          onChange={changeHandler}
          onBlur={(e) => changeHandler(e)}
          haserror={!!errors.share_percent?.message}
          errortext={errors.share_percent?.message}
        />
        <CustomButton
          isloading={loading}
          disabled={disableSubmitBtn}
          className="mt-8 self-start px-[3.25rem] py-3.5 text-base leading-7"
        >
          {PageDictionary.form_elements.submit}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default ShareholderForm;
