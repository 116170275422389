import React from "react";
import { homeNavLinks } from "../../../utils/helpers/navHelpers";
import { NavLink, useNavigate } from "react-router-dom";
import { mergeClassName } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import routes from "../../../routes";
import { paginationActions } from "../../../../store/helperReducers/paginationSlice";

const {
  settings,
  compliance,
  onboarding,
  applications,
  user: admins,
} = routes.protected;

export default function WelcomeMenu() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, company } = useAppSelector((state) => state.auth);
  const agreement_complete = company?.agreement_info?.signature?.complete;
  const { user_types } = user;
  const navClickHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const { href } = e.currentTarget;

    if (
      href.includes(admins.index) ||
      href.includes(compliance.index) ||
      href.includes(applications.index)
    ) {
      dispatch(paginationActions.resetData());
    }
    if (href.includes(settings.index)) {
      e.preventDefault();
      navigate(settings.security);
    }
    if (href.includes(compliance.index)) {
      e.preventDefault();
      navigate(compliance.business_document.index);
    }
    if (href.includes(onboarding.index)) {
      e.preventDefault();
      let path = onboarding.terms.index;
      if (agreement_complete) {
        path = onboarding.shareholders.index;
      }
      navigate(path);
    }
  };

  return (
    <div
      data-tut="instextour__nav-navigation"
      className="row-start-3 row-end-4 flex flex-col gap-y-7 pl-6 "
    >
      {homeNavLinks.map(({ icon, link, title, name }) => {
        if (name !== "onboarding" && company?.admin_approved === false) {
          return null;
        }
        if (name === "onboarding" && company?.admin_approved === true) {
          return null;
        }
        if (name === "user" && user_types.includes("WORKSPACE_MEMBER")) {
          return null;
        }
        return (
          <NavLink
            key={link}
            onClick={navClickHandler}
            to={link}
            data-tut={`instextour__nav-${name}`}
            className={({ isActive }) =>
              mergeClassName(
                "flex items-center justify-start gap-x-5 text-sm font-semibold leading-4",
                isActive ? "relative rounded-2xl text-blue-130" : "text-gray-10"
              )
            }
          >
            {icon}
            <span className="hidden md:inline-block">{title}</span>
          </NavLink>
        );
      })}
    </div>
  );
}
