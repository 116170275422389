import * as tp from "../types";

export const featureParser = (features: tp.IAppFeature[]) => {
  return features.map((feature) => {
    let temp: tp.IinAppFeature | null = null;
    switch (feature.name) {
      case "BANK_TRANSFER":
      case "WALLET_TRANSFER":
      case "FUND_WALLET":
      case "GIFT_CARDS":
      case "LOANS":
        temp = { ...feature, type: feature.name };
        break;
      case "INVESTMENT":
      case "SAVINGS":
        temp = { ...feature, type: "SAVINGS" };
        break;

      default:
        temp = { ...feature, type: "UTILITIES" };
        break;
    }
    return temp;
  });
};
