import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import AwaitingConfirmation from "./Wallet/FundWallet/AwaitingConfirmation";
import FundBankForm from "./Wallet/FundWallet/FundBankForm";
import FundCryptoForm from "./Wallet/FundWallet/FundCryptoForm";
import FundWalletForm from "./Wallet/FundWallet/FundWalletForm";
import FundWalletSuccess from "./Wallet/FundWallet/FundWalletSuccess";
import PaymentPrcocessing from "./Wallet/FundWallet/PaymentPrcocessing";
import AuthorizeBank from "./Wallet/Withdrawal/AuthorizeBank";
import AuthorizeCrypto from "./Wallet/Withdrawal/AuthorizeCrypto";
import WithdrawalForm from "./Wallet/Withdrawal/WithdrawalForm";
import WithdrawalThreshold from "./Wallet/Withdrawal/WithdrawalThreshold";
import WithdrawSuccess from "./Wallet/Withdrawal/WithdrawSuccess";
import BankAccountDetails from "./WithdrawalSetting/BankAccountDetails";
import BankAccountType from "./WithdrawalSetting/BankAccountType";
import CryptoSetting from "./WithdrawalSetting/CryptoSetting";
import DeactivateAccount from "./WithdrawalSetting/DeactivateAccount";
import SettingsAddedSuccess from "./WithdrawalSetting/SettingsAddedSuccess";

function WalletModal() {
  return (
    <Routes>
      {/* wallet fund */}
      <Route
        path={routes.protected.applications.app.wallet.fund.index}
        element={<FundWalletForm />}
      />
      <Route
        path={routes.protected.applications.app.wallet.fund.bank.index}
        element={<FundBankForm />}
      />
      <Route
        path={routes.protected.applications.app.wallet.fund.crypto.index}
        element={<FundCryptoForm />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.fund.awaiting_confirmation
            .index
        }
        element={<AwaitingConfirmation />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.fund.payment_processing.index
        }
        element={<PaymentPrcocessing />}
      />
      <Route
        path={routes.protected.applications.app.wallet.fund.success.index}
        element={<FundWalletSuccess />}
      />

      {/* wallet withdraw */}
      <Route
        path={routes.protected.applications.app.wallet.withdrawl.index}
        element={<WithdrawalForm />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.withdrawl.authorize_bank
            .index
        }
        element={<AuthorizeBank />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.withdrawl.authorize_crypto
            .index
        }
        element={<AuthorizeCrypto />}
      />
      <Route
        path={routes.protected.applications.app.wallet.withdrawl.success.index}
        element={<WithdrawSuccess />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.withdrawl.threshold.index
        }
        element={<WithdrawalThreshold />}
      />

      {/* wallet withdrawal setting */}
      <Route
        path={
          routes.protected.applications.app.wallet.settings.withdrawal_details
            .confirm_action.index
        }
        element={<DeactivateAccount />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.settings.withdrawal_details
            .bank.select_bank_type.index
        }
        element={<BankAccountType />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.settings.withdrawal_details
            .bank.add_bank.index
        }
        element={<BankAccountDetails />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.settings.withdrawal_details
            .crypto.add_crypto.index
        }
        element={<CryptoSetting />}
      />
      <Route
        path={
          routes.protected.applications.app.wallet.settings.withdrawal_details
            .success.index
        }
        element={<SettingsAddedSuccess />}
      />
    </Routes>
  );
}

export default WalletModal;
