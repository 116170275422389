import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../common/hooks";
import routes from "../../../../common/routes";
import { validationRules } from "../../../../common/utils/helpers";
import { resendPasswordUpdate, updatePassword } from "../../store/thunk";
import { iHandleChange, iHandleClick } from "../../types";
import Language from "../../../../common/utils/language/en";

const PageDictionary = Language.protected.settingsModal.updatePassword;

const initialstate = {
  old_password: "",
  new_password: "",
  otp: "",
};

const errorstate = {
  otp: false,
};

function UpdatePassword() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [inData, setInData] = useState(initialstate);
  const [inError, setInError] = useState(errorstate);
  const [otpLoad, setOtpLoad] = useState(false);
  const { loading } = useAppSelector((state) => state.settings);

  useEffectOnce(() => {
    if (!state?.data) return;
    const temp = state.data;
    setInData((prev) => ({
      ...prev,
      new_password: temp.new_password,
      old_password: temp.old_password,
    }));
  });

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));

    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setInError((prev) => ({ ...prev, [name]: false }));
    } else {
      setInError((prev) => ({ ...prev, [name]: true }));
    }
  }, []);

  const handleResendOtp: iHandleClick = (e) => {
    e.stopPropagation();
    setOtpLoad(true);
    dispatch(resendPasswordUpdate()).finally(() => setOtpLoad(false));
  };

  const cancelHandler = () => {
    if (loading) return;
    const path = routes.protected.settings.security;
    navigate(path, { replace: true });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (Object.values(inError).includes(true)) return;

    dispatch(updatePassword(inData)).unwrap().finally(cancelHandler);
  };

  return (
    <CenterModal className="pt-26 pb-32" handleClose={cancelHandler} blurBg>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
        autoComplete="off"
      >
        <h3 className="text-2xl text-red-10">{PageDictionary.warn_text}</h3>
        <p>{PageDictionary.form_label}</p>
        <div className="self-stretch">
          <label>
            <div className="mb-2 flex items-center justify-between">
              <span className="inline-block text-base font-medium text-base-20">
                {PageDictionary.otp_label}
              </span>
              <button
                type="button"
                onClick={handleResendOtp}
                disabled={otpLoad}
                className="text-green-10 disabled:cursor-not-allowed"
              >
                {PageDictionary.resend_otp_btn}
              </button>
            </div>
            <CustomInput
              label={PageDictionary.otp_label}
              containerClassName="self-stretch flex flex-col"
              labelClassName="none hidden"
              type="text"
              name="otp"
              value={inData.otp}
              rules={validationRules.number}
              haserror={inError.otp}
              onChange={changeHandler}
            />
          </label>
        </div>
        <div className="flex items-center gap-x-9">
          <CustomButton type="button" variant onClick={cancelHandler}>
            {PageDictionary.cancel}
          </CustomButton>
          <CustomButton isloading={loading} disabled={inData.otp === ""}>
            {PageDictionary.submit}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default UpdatePassword;
