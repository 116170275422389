import { useNavigate, useParams } from "react-router-dom";
import SideModal from "../../../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../../../common/components/ModalTitleHeader";
import routes from "../../../../../../common/routes";
import Language from "../../../../../../common/utils/language/en";
import { useAppSelector } from "../../../../../../common/hooks";
import NigerianForm from "./NigerianForm";
import OtherForms from "./OtherForms";

const { FundBankForm: PageDictionary } =
  Language.protected.walletModals.funding;

function FundBankForm() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const currency = useAppSelector(
    (state) => state.wallet.exchange_rate.data?.fund_rate.from
  );

  const handleBackDrop = () => {
    if (!appid) return;
    navigate(routes.protected.applications.app.wallet.link(appid), {
      replace: true,
    });
  };

  return (
    <SideModal
      onclickBackDrop={handleBackDrop}
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <ModalTitleHeader
        title={PageDictionary.header.title}
        subtitle={PageDictionary.header.subtitle}
      />
      {currency === "NGN" ? <NigerianForm /> : <OtherForms />}
    </SideModal>
  );
}

export default FundBankForm;
