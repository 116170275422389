import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
// import SuspsenseFallBack from "../SuspsenseFallBack";

function Layout() {
  return (
    <Suspense fallback={null}>
      <Outlet />
    </Suspense>
  );
}

export default Layout;
