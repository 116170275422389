import { mergeClassName } from "../../utils/helpers";

interface props {
  isDisabled?: boolean;
  currentPage?: number;
  totalPages?: number;
  setPage: (page: number) => void;
  loading?: boolean;
  dataCount?: number;
  tableRef?: React.MutableRefObject<HTMLElement | null>;
}

function Pagination({
  currentPage = 1,
  dataCount = 10,
  setPage,
  totalPages = 10,
  isDisabled,
  loading,
  tableRef,
}: props) {
  const handlePageChange = (page: number) => {
    if (page === currentPage) return;
    setPage(page);
    tableRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const renderPageNumbers = () => {
    const pageNumbers: any[] = [];
    const visiblePages: any[] = [];
    const firstPage = 1;
    const lastPage = totalPages;

    if (lastPage <= 7) {
      // Display all pages
      for (let i = firstPage; i <= lastPage; i++) {
        visiblePages.push(i);
      }
    } else if (currentPage <= 5) {
      // Display first 7 pages
      for (let i = firstPage; i <= Math.min(lastPage, 7); i++) {
        visiblePages.push(i);
      }

      if (lastPage > 9) {
        visiblePages.push("ellipsis", lastPage - 1, lastPage);
      }
    } else if (currentPage >= lastPage - 5) {
      // Display last 7 pages
      visiblePages.push(firstPage, firstPage + 1, "ellipsis");

      for (let i = lastPage - 6; i <= lastPage; i++) {
        visiblePages.push(i);
      }
    } else {
      // Display pages with ellipsis in between
      visiblePages.push(firstPage, firstPage + 1, "ellipsis");

      for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        visiblePages.push(i);
      }

      visiblePages.push("ellipsis", lastPage - 1, lastPage);
    }

    visiblePages.forEach((page, idx) => {
      if (page === "ellipsis") {
        pageNumbers.push(
          <li
            key={`ellipsis-${idx}`}
            className="border-gray-260 text-gray-280 w-10 flex-1 border-l bg-transparent py-2.5 text-center text-sm font-medium last:border-r"
          >
            ...
          </li>
        );
      } else {
        pageNumbers.push(
          <li
            key={page}
            className={mergeClassName(
              "border-gray-260 text-gray-280 w-10 flex-1 border-l bg-transparent text-sm font-medium last:border-r",
              page === currentPage ? "bg-gray-270 text-green-10 " : ""
            )}
          >
            <button
              className="w-full py-2.5"
              disabled={loading}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          </li>
        );
      }
    });

    return pageNumbers;
  };

  const nextPrevClickHandler = (name: string) => {
    if (name === "prev") {
      handlePageChange(currentPage - 1);
    }
    if (name === "next") {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <>
      {dataCount > 9 ? (
        <nav
          aria-label="Page navigation"
          className={mergeClassName(
            "border-gray-260 text-gray-280 mt-2 ml-auto flex max-w-fit place-items-center items-center justify-between overflow-hidden rounded-lg border bg-white",
            loading ? "paginator_loading" : ""
          )}
        >
          <button
            className={mergeClassName(
              "flex cursor-pointer items-center justify-center gap-x-3 place-self-stretch bg-white pl-4 pr-2 disabled:cursor-not-allowed",
              totalPages > 9 ? "flex-1" : ""
            )}
            disabled={isDisabled || currentPage <= 1 || loading}
            onClick={() => nextPrevClickHandler("prev")}
          >
            <span>{"<"}</span> <span>Previous</span>
          </button>
          <ul className="flex">{renderPageNumbers()}</ul>
          <button
            className={mergeClassName(
              "flex cursor-pointer items-center justify-center gap-x-3 place-self-stretch bg-white pr-4 pl-2 disabled:cursor-not-allowed",
              totalPages > 9 ? "flex-1" : ""
            )}
            disabled={
              isDisabled ||
              currentPage === totalPages ||
              totalPages === 0 ||
              loading
            }
            onClick={() => nextPrevClickHandler("next")}
          >
            <span>Next</span> <span>{">"}</span>
          </button>
        </nav>
      ) : null}
    </>
  );
}

export default Pagination;
