import React from "react";
import * as HoverCard from "@radix-ui/react-hover-card";
import { InfoIcon } from "../../../static/images/icons";

type Props = {
  tip: string;
};

function CustomToolTip({ tip }: Props) {
  return (
    <HoverCard.Root openDelay={0}>
      <HoverCard.Trigger>
        <span
          data-testid="tooltipicon"
          className="cursor-pointer fill-[#6F6F6F]"
        >
          <InfoIcon scale={0.75} />
        </span>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          align="center"
          side="right"
          className="HoverCardContent z-50 w-62 rounded bg-gray-20 py-4 px-2.5 text-base-30 "
        >
          <p className="text-xs leading-5">{tip}</p>
          <HoverCard.Arrow fill="#EDEDED" />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}

export default CustomToolTip;
